import * as React from 'react';

function Svg29Up(props) {
	return (
		<svg data-name="\u0421\u043B\u043E\u0439 1" viewBox="0 0 557.72 547.34" {...props}>
			<path
				d="M279.24 0C126.26-.2 1 124 0 277c-.83 127.4 83.78 235.16 199.9 269.38a23.07 23.07 0 0029.54-22.16V287.86l-69 69a43.35 43.35 0 01-61.32 0 43.35 43.35 0 010-61.31l146.7-146.7a43.21 43.21 0 0133-12.63 43.24 43.24 0 0133.05 12.63L458.6 295.56a43.34 43.34 0 010 61.31 43.34 43.34 0 01-61.31 0l-66-66V523.3a23.08 23.08 0 0029.86 22.06C475 510.25 557.72 404.23 557.72 278.86 557.72 125 433.07.2 279.24 0z"
				fill="#fff"
			/>
		</svg>
	);
}

export default Svg29Up;
