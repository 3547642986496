import * as React from 'react';

function Svg25Passportfrontimage(props) {
	return (
		<svg
			id="-_25_Passport_front_image_svg__\u0421\u043B\u043E\u0439_1"
			data-name="\u0421\u043B\u043E\u0439 1"
			viewBox="0 0 1989.16 1570.83"
			{...props}
		>
			<defs>
				<clipPath id="-_25_Passport_front_image_svg__clip-path">
					<path className="-_25_Passport_front_image_svg__cls-1" d="M0-16.17h1989.16v1587H0z" />
				</clipPath>
				<style>
					{
						'.-_25_Passport_front_image_svg__cls-1,.-_25_Passport_front_image_svg__cls-20{fill:none}.-_25_Passport_front_image_svg__cls-13{fill:#ffca27}.-_25_Passport_front_image_svg__cls-7{fill:#624de6}.-_25_Passport_front_image_svg__cls-18,.-_25_Passport_front_image_svg__cls-8,.-_25_Passport_front_image_svg__cls-9{fill:#8fafe2}.-_25_Passport_front_image_svg__cls-8{opacity:.1}.-_25_Passport_front_image_svg__cls-17,.-_25_Passport_front_image_svg__cls-9{opacity:.25}.-_25_Passport_front_image_svg__cls-11{fill:#ff703e}.-_25_Passport_front_image_svg__cls-12{fill:#4a3edd}.-_25_Passport_front_image_svg__cls-14{fill:#fff7f1}.-_25_Passport_front_image_svg__cls-16{fill:#32384f}.-_25_Passport_front_image_svg__cls-16,.-_25_Passport_front_image_svg__cls-20{stroke:#32384f}.-_25_Passport_front_image_svg__cls-16{stroke-miterlimit:10;stroke-width:1.18px}.-_25_Passport_front_image_svg__cls-19{fill:#f7b614}.-_25_Passport_front_image_svg__cls-20{stroke-linecap:round;stroke-linejoin:round;stroke-width:14.03px}'
					}
				</style>
			</defs>
			<g clipPath="url(#-_25_Passport_front_image_svg__clip-path)">
				<path
					d="M1548.4 621.64s-7.4-377.36-316.83-213.49C992 535 1107.88 791.25 946.52 854.8c-253.08 99.66-118.85 599.83 217 389.91s422.69-43.12 523.84-231.22c68.47-127.22-101.1-153.85-138.96-391.85z"
					fill="#1188f7"
					opacity={0.07}
				/>
				<path
					d="M1213.59 492.26s254.2-305.48-42.42-436.58c-229.6-101.49-301.84 108.06-499.38 65.13-246-53.46-501.41 235-178.06 409.37C788.8 689.32 667 1039 1035.89 905.3c120.85-43.8 20.24-215.52 177.7-413.04z"
					opacity={0.11}
					fill="#ffca27"
				/>
				<path
					d="M505.9 945s-131.7 216.63 90.85 314.49c224.52 98.72 244-159 444.8-121.74 343.86 63.85 470.9-345.34 123.28-442.73s-307.44-275.59-496-201.3C458 576.75 637.48 755.78 505.9 945z"
					fill="#4dbc76"
					opacity={0.09}
				/>
				<rect x={599.51} y={140.53} width={811.51} height={871.06} rx={34.2} ry={34.2} fill="#fff" />
				<path
					className="-_25_Passport_front_image_svg__cls-7"
					d="M635.22 61.65h740.09A35.71 35.71 0 011411 97.36v428.43H599.51V97.36a35.71 35.71 0 0135.71-35.71z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-8"
					d="M1411 97.36a35.71 35.71 0 00-35.71-35.71H635.22a35.71 35.71 0 00-35.71 35.71v13.93c89.92.86 187.67 33.92 217.82 161.47 30.41 128.63 78.31 217.76 167.32 253h218.84c51.68-12.86 110.06-33.25 176.13-61.79 10.67-4.61 21.12-8.81 31.4-12.68z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-8"
					d="M1375.31 61.65H635.22a35.71 35.71 0 00-35.71 35.71v329.93c131 16.13 320.29-105.78 499.11-175.35 131.94-51.33 241.06-17.34 312.4 23.47v-178a35.71 35.71 0 00-35.71-35.76z"
				/>
				<rect className="-_25_Passport_front_image_svg__cls-7" x={976.63} y={582.31} width={300.25} height={33.23} rx={16.62} ry={16.62} />
				<rect className="-_25_Passport_front_image_svg__cls-9" x={976.63} y={639.55} width={225.6} height={33.23} rx={16.62} ry={16.62} />
				<rect className="-_25_Passport_front_image_svg__cls-9" x={976.63} y={696.78} width={282.04} height={33.23} rx={16.62} ry={16.62} />
				<rect className="-_25_Passport_front_image_svg__cls-9" x={976.63} y={754.01} width={206.79} height={33.23} rx={16.62} ry={16.62} />
				<rect className="-_25_Passport_front_image_svg__cls-9" x={976.63} y={811.24} width={206.79} height={33.23} rx={16.62} ry={16.62} />
				<rect x={684.55} y={582.31} width={252.71} height={262.16} rx={12.6} ry={12.6} fill="#e4eaf4" />
				<path
					className="-_25_Passport_front_image_svg__cls-11"
					d="M776.36 632.81s-22.09 19.14-23 50.68 8.93 45.22-7 58.78-44 28.1-22.54 64.62h87.44V639.35z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-11"
					d="M845.35 632.81s22.08 19.14 23 50.68-5.33 46.45 10.58 60 40.41 26.87 19 63.39h-87.5V639.35z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-7"
					d="M922.49 844.47H698.26s2.25-28 15.79-33.38c2.8-1.12 7.46-3.31 13.08-6.07l2.4-1.18c11.72-5.8 26.78-13.62 38.17-19.58 9.7-5.08 16.72-8.82 16.72-8.82l1.72.39a106.74 106.74 0 0048.36-.39s5.79 3 14.15 7.24c4.94 2.52 10.79 5.49 16.87 8.55 16.36 8.24 34.4 17.15 41.17 19.86 13.55 5.41 15.8 33.38 15.8 33.38z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-12"
					d="M749.16 844.47h-6c-4.63-10.2-12.38-27.82-16-39.45l2.4-1.18c2.66 6.88 11.44 28.67 19.6 40.63zM867.53 844.47h6c4.64-10.2 13.15-29.32 16.81-40.94-.78-.39-1.37-.66-2.18-1.06-2.71 6.88-12.51 30.04-20.63 42z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-7"
					d="M798.62 844.47h-49c-8.24-12.27-15.89-30.06-20.1-40.63-2.13-5.36-3.38-8.86-3.38-8.86 12.63-10 41.55-10.72 41.55-10.72 10.31 13.79 23.59 43.12 30.93 60.21zM818.53 844.47h49c8.25-12.27 15.9-30.06 20.1-40.63 2.1-5.36 3.37-8.84 3.37-8.84-12.63-10-42.32-12.3-42.32-12.3-10.32 13.78-22.81 44.68-30.15 61.77z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-13"
					d="M839.19 777.85a10.24 10.24 0 01-5.38-7.13c-1.28-6.42-3.47-17.67-4.64-24.56-.63-3.71-1.18-7.4-1.52-10.54l-37 1.47a71.76 71.76 0 01-1.68 16.68c-.67 3.19-3.36 12.65-5.36 18.84a10.22 10.22 0 01-5 5.92c-2.86 1.51-6.61 3.49-10.89 5.73a167.76 167.76 0 007.16 21.48c6.19 15 16.29 32.6 31.06 38.73h17.69c10.73-5.75 17.11-22.4 20.8-37.59a202.91 202.91 0 004.24-24.2z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-14"
					d="M844.42 806.88c-3.69 15.19-10.07 31.84-20.8 37.59h-17.69c-14.77-6.13-24.87-23.74-31.06-38.73 7.75 3.2 19.3 6.11 35.64 6.11a108.86 108.86 0 0033.91-4.97zM832.5 773.63c.55-.74 1-1.47 1.49-2.2-.06-.23-.13-.47-.18-.71-.09-.44-.18-.91-.28-1.39a30 30 0 01-2.23 3.47c-4.89 6.52-11.63 9.83-20 9.83a27.18 27.18 0 01-21.3-9.88 35.86 35.86 0 01-4.49-6.51c-.19.66-.39 1.31-.58 2a38 38 0 003.93 5.47 29.06 29.06 0 0022.4 10.42 25.49 25.49 0 0021.24-10.5z"
				/>
				<path d="M828.23 740c-5.73 4-16.3 10.16-38.26 7.85a76.26 76.26 0 00.65-10.79l37-1.47a30.65 30.65 0 00.61 4.41z" fill="#09030b" />
				<path className="-_25_Passport_front_image_svg__cls-13" d="M776.12 691.33s-8.21-11.17-14.59-4.56 5.93 29.64 17.1 24.62z" />
				<path
					className="-_25_Passport_front_image_svg__cls-16"
					d="M773.84 706.51a.74.74 0 01-.74-.64c-1.43-10.27-9.58-16.57-9.67-16.63a.75.75 0 11.91-1.19c.36.27 8.73 6.74 10.25 17.61a.76.76 0 01-.64.85z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-16"
					d="M771.57 699.14a.73.73 0 01-.62-.33c-1.09-1.61-4.35-1.77-5.51-1.7a.74.74 0 01-.79-.7.74.74 0 01.7-.8c.2 0 5-.28 6.84 2.36a.75.75 0 01-.2 1 .74.74 0 01-.42.17z"
				/>
				<path className="-_25_Passport_front_image_svg__cls-13" d="M847.35 691.33s8.21-11.17 14.59-4.56-5.92 29.64-17.09 24.62z" />
				<path
					className="-_25_Passport_front_image_svg__cls-16"
					d="M848.83 706.51h-.1a.75.75 0 01-.64-.85c1.51-10.87 9.89-17.34 10.25-17.61a.75.75 0 11.9 1.19c-.08.06-8.24 6.38-9.66 16.63a.76.76 0 01-.75.64z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-16"
					d="M851.11 699.14a.72.72 0 01-.42-.13.74.74 0 01-.2-1c1.79-2.64 6.63-2.37 6.84-2.36a.75.75 0 01-.09 1.5c-1.17-.07-4.43.1-5.51 1.7a.74.74 0 01-.62.29z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-13"
					d="M816.29 644.77c-32.07-.88-44.64 15.08-44.64 48.32 0 15.55 4.29 48.16 38.85 49.16 37 1.06 40.67-39.53 40.67-52.48 0-22.4-5.06-37.37-24.86-43.44a87.77 87.77 0 00-10.02-1.56z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-11"
					d="M772.32 703.23s5.23-14.31 3-24.85l-7.41-2.15s.39 18.09 4.41 27zM850.24 702.21s-5.08-13.29-2.82-23.83l7.4-2.15s-.57 17.07-4.58 25.98z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-11"
					d="M776.36 632.81c-8.21 8.11-14.61 21.4-15 29.51s1.67 16.38 7.83 19.51c12.61 6.42 35.87-.78 50.42-6.51a22.25 22.25 0 0121.22 2.62c3.56 2.58 8 4.63 12.57 4.07 11.07-1.35 15.79-33.56-10.57-51.58s-53.89-10.04-66.47 2.38z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-16"
					d="M813.23 715.05a10.64 10.64 0 01-4.25-1 1 1 0 01-.62-1.08l2.18-16.68a1 1 0 112.05.27l-2.06 15.88c2.15.79 3.81.72 5.18-.2a1 1 0 011.16 1.72 6.32 6.32 0 01-3.64 1.09zM810.71 725.83a22 22 0 01-2.53-.47 24.05 24.05 0 01-7.82-3.46 1 1 0 011.23-1.68c.46.34 11.43 8.2 22.83.07a1 1 0 011.45.24 1 1 0 01-.25 1.45 20.57 20.57 0 01-14.91 3.85z"
				/>
				<g className="-_25_Passport_front_image_svg__cls-17">
					<rect className="-_25_Passport_front_image_svg__cls-18" x={684.55} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={705.56} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={726.58} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={747.6} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={768.61} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={789.63} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={810.64} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={831.66} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={852.68} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={873.69} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={894.71} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={915.72} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={936.74} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={957.76} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={978.77} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={999.79} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1020.8} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1041.82} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1062.83} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1083.85} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1105.51} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1126.53} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1147.55} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1168.56} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1189.58} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1210.59} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1231.61} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1252.63} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1273.64} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1294.66} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1315.67} y={898.19} width={10.3} height={19.81} rx={5.11} ry={5.11} />
				</g>
				<g className="-_25_Passport_front_image_svg__cls-17">
					<rect className="-_25_Passport_front_image_svg__cls-18" x={684.55} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={705.56} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={726.58} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={747.6} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={768.61} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={789.63} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={810.64} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={831.66} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={852.68} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={873.69} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={894.71} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={915.72} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={936.74} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={957.76} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={978.77} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={999.79} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1020.8} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1041.82} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1062.83} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1083.85} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1105.51} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1126.53} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1147.55} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1168.56} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1189.58} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1210.59} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1231.61} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1252.63} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1273.64} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1294.66} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1315.67} y={920.85} width={10.3} height={19.81} rx={5.11} ry={5.11} />
				</g>
				<g className="-_25_Passport_front_image_svg__cls-17">
					<rect className="-_25_Passport_front_image_svg__cls-18" x={684.55} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={705.56} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={726.58} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={747.6} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={768.61} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={789.63} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={810.64} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={831.66} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={852.68} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={873.69} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={894.71} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={915.72} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={936.74} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={957.76} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={978.77} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={999.79} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1020.8} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1041.82} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1062.83} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1083.85} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1105.51} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1126.53} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1147.55} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1168.56} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1189.58} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1210.59} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1231.61} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1252.63} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1273.64} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1294.66} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_25_Passport_front_image_svg__cls-18" x={1315.67} y={945.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
				</g>
				<circle className="-_25_Passport_front_image_svg__cls-7" cx={787.41} cy={771.5} r={2.69} />
				<circle className="-_25_Passport_front_image_svg__cls-7" cx={791.82} cy={776.07} r={2.69} />
				<circle className="-_25_Passport_front_image_svg__cls-7" cx={796.94} cy={779.75} r={2.69} />
				<circle className="-_25_Passport_front_image_svg__cls-7" cx={802.57} cy={782.25} r={2.69} />
				<circle className="-_25_Passport_front_image_svg__cls-7" cx={808.59} cy={783.35} r={2.69} />
				<circle className="-_25_Passport_front_image_svg__cls-7" cx={814.81} cy={783.35} r={2.69} />
				<circle className="-_25_Passport_front_image_svg__cls-7" cx={820.97} cy={781.44} r={2.69} />
				<circle className="-_25_Passport_front_image_svg__cls-7" cx={826.56} cy={778.13} r={2.69} />
				<circle className="-_25_Passport_front_image_svg__cls-7" cx={831.09} cy={773.69} r={2.69} />
				<path
					className="-_25_Passport_front_image_svg__cls-18"
					d="M1306.3 793.15c-3.65-8.8-14.83-10.81-22.34-5.46-11.5 8.2-9.83 23.63-12.64 35.78-1.49 6.44-5.51 14.93-13.07 15.5a9.53 9.53 0 01-8.17-3.47c-3.44-4.6 1-9.94 5.92-10.35 8.61-.73 11.65 8.58 17.79 12.58a21.33 21.33 0 0016 2.58c10-2.19 20.75-12.8 22.09-23.2.48-3.76-.65-9.53-4.84-10.88-3.95-1.26-8.55 2.15-10.32 5.46-5.35 10 5.78 18.36 15 17.11s22.83-10.61 17.88-21.33-19.32.77-13.29 9.82c3.1 4.65 9.17 5.81 14.15 3.85 6.43-2.52 9.87-8.59 12.84-14.45l-4.75-1.29a73.68 73.68 0 01-6.48 29.12c-3.51 7.67-9.77 19.89-19.91 18.78-3.62-.39-7.34-2.51-4.44-6.8 1.23-1.82 3.86-3.28 5.61-4.51a76.16 76.16 0 0111.43-6.56c8.34-3.93 17.26-6.5 26.18-8.77 3.17-.81 1.83-5.73-1.36-4.92-12.5 3.19-25.1 7.08-36.08 14-3.88 2.46-9.68 5.78-11.58 10.19s.86 9 4.93 11.11c10.86 5.57 20.88-4.49 26-13a78.58 78.58 0 0010.82-38.63c.06-2.45-3.59-3.56-4.75-1.28-2.11 4.18-4.33 8.88-8.48 11.41-2.25 1.37-5.36 2.19-7.8.76-5.26-3.09-.37-10.77 2.64-6 2.82 4.5-5.32 9.85-8.36 11.47-4.46 2.35-11.1 3.5-14.73-1a5.9 5.9 0 01.35-8.24c2.49-2.31 3.86-.71 4.33 2 .84 4.83-4.39 11.38-7.61 14.61-7.17 7.2-18.52 9.34-26 1.33-5.4-5.82-10.94-12.45-19.92-10.21-6 1.49-10.85 7.54-9.48 13.85 1.22 5.63 6.75 9.32 12.25 9.86 7.07.7 13.15-4.17 16.53-10 5.41-9.32 4.13-20.32 6.91-30.39 1.32-4.78 3.83-10 8.38-12.53 4.35-2.37 11.28-1.68 13.38 3.4 1.24 3 6.18 1.68 4.92-1.36z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-19"
					d="M1878.08 668.83c-54.71-45.77-166.57-115.83-199.32-137.43s-63 30.34-1.78 95.46c0 0-94.82 9-123.39 20.6s-63 46 6.71 67.29 130.07 15.33 130.07 15.33S1569 778.83 1540.08 801s-25 63.65 10.61 65.55 176.57-54.08 176.57-54.08S1502 948.21 1537.19 995.82s109.31 18.49 155.82-17.39 76.26-6.91 82.49 23"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-13"
					d="M2002.05 1553.39s253.1-153 256.22-284.4c0 0-80.45-97.56-126.08-180.69s-117.56-265.89-188.34-343-136.54-164.85-191.25-210.63-169.25-112.24-199.32-137.44c-32.85-27.52-76.61-8.94-43.73 67.26 13.43 31.14 127.76 159.79 150.51 185 0 0 91.17 136.28 110 186.93 7.06 19 25.66 28.81 25.16 62.23-.83 55.71-28.94 137-62.8 142.81"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-13"
					d="M1775.43 1037.12c-30.4 2.56-63.6 4.37-63.6 4.37s-249.75 4.11-303 48-41.69 72.22 8.38 82.07 172.74 26.58 227.69 61.09 120.31 94.41 193.75 127.14 137.85 155.41 137.85 155.41c4.23-3.16 30.56-33 31.07-69.47s-204.24-410.96-232.14-408.61z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-20"
					d="M1795.2 1044.29c-27.28-7.41-69.13-.74-108.37-2.07M1616.49 600.2s48.76 28.91 140.68 217.66"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-19"
					d="M129.41 668.83C184.12 623.06 296 553 328.73 531.4s63 30.34 1.78 95.46c0 0 94.82 9 123.39 20.6s63 46-6.71 67.29-130.07 15.33-130.07 15.33 121.4 48.75 150.29 70.9 25 63.65-10.61 65.55-176.57-54.08-176.57-54.08S505.5 948.21 470.3 995.82s-109.3 18.49-155.82-17.39-76.26-6.91-82.49 23"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-13"
					d="M5.44 1553.39s-253.1-153-256.22-284.4c0 0 80.45-97.56 126.08-180.69s117.56-265.89 188.34-343 136.53-164.85 191.25-210.63 169.25-112.24 199.32-137.44c32.85-27.52 76.6-8.94 43.73 67.26-13.43 31.14-127.77 159.79-150.51 185 0 0-91.17 136.28-110 186.93-7.05 19-25.65 28.81-25.15 62.23.83 55.71 28.93 137 62.8 142.81"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-13"
					d="M232.06 1037.12c30.4 2.56 63.6 4.37 63.6 4.37s249.75 4.11 303 48 41.69 72.22-8.38 82.07-172.74 26.58-227.69 61.09-120.31 94.41-193.75 127.14-142.13 155-142.13 155c-4.23-3.15-26.28-32.62-26.79-69.09s204.24-410.93 232.14-408.58z"
				/>
				<path
					className="-_25_Passport_front_image_svg__cls-20"
					d="M212.29 1044.29c27.28-7.41 69.13-.74 108.37-2.07M391 600.2s-48.76 28.91-140.68 217.66"
				/>
				<path
					d="M1543.76 454.28H465.4a52.51 52.51 0 00-52.51 52.5v525.56a52.5 52.5 0 0052.51 52.5h1078.36a52.5 52.5 0 0052.51-52.5V506.78a52.51 52.51 0 00-52.51-52.5zm-29.32 609.51H494.72V475.33h1019.72z"
					fill="#32384f"
				/>
				<path fill="#fff" opacity={0.11} d="M494.72 475.33h1019.72v588.46H494.72z" />
				<path opacity={0.39} fill="#fff" d="M1514.44 1067.77H716.57l797.87-588.46v588.46z" />
				<path className="-_25_Passport_front_image_svg__cls-1" d="M126.41 16.65h1774.92v1500.51H126.41z" />
				<circle className="-_25_Passport_front_image_svg__cls-7" cx={929.68} cy={936.89} r={2.17} />
			</g>
		</svg>
	);
}

export default Svg25Passportfrontimage;
