import React from 'react';
import StepsProgressBar from '../StepsProgressBar';

type Props = {
	currentStep?: number;
};

const PageHeader = ({ currentStep = 0 }: Props): React.ReactElement => (
	<header>
		<div className="max-w-7xl  mt-2 mb-4 mx-auto px-4 sm:px-6 lg:px-8">
			{/* <h1 className="text-3xl font-bold leading-tight text-gray-900">Dashboard</h1> */}
			<StepsProgressBar currentStep={currentStep} />
		</div>
	</header>
);

export default PageHeader;
