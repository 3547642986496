import * as React from 'react';

function Svg32Right(props) {
	return (
		<svg data-name="\u0421\u043B\u043E\u0439 1" viewBox="0 0 547.34 557.72" {...props}>
			<path
				d="M547.34 279.24c.2-153-124-278.24-277-279.23C142.93-.82 35.17 83.79 1 199.91a23.07 23.07 0 0022.16 29.54h236.32l-69-69a43.35 43.35 0 010-61.32 43.35 43.35 0 0161.31 0l146.7 146.7a43.24 43.24 0 0112.63 33 43.28 43.28 0 01-12.63 33.05L251.78 458.6a43.34 43.34 0 01-61.31 0 43.34 43.34 0 010-61.31l66-66H24a23.08 23.08 0 00-22 29.86C37.09 475 143.11 557.72 268.48 557.72c153.88 0 278.65-124.65 278.86-278.48z"
				fill="#fff"
			/>
		</svg>
	);
}

export default Svg32Right;
