import * as React from 'react';

function SvgOtherDocument(props) {
	return (
		<svg id="other_document_svg__\u0421\u043B\u043E\u0439_1" x={0} y={0} viewBox="0 0 734.1 860.5" xmlSpace="preserve" {...props}>
			<style>{'.other_document_svg__st4{fill:#c2c9ce}'}</style>
			<path
				d="M37 0h661.5c19.7 0 35.7 16 35.7 35.7v789.1c0 19.7-16 35.7-35.7 35.7H37c-19.7 0-35.7-16-35.7-35.7V35.7C1.3 16 17.2 0 37 0z"
				fill="#e6ebef"
			/>
			<path
				d="M690.9 42v776.5H42V42h648.9m6.3-42H35.7C16 0 0 16 0 35.7v789.1c0 19.7 16 35.7 35.7 35.7h661.5c19.7 0 35.7-16 35.7-35.7V35.7c0-19.7-16-35.7-35.7-35.7z"
				fill="#d7dce0"
			/>
			<path
				d="M168.5 249.4h398.4c13 0 23.5 10.5 23.5 23.5v398.4c0 13-10.5 23.5-23.5 23.5H168.5c-13 0-23.5-10.5-23.5-23.5V273c0-13 10.5-23.6 23.5-23.6z"
				fill="#e6ebef"
				stroke="#d7dce0"
				strokeWidth={30}
				strokeMiterlimit={10}
			/>
			<circle
				transform="matrix(.07089 -.9975 .9975 .07089 -392.123 801.22)"
				cx={234}
				cy={611.1}
				opacity={0.93}
				fill="none"
				stroke="#c2c9ce"
				strokeWidth={17.93}
				strokeMiterlimit={10}
				r={34.4}
			/>
			<path
				className="other_document_svg__st4"
				d="M242.6 310h247.2c10.4 0 18.9 8.4 18.9 18.9 0 10.4-8.4 18.9-18.9 18.9H242.6c-10.4 0-18.9-8.4-18.9-18.9.1-10.5 8.5-18.9 18.9-18.9zM218.5 379.3h137.2c10.4 0 18.9 8.4 18.9 18.9 0 10.4-8.4 18.9-18.9 18.9H218.5c-10.4 0-18.9-8.4-18.9-18.9.1-10.4 8.5-18.9 18.9-18.9zM234.5 447.5h260c10.4 0 18.9 8.4 18.9 18.9 0 10.4-8.4 18.9-18.9 18.9h-260c-10.4 0-18.9-8.4-18.9-18.9 0-10.5 8.5-18.9 18.9-18.9zM304.5 520.2h135.3c10.4 0 18.9 8.4 18.9 18.9 0 10.4-8.4 18.9-18.9 18.9H304.5c-10.4 0-18.9-8.4-18.9-18.9 0-10.4 8.4-18.9 18.9-18.9zM425.7 379.3h93.4c10.4 0 18.9 8.4 18.9 18.9 0 10.4-8.4 18.9-18.9 18.9h-93.4c-10.4 0-18.9-8.4-18.9-18.9 0-10.4 8.5-18.9 18.9-18.9z"
			/>
			<path d="M183.9 165.2s28.6-4.4 88.5-28.1" fill="none" stroke="#e6eaed" strokeWidth={25} strokeLinecap="round" strokeMiterlimit={10} />
		</svg>
	);
}

export default SvgOtherDocument;
