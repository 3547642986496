import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Theme, createStyles, ThemeProvider, withStyles, createTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import classnames from 'classnames';
import StepConnector from '@material-ui/core/StepConnector';
import Step from '@material-ui/core/Step';
import NoSsr from '../NoSsr';

const Step1Icon = () => <span>01</span>;
const Step2Icon = () => <span>02</span>;
const Step3Icon = () => <span>03</span>;

// stepper label text styles
const stepperLabelTheme = createTheme({
	palette: {
		text: {
			primary: '#624de6',
			secondary: '#bcbfc6',
		},
	},
	typography: {
		fontSize: 12,
	},
});
// stepper label styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: 0,
			marginTop: 10,
			backgroundColor: 'transparent',
		},
		step: {},
		labelContainer: {
			'& $alternativeLabel': {
				marginTop: 0,
			},
		},
		alternativeLabel: {},
	}),
);
// stepper line connector styles
const StepperLineConnector = withStyles({
	alternativeLabel: {
		top: 13,
	},
	active: {
		'& $line': {
			backgroundColor: '#624de6',
		},
	},
	completed: {
		'& $line': {
			backgroundColor: '#624de6',
		},
	},
	line: {
		height: 1,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
})(StepConnector);
// stepper icon styles
const useStepIconStyles = makeStyles({
	root: {
		zIndex: 1,
		color: '#bcbfc6',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active: {
		color: '#624de6',
	},
	completed: {
		color: '#624de6',
	},
});

const CustomStepIcon = (props: StepIconProps) => {
	const classes = useStepIconStyles();
	const { active, completed, icon } = props;

	const icons: { [index: string]: React.ReactElement } = {
		1: <Step1Icon />,
		2: <Step2Icon />,
		3: <Step3Icon />,
	};

	return (
		<div
			className={classnames([
				classes.root,
				{
					[classes.active]: active,
					[classes.completed]: completed,
				},
			])}
		>
			{icons[String(icon)]}
		</div>
	);
};

export const getSteps = (currentStep: number): any => [
	{
		text: 'ID Documents',
		link: '/',
		active: currentStep === 1,
		icon: (
			<svg
				className="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600"
				fill="currentColor"
				viewBox="0 0 20 20"
			>
				<path
					fillRule="evenodd"
					d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
					clipRule="evenodd"
				/>
			</svg>
		),
	},
	{
		text: 'Liveness test',
		link: '/document-photo',
		active: currentStep === 2,
		icon: (
			<svg className="-ml-0.5 mr-2 h-5 w-5 text-indigo-500 group-focus:text-indigo-600" fill="currentColor" viewBox="0 0 20 20">
				<path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
			</svg>
		),
	},
	{
		text: 'Result',
		link: '/liveness-test',
		active: currentStep === 3,
		icon: (
			<svg
				className="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600"
				fill="currentColor"
				viewBox="0 0 20 20"
			>
				<path
					fillRule="evenodd"
					d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4zm14 5H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
					clipRule="evenodd"
				/>
			</svg>
		),
	},
];

const HorizontalLabelPositionBelowStepper = ({ currentStep }) => {
	const classes = useStyles();
	const [activeStep] = React.useState(currentStep);
	const steps = getSteps(0);

	return (
		<NoSsr>
			<ThemeProvider theme={stepperLabelTheme}>
				<Stepper className={classes.root} connector={<StepperLineConnector />} activeStep={activeStep} alternativeLabel>
					{steps.map(item => (
						<Step key={item.text} className={classes.step}>
							<StepLabel
								StepIconComponent={CustomStepIcon}
								style={{ fontSize: 20 }}
								classes={{ alternativeLabel: classes.alternativeLabel, labelContainer: classes.labelContainer }}
							>
								{item.text.toUpperCase()}
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</ThemeProvider>
		</NoSsr>
	);
};
HorizontalLabelPositionBelowStepper.propTypes = {
	currentStep: PropTypes.number,
};
HorizontalLabelPositionBelowStepper.defaultProps = {
	currentStep: 0,
};

export default HorizontalLabelPositionBelowStepper;
