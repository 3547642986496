import * as React from 'react';

function Svg07Medicare1(props) {
	return (
		<svg id="-_07_Medicare_-_1_svg__\u0421\u043B\u043E\u0439_1" data-name="\u0421\u043B\u043E\u0439 1" viewBox="0 0 946 583.8" {...props}>
			<defs>
				<style>{'.-_07_Medicare_-_1_svg__cls-2{fill:#e6ebef}.-_07_Medicare_-_1_svg__cls-4{fill:#c2c9ce}'}</style>
			</defs>
			<rect width={946} height={583.8} rx={35.71} ry={35.71} fill="#fff" />
			<rect className="-_07_Medicare_-_1_svg__cls-2" width={946} height={583.8} rx={35.71} ry={35.71} />
			<path
				className="-_07_Medicare_-_1_svg__cls-4"
				d="M588.84 75l-9 17a17.93 17.93 0 0015.84 26.32h206.17a71.11 71.11 0 0059.74-32.54l13.52-20.93a17.93 17.93 0 00-15.06-27.65H651.67A71.09 71.09 0 00588.84 75z"
			/>
			<path
				className="-_07_Medicare_-_1_svg__cls-2"
				d="M623.17 96.63h182.06c6.26 0 14.86-5.07 19.22-11.33L835 70.1c4.35-6.26 2.81-11.34-3.45-11.34H649.51c-6.26 0-14.86 5.08-19.21 11.34l-10.58 15.2c-4.35 6.26-2.81 11.33 3.45 11.33z"
			/>
			<rect className="-_07_Medicare_-_1_svg__cls-4" x={86.76} y={323.85} width={102.27} height={23.46} rx={10.06} ry={10.06} />
			<rect className="-_07_Medicare_-_1_svg__cls-4" x={351.74} y={323.85} width={197.56} height={23.46} rx={10.06} ry={10.06} />
			<rect className="-_07_Medicare_-_1_svg__cls-4" x={86.76} y={375.76} width={160.44} height={23.46} rx={10.06} ry={10.06} />
			<rect className="-_07_Medicare_-_1_svg__cls-4" x={351.74} y={375.76} width={197.56} height={23.46} rx={10.06} ry={10.06} />
			<rect className="-_07_Medicare_-_1_svg__cls-4" x={86.76} y={427.68} width={220.48} height={23.46} rx={10.06} ry={10.06} />
			<rect className="-_07_Medicare_-_1_svg__cls-4" x={351.74} y={427.68} width={197.56} height={23.46} rx={10.06} ry={10.06} />
			<rect className="-_07_Medicare_-_1_svg__cls-4" x={618.01} y={505.81} width={222.85} height={26.46} rx={11.35} ry={11.35} />
			<rect className="-_07_Medicare_-_1_svg__cls-4" x={86.76} y={175.81} width={251.52} height={79.49} rx={17.91} ry={17.91} />
			<rect className="-_07_Medicare_-_1_svg__cls-4" x={395.76} y={175.81} width={315.52} height={79.49} rx={17.91} ry={17.91} />
		</svg>
	);
}

export default Svg07Medicare1;
