import * as React from 'react';

function Svg30Down(props) {
	return (
		<svg data-name="\u0421\u043B\u043E\u0439 1" viewBox="0 0 557.72 547.34" {...props}>
			<path
				d="M278.49 547.34c153 .2 278.23-124 279.23-277C558.54 142.93 473.94 35.17 357.81 1a23.06 23.06 0 00-29.53 22.16v236.32l69-69a43.34 43.34 0 0161.31 0 43.34 43.34 0 010 61.31L311.91 398.48a43.28 43.28 0 01-33.05 12.63 43.24 43.24 0 01-33-12.63L99.12 251.78a43.35 43.35 0 010-61.31 43.35 43.35 0 0161.32 0l66 66V24a23.08 23.08 0 00-29.87-22C82.73 37.09 0 143.11 0 268.48c0 153.88 124.65 278.65 278.49 278.86z"
				fill="#fff"
			/>
		</svg>
	);
}

export default Svg30Down;
