import * as React from 'react';

function PassportSvg01(props) {
	return (
		<svg id="-_01_Passport_-1_svg__\u0421\u043B\u043E\u0439_1" data-name="\u0421\u043B\u043E\u0439 1" viewBox="0 0 946 558" {...props}>
			<defs>
				<style>
					{'.-_01_Passport_-1_svg__cls-2{fill:#c2c9ce}.-_01_Passport_-1_svg__cls-7{fill:#e8ebed}.-_01_Passport_-1_svg__cls-8{fill:#98a0a5}'}
				</style>
			</defs>
			<rect width={946} height={558} rx={42} ry={42} fill="#e6ebef" />
			<rect className="-_01_Passport_-1_svg__cls-2" x={443.8} y={59.34} width={368.28} height={40.76} rx={20.38} ry={20.38} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={443.8} y={129.54} width={276.71} height={40.76} rx={20.38} ry={20.38} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={443.8} y={199.74} width={345.94} height={40.76} rx={20.38} ry={20.38} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={443.8} y={269.94} width={428.51} height={40.76} rx={20.38} ry={20.38} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={443.8} y={340.14} width={428.51} height={40.76} rx={20.38} ry={20.38} />
			<rect x={85.54} y={59.34} width={309.97} height={321.56} rx={15.45} ry={15.45} fill="#fff" />
			<path
				d="M378.61 380.9h-276.8s2.78-34.53 19.49-41.22c6.75-2.7 22.23-10.4 38.28-18.62 6-3.05 12-6.17 17.7-9.14l1.79-.93c4.67-2.44 9.09-4.74 13-6.8 8.6-4.51 14.75-7.78 15.92-8.4l.2-.11 2.12.48c2.65.59 5.3 1.1 8 1.52s5.28.77 7.93 1a132 132 0 0043.74-3h.07s6.5 3.35 16.05 8.22c4 2 8.47 4.32 13.26 6.74l1.82.92 7.16 3.61c2.58 1.3 5.2 2.63 7.82 3.93 17.85 8.93 35.72 17.67 43 20.58 16.67 6.69 19.45 41.22 19.45 41.22z"
				fill="#dde3e8"
			/>
			<path
				d="M301.14 311.56c-.95 2.77-2 5.6-3.3 8.42-5 11.16-11.46 20-19.33 26.44-9.87 8-21.89 12.07-35.75 12.07-28.94 0-46.81-17-56.7-31.19a103.39 103.39 0 01-8.78-15.38l1.79-.93a101.57 101.57 0 008.68 15.21c14 20.09 32.5 30.27 55 30.27 24.33 0 42.24-12.55 53.24-37.3 1.27-2.86 2.37-5.73 3.32-8.53z"
				fill="#8fafe2"
				opacity={0.25}
			/>
			<path
				d="M286.06 303.9c-3.48 15-16.69 37.82-45.85 37.82-32 0-44.05-25.91-48.15-37.53 8.6-4.51 14.75-7.78 15.92-8.4l.2-.11 2.12.48c2.65.59 5.3 1.1 8 1.52s5.28.77 7.93 1a132 132 0 0043.74-3h.07l16.02 8.22z"
				fill="#f0f5f7"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-7"
				d="M271.41 298.84s-5.43 21-30.37 21c-29.09 0-35.94-19.06-35.94-19.06a184 184 0 005.43-22.66c2.16-12.48 4.94-26.33 4.81-32.34L264 247.3c.48 5.32 1.24 11.58 2.11 17.87 2.29 16.72 5.3 33.67 5.3 33.67z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M265.22 258.88c-7.92 6.76-22.63 13.49-53 9.56 2.16-12.48 4.06-20.68 3.93-26.69L264 247.3c.46 5.32.35 5.28 1.22 11.58z"
			/>
			<path className="-_01_Passport_-1_svg__cls-7" d="M195.08 196.82s-10.71-14.58-19-5.95 7.73 38.66 22.31 32.12z" />
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M193.16 216.35a1 1 0 01-.95-.82c-1.81-13.05-12.18-21.06-12.29-21.14a.94.94 0 01-.18-1.33 1 1 0 011.34-.19c.45.35 11.1 8.58 13 22.4a1 1 0 01-.81 1.07z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M193.16 217a1.58 1.58 0 01-1.57-1.36c-1.78-12.77-12-20.65-12.05-20.72a1.59 1.59 0 011.92-2.53c.46.35 11.31 8.73 13.27 22.81a1.53 1.53 0 01-.31 1.17 1.58 1.58 0 01-1 .62zm-12.66-23.69a.35.35 0 00-.26.13.33.33 0 00.06.45c.11.08 10.68 8.17 12.54 21.55a.32.32 0 00.32.28h.05a.35.35 0 00.27-.37c-1.89-13.56-12.34-21.64-12.79-22a.26.26 0 00-.19-.04z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M190.27 207a1 1 0 01-.79-.42c-1.39-2.05-5.53-2.26-7-2.17a1 1 0 01-1-.89 1 1 0 01.9-1c.26 0 6.42-.36 8.69 3a1 1 0 01-.25 1.33.94.94 0 01-.55.15z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M190.27 207.61a1.59 1.59 0 01-1.32-.7c-1.21-1.79-5.15-2-6.44-1.88a1.56 1.56 0 01-1.14-.39 1.59 1.59 0 01-.54-1.1 1.62 1.62 0 01.4-1.15 1.6 1.6 0 011.09-.53c.27 0 6.77-.4 9.26 3.28a1.59 1.59 0 01-.43 2.2 1.52 1.52 0 01-.88.27zm-7.27-3.86c1.49 0 5.48.22 7 2.46a.28.28 0 00.44.08.31.31 0 00.14-.2.29.29 0 00-.05-.24c-2.08-3.08-8.07-2.73-8.13-2.73a.32.32 0 00-.31.34.35.35 0 00.12.23.24.24 0 00.22.07z"
			/>
			<path className="-_01_Passport_-1_svg__cls-7" d="M291.55 196.82s10.71-14.58 19-5.95-7.74 38.66-22.31 32.12z" />
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M290.38 216.35h-.13a1 1 0 01-.82-1.07c1.92-13.82 12.58-22.05 13-22.4a1 1 0 011.15 1.52c-.1.08-10.48 8.12-12.29 21.14a1 1 0 01-.91.81z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M290.38 217h-.22a1.6 1.6 0 01-1.05-.62 1.57 1.57 0 01-.3-1.17c1.95-14.08 12.81-22.46 13.27-22.81a1.58 1.58 0 111.91 2.52c-.1.08-10.27 8-12 20.73a1.59 1.59 0 01-1.61 1.35zM303 193.31a.3.3 0 00-.19.07c-.44.33-10.9 8.4-12.78 22a.34.34 0 00.06.24.32.32 0 00.21.13h.05a.33.33 0 00.32-.28c1.85-13.36 12.42-21.47 12.53-21.55a.32.32 0 00.06-.45.33.33 0 00-.26-.16z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M293.27 207a1 1 0 01-.54-.16 1 1 0 01-.25-1.33c2.27-3.36 8.44-3 8.7-3a1 1 0 01.89 1 .94.94 0 01-1 .89c-1.48-.08-5.62.13-7 2.17a1 1 0 01-.8.43z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M293.27 207.61a1.56 1.56 0 01-.89-.27 1.59 1.59 0 01-.67-1 1.57 1.57 0 01.25-1.19c2.42-3.58 8.56-3.32 9.26-3.28a1.58 1.58 0 01.94 2.77 1.51 1.51 0 01-1.14.4c-1.28-.08-5.23.1-6.44 1.88a1.57 1.57 0 01-1.31.69zm7.22-4.5c-1.61 0-5.8.25-7.49 2.74a.33.33 0 00-.05.24.35.35 0 00.14.2.32.32 0 00.44-.08c1.86-2.75 7.51-2.45 7.57-2.45a.29.29 0 00.23-.07.35.35 0 00.11-.23.33.33 0 00-.3-.34z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-7"
				d="M250.58 135.92c-39.33 0-63.78 13.27-60.2 54.87 2.9 33.57 8.22 67.36 53.21 67.36 34.53 0 53.6-37 51-64.48-2.19-23.5-8.77-48.84-33.94-56.17a62 62 0 00-10.07-1.58z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M190.76 194.52s-10.57-25.73-6-54.56c3.76-23.6 25.06-36.91 60.45-36.84 28.54.06 34.42 9.49 36.72 19.61 0 0 18.2 3.73 21.95 18.32 6.61 25.73-10.17 59.13-10.17 59.13a145.84 145.84 0 01-7.71-24.93c-3.22-14.72-7.41-23.72-16.6-23.9s-20.69.27-33.1 7.63-24.83 8.27-33.57 1.84c.02 0-2.31 22.18-11.97 33.7z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M244.08 218.87a13.73 13.73 0 01-5.42-1.29 1.36 1.36 0 01-.8-1.42l2.76-21.2a1.36 1.36 0 012.69.35l-2.62 20.15c2.71 1 4.8.91 6.52-.26a1.36 1.36 0 111.52 2.26 8.09 8.09 0 01-4.65 1.41z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M244.08 219.5a14.31 14.31 0 01-5.67-1.34 2 2 0 01-1.18-2.09l2.77-21.19a2 2 0 113.95.51L241.38 215c2.28.75 4 .63 5.48-.35a2 2 0 012.76.54 2 2 0 01-.53 2.76 8.8 8.8 0 01-5.01 1.55zM242 194.41a.72.72 0 00-.72.63l-2.76 21.2a.74.74 0 00.43.76c3.85 1.67 6.92 1.66 9.47-.06a.76.76 0 00.31-.47.77.77 0 00-.11-.55.71.71 0 00-.47-.3.69.69 0 00-.54.11c-1.9 1.27-4.22 1.38-7.1.33l-.47-.18 2.69-20.65a.73.73 0 00-.63-.82zM240.74 234.9a31.47 31.47 0 01-13-5.67 1.36 1.36 0 111.72-2.1c.59.48 14 11.13 28.94 1.58a1.35 1.35 0 011.87.41 1.36 1.36 0 01-.41 1.88 26 26 0 01-19.12 3.9z"
			/>
			<path
				className="-_01_Passport_-1_svg__cls-8"
				d="M245.55 236a27.62 27.62 0 01-4.92-.45 32 32 0 01-13.29-5.8 2 2 0 012.52-3.08c.55.45 13.61 10.84 28.2 1.53a2 2 0 112.14 3.36 26.83 26.83 0 01-14.65 4.44zm-17-8.52a.71.71 0 00-.56.27.69.69 0 00-.16.53.67.67 0 00.26.49 30.9 30.9 0 0012.72 5.54 25.4 25.4 0 0018.66-3.81.73.73 0 10-.79-1.23c-15.34 9.8-29.09-1.15-29.67-1.62a.73.73 0 00-.41-.2z"
			/>
			<rect className="-_01_Passport_-1_svg__cls-2" x={85.54} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={111.32} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={137.1} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={162.87} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={188.65} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={214.43} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={240.21} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={265.98} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={291.76} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={317.54} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={343.32} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={369.1} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={394.87} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={420.65} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={446.43} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={472.21} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={497.98} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={523.76} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={549.54} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={575.32} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={601.89} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={627.67} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={653.44} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={679.22} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={705} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={730.78} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={756.55} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={782.33} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={808.11} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={833.89} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			<rect className="-_01_Passport_-1_svg__cls-2" x={859.66} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
		</svg>
	);
}

export default PassportSvg01;
