import * as React from 'react';

function Svg20Driverlicencefrontimage(props) {
	return (
		<svg
			id="-_20_Driver_licence_front_image_svg__\u0421\u043B\u043E\u0439_1"
			data-name="\u0421\u043B\u043E\u0439 1"
			viewBox="0 0 1989.16 1270.93"
			{...props}
		>
			<defs>
				<clipPath id="-_20_Driver_licence_front_image_svg__clip-path">
					<path className="-_20_Driver_licence_front_image_svg__cls-1" d="M0-16.13h1989.16v1287.05H0z" />
				</clipPath>
				<style>
					{
						'.-_20_Driver_licence_front_image_svg__cls-1,.-_20_Driver_licence_front_image_svg__cls-23{fill:none}.-_20_Driver_licence_front_image_svg__cls-11{fill:#1188f7}.-_20_Driver_licence_front_image_svg__cls-14{fill:#ffca27}.-_20_Driver_licence_front_image_svg__cls-15{fill:#4dbc76}.-_20_Driver_licence_front_image_svg__cls-8{fill:#fff;opacity:.2}.-_20_Driver_licence_front_image_svg__cls-11{opacity:.17}.-_20_Driver_licence_front_image_svg__cls-13{fill:#382c36}.-_20_Driver_licence_front_image_svg__cls-16{fill:#09030b}.-_20_Driver_licence_front_image_svg__cls-17,.-_20_Driver_licence_front_image_svg__cls-19{fill:#32384f}.-_20_Driver_licence_front_image_svg__cls-17,.-_20_Driver_licence_front_image_svg__cls-19,.-_20_Driver_licence_front_image_svg__cls-23{stroke:#32384f}.-_20_Driver_licence_front_image_svg__cls-17,.-_20_Driver_licence_front_image_svg__cls-19{stroke-miterlimit:10}.-_20_Driver_licence_front_image_svg__cls-17{stroke-width:1.18px}.-_20_Driver_licence_front_image_svg__cls-19{stroke-width:.76px}.-_20_Driver_licence_front_image_svg__cls-22{fill:#f7b614}.-_20_Driver_licence_front_image_svg__cls-23{stroke-linecap:round;stroke-linejoin:round;stroke-width:14.03px}'
					}
				</style>
			</defs>
			<g clipPath="url(#-_20_Driver_licence_front_image_svg__clip-path)">
				<path
					d="M1278.56 437.27c88.44-74.64 180-209.41 104-317.82-56.45-80.55-156.71-117.23-253.36-95.6-38.92 8.71-76.64 23.2-116 29.75-48.84 8.13-88-2-133-17.57-77.66-26.8-171.8-13.74-238.8 39.71C572.09 131 559 216.86 604.69 288.7c29.33 46.11 75.73 74.53 110.52 115.53 26.73 31.49 46.39 68 59.23 107.6C793 569.13 791.77 640 826.77 690.2c21.77 31.24 57 46.13 93.67 53.25 41.54 8.08 104.9 18.85 142.76-8.36 38.06-27.35 56.81-81.06 78.12-120.74 35.74-66.56 79.51-127.59 137.24-177.08z"
					opacity={0.07}
					fill="#1188f7"
				/>
				<path
					d="M703.11 544.79S464 691.46 633.56 900.29C804.67 1111 954.18 867.13 1133 1005.3c306.21 236.68 638.11-101.9 345.16-373.49s-163.33-426.7-386.54-448.91c-249.54-24.82-163.34 242.15-388.51 361.89z"
					opacity={0.23}
					fill="#ffca27"
				/>
				<path
					d="M408.25 761.53s-69.26 219 147.63 257.15c218.8 38.53 180.5-192.44 365.16-202.86C1237.33 798 1261 410.6 934.2 399.74S604.37 223.43 454.42 329.4c-167.62 118.45 28.87 237.32-46.17 432.13z"
					opacity={0.12}
					fill="#4dbc76"
				/>
				<rect x={588.66} y={236.54} width={811.51} height={500.8} rx={35.71} ry={35.71} fill="#fff" />
				<rect x={588.66} y={236.54} width={811.51} height={500.8} rx={35.71} ry={35.71} opacity={0.14} fill="#1188f7" />
				<path
					className="-_20_Driver_licence_front_image_svg__cls-8"
					d="M624.37 236.54a35.48 35.48 0 00-12 2.07 85.11 85.11 0 0013.68 14.93c3 2.65 174-4 203 160s129 276 247 183 40-239 131-293c26-15.44 58.18-40 83.7-67z"
				/>
				<path
					d="M1364.46 737.34a35.71 35.71 0 0035.71-35.71V424c-105.53-57-276.45-91.33-360.1 22.51-97 132-257.3 169.84-340.15 180.42-27.78 3.55-72.46 27-111.26 62.22v12.45a35.71 35.71 0 0035.71 35.71z"
					opacity={0.25}
					fill="#fff"
				/>
				<path d="M624.37 236.54h740.09a35.71 35.71 0 0135.71 35.71v49.18H588.66v-49.18a35.71 35.71 0 0135.71-35.71z" fill="#ffe566" />
				<rect
					className="-_20_Driver_licence_front_image_svg__cls-11"
					x={1201.57}
					y={645.42}
					width={147.29}
					height={23.62}
					rx={11.81}
					ry={11.81}
				/>
				<rect x={1201.57} y={363.93} width={147.29} height={23.62} rx={11.81} ry={11.81} opacity={0.21} fill="#1188f7" />
				<rect
					className="-_20_Driver_licence_front_image_svg__cls-11"
					x={1100.54}
					y={686.1}
					width={248.32}
					height={23.62}
					rx={11.81}
					ry={11.81}
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-13"
					d="M1161.66 621.2s-42.24 8.49-44-27.07c-.78-15.4-2.85-13-16.31-26s-13.46-18.27-10.58-30.29 15-16.14 9-27.25c-7.89-14.63-5.47-39.63 11.67-49.38 16.58-9.42 18.45-9.3 18.45-19 0-12.62 13.1-32.95 40.13-30.84 23.25 1.82 19.64-9.5 36.12-14.54 30.7-9.38 45.57 17.2 56.15 18.16s28.87 1.14 38.16 15.2c11.52 17.43 1.35 31.66 15.77 33.1s28.84 13.94 28.36 26.92-8.17 24-2.88 29.81 7.33 39.93-14 46.47c-29.16 8.94 0 52.11-53.63 51.31-48.21-.65-112.41 3.4-112.41 3.4z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-14"
					d="M1247.31 560.33a10.26 10.26 0 01-5.39-7.12c-1.28-6.42-3.46-17.67-4.63-24.56-.63-3.72-1.19-7.41-1.53-10.54l-37 1.46a71.93 71.93 0 01-1.69 16.69c-.68 3.18-3.37 12.64-5.36 18.84a10.28 10.28 0 01-5 5.92l-10.89 5.73a168.23 168.23 0 007.17 21.47c6.18 15 16.29 32.61 31.06 38.74h17.68c10.73-5.75 17.11-22.4 20.8-37.59a203.25 203.25 0 004.24-24.2z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-15"
					d="M1330.61 627h-224.24s2.26-28 15.79-33.39c2.81-1.12 7.46-3.3 13.09-6.06.78-.38 1.58-.77 2.39-1.18 11.71-5.8 26.78-13.61 38.16-19.57a102.11 102.11 0 0014.43-8.82l2.19 2.59c11.25 14 38.25 18.75 51.79-2.6a107.89 107.89 0 0012.54 7.25c4.94 2.52 10.79 5.49 16.88 8.55 16.35 8.24 34.4 17.15 41.16 19.85 13.56 5.38 15.82 33.38 15.82 33.38z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-16"
					d="M1236.35 522.52c-5.74 4-16.3 10.16-38.26 7.84a77.69 77.69 0 00.65-10.79l37-1.46a31 31 0 00.61 4.41z"
				/>
				<path className="-_20_Driver_licence_front_image_svg__cls-14" d="M1184.23 473.82s-8.2-11.17-14.59-4.56 5.93 29.63 17.1 24.62z" />
				<path
					className="-_20_Driver_licence_front_image_svg__cls-17"
					d="M1182 489a.74.74 0 01-.74-.65c-1.43-10.26-9.59-16.56-9.67-16.62a.75.75 0 01.9-1.2c.36.27 8.74 6.75 10.25 17.62a.74.74 0 01-.64.84z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-17"
					d="M1179.68 481.63a.76.76 0 01-.62-.33c-1.09-1.61-4.35-1.78-5.5-1.71a.75.75 0 11-.1-1.49c.21 0 5.06-.29 6.84 2.36a.74.74 0 01-.2 1 .72.72 0 01-.42.17z"
				/>
				<path className="-_20_Driver_licence_front_image_svg__cls-14" d="M1255.47 473.82s8.2-11.17 14.59-4.56-5.93 29.63-17.1 24.62z" />
				<path
					className="-_20_Driver_licence_front_image_svg__cls-17"
					d="M1257 489h-.1a.73.73 0 01-.64-.84c1.51-10.87 9.88-17.35 10.24-17.62a.75.75 0 01.91 1.2c-.08.06-8.25 6.38-9.67 16.62a.74.74 0 01-.74.64z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-17"
					d="M1259.22 481.63a.74.74 0 01-.42-.13.75.75 0 01-.2-1c1.79-2.65 6.64-2.37 6.84-2.36a.75.75 0 01-.09 1.49c-1.16-.06-4.42.1-5.51 1.71a.75.75 0 01-.62.29z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-14"
					d="M1224.41 427.25c-32.07-.87-44.65 15.09-44.65 48.33 0 15.55 4.29 48.16 38.85 49.15 37.06 1.06 40.67-39.53 40.67-52.47 0-22.4-5.06-37.37-24.86-43.45a87.6 87.6 0 00-10.01-1.56z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-13"
					d="M1180.43 485.72s5.23-14.31 3-24.85l-7.4-2.16s.39 18.1 4.4 27.01zM1258.35 484.69s-5.08-13.28-2.82-23.82l7.4-2.16s-.56 17.07-4.58 25.98z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-13"
					d="M1184.47 415.3c-8.21 8.11-14.61 21.39-15 29.51s1.67 16.37 7.83 19.51c12.61 6.41 35.88-.78 50.43-6.51a22.2 22.2 0 0121.21 2.62c3.56 2.58 8 4.63 12.58 4.07 11.07-1.35 15.78-33.56-10.58-51.58s-53.89-10.05-66.47 2.38z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-17"
					d="M1221.35 497.54a10.81 10.81 0 01-4.25-1 1 1 0 01-.62-1.09l2.17-16.67a1 1 0 112.06.27l-2.07 15.87c2.15.8 3.81.73 5.18-.19a1 1 0 111.17 1.72 6.39 6.39 0 01-3.64 1.09zM1218.83 508.32a21.17 21.17 0 01-2.53-.47 23.7 23.7 0 01-7.83-3.47 1 1 0 011.23-1.67c.47.34 11.44 8.2 22.83.06a1 1 0 011.45.25 1 1 0 01-.24 1.44 20.58 20.58 0 01-14.91 3.86z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-14"
					d="M1142.08 584.12l-4.42 2.2c-.81.41-1.61.8-2.39 1.18-5.63 2.76-10.28 4.94-13.08 6.06-13.54 5.41-15.8 33.39-15.8 33.39h44.94c3.21-22.13-3.76-35.82-9.25-42.83zM1314.81 593.57c-4-1.62-12.13-5.46-21.47-10-5.55 6.83-13 20.65-9.7 43.43h47s-2.29-28-15.83-33.43z"
				/>
				<g opacity={0.3}>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-13"
						d="M706.62 623.23s-27.35 5.5-28.5-17.53c-.5-10-1.85-8.4-10.56-16.81s-8.72-11.82-6.85-19.6 9.72-10.46 5.84-17.65c-5.11-9.47-3.54-25.66 7.56-32 10.73-6.1 11.94-6 11.94-12.27 0-8.17 8.48-21.34 26-20 15.05 1.18 12.72-6.15 23.39-9.41 19.87-6.08 29.5 11.13 36.34 11.76s18.7.74 24.71 9.84c7.46 11.28.87 20.49 10.21 21.43s18.67 9 18.36 17.42-5.29 15.57-1.87 19.3 4.75 25.85-9.06 30.09c-18.88 5.78 0 33.73-34.72 33.21-31.23-.4-72.79 2.22-72.79 2.22z"
					/>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-14"
						d="M762.07 583.83a6.67 6.67 0 01-3.49-4.62c-.83-4.16-2.24-11.44-3-15.9-.41-2.4-.77-4.79-1-6.82l-24 1a46.75 46.75 0 01-1.09 10.8c-.44 2.06-2.18 8.19-3.47 12.2a6.67 6.67 0 01-3.22 3.83l-7.01 3.68a108.19 108.19 0 004.63 13.9c4 9.71 10.55 21.11 20.11 25.08H752c6.95-3.72 11.08-14.5 13.47-24.33a132.62 132.62 0 002.72-15.65z"
					/>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-15"
						d="M816 627H670.83s1.45-18.11 10.22-21.61c1.81-.73 4.83-2.14 8.47-3.93l1.55-.76c7.58-3.75 17.34-8.81 24.71-12.67a66.14 66.14 0 009.34-5.71l1.41 1.68c7.29 9.06 24.77 12.14 33.53-1.69a69.92 69.92 0 008.12 4.7c3.2 1.63 7 3.55 10.93 5.53 10.59 5.33 22.27 11.1 26.65 12.86C814.53 608.85 816 627 816 627z"
					/>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-16"
						d="M755 559.34c-3.71 2.59-10.55 6.58-24.77 5.08a49.19 49.19 0 00.42-7l24-1a19.58 19.58 0 00.35 2.92z"
					/>
					<path className="-_20_Driver_licence_front_image_svg__cls-14" d="M721.23 527.82s-5.31-7.24-9.44-3 3.83 19.19 11.07 15.94z" />
					<path
						className="-_20_Driver_licence_front_image_svg__cls-19"
						d="M719.76 537.64a.48.48 0 01-.48-.41 18.05 18.05 0 00-6.28-10.77.48.48 0 01-.09-.68.49.49 0 01.68-.09 18.67 18.67 0 016.63 11.4.49.49 0 01-.41.55z"
					/>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-19"
						d="M718.29 532.87a.49.49 0 01-.41-.21c-.7-1-2.81-1.15-3.56-1.1a.5.5 0 01-.52-.46.48.48 0 01.46-.51c.13 0 3.27-.19 4.43 1.52a.49.49 0 01-.13.68.56.56 0 01-.27.08z"
					/>
					<path className="-_20_Driver_licence_front_image_svg__cls-14" d="M767.35 527.82s5.31-7.24 9.44-3-3.79 19.23-11.06 15.98z" />
					<path
						className="-_20_Driver_licence_front_image_svg__cls-19"
						d="M768.31 537.64h-.07a.48.48 0 01-.41-.55 18.63 18.63 0 016.63-11.4.49.49 0 01.59.77 18 18 0 00-6.26 10.77.48.48 0 01-.48.41z"
					/>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-19"
						d="M769.78 532.87a.53.53 0 01-.27-.08.49.49 0 01-.13-.68c1.16-1.71 4.29-1.53 4.43-1.52a.48.48 0 01.45.51.49.49 0 01-.51.46c-.76 0-2.87.06-3.57 1.1a.48.48 0 01-.4.21z"
					/>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-14"
						d="M747.24 497.67c-20.76-.57-28.9 9.77-28.9 31.28 0 10.07 2.78 31.19 25.15 31.83 24 .69 26.33-25.59 26.33-34 0-14.5-3.28-24.19-16.1-28.12a58.24 58.24 0 00-6.48-.99z"
					/>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-13"
						d="M718.77 535.52s3.39-9.26 1.92-16.09L715.9 518s.27 11.75 2.87 17.52zM769.22 534.86s-3.29-8.6-1.83-15.43l4.79-1.39s-.36 11.05-2.96 16.82z"
					/>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-13"
						d="M721.39 489.93c-5.32 5.25-9.46 13.85-9.74 19.1s1.08 10.61 5.07 12.63c8.16 4.16 23.22-.5 32.64-4.21a14.4 14.4 0 0113.74 1.7c2.3 1.67 5.15 3 8.14 2.63 7.17-.87 10.22-21.73-6.85-33.39s-34.86-6.5-43 1.54z"
					/>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-19"
						d="M745.26 543.17a7.05 7.05 0 01-2.75-.65.67.67 0 01-.4-.7l1.4-10.8a.68.68 0 01.76-.58.69.69 0 01.58.75l-1.34 10.28a3.47 3.47 0 003.35-.13.69.69 0 01.94.19.68.68 0 01-.19.93 4 4 0 01-2.35.71zM743.63 550.15a14.17 14.17 0 01-1.64-.3 15.6 15.6 0 01-5.07-2.25.68.68 0 01-.14-.94.67.67 0 01.94-.14c.3.22 7.4 5.31 14.78 0a.67.67 0 01.78 1.1 13.35 13.35 0 01-9.65 2.53z"
					/>
					<path
						className="-_20_Driver_licence_front_image_svg__cls-14"
						d="M693.94 599.22l-2.86 1.43-1.55.76c-3.64 1.79-6.66 3.2-8.47 3.93-8.77 3.5-10.22 21.61-10.22 21.61h29.09c2.07-14.32-2.43-23.18-5.99-27.73zM805.77 605.35c-2.62-1.05-7.86-3.54-13.9-6.51-3.6 4.42-8.43 13.37-6.28 28.12H816s-1.47-18.11-10.23-21.61z"
					/>
				</g>
				<rect
					className="-_20_Driver_licence_front_image_svg__cls-11"
					x={638.56}
					y={426.13}
					width={184.51}
					height={26.3}
					rx={13.15}
					ry={13.15}
					transform="rotate(-180 730.81 439.28)"
				/>
				<rect
					className="-_20_Driver_licence_front_image_svg__cls-11"
					x={638.56}
					y={519.13}
					width={128.79}
					height={26.3}
					rx={13.15}
					ry={13.15}
					transform="rotate(-180 702.955 532.28)"
				/>
				<rect
					className="-_20_Driver_licence_front_image_svg__cls-11"
					x={638.56}
					y={471.43}
					width={276.49}
					height={26.3}
					rx={13.15}
					ry={13.15}
					transform="rotate(-180 776.805 484.58)"
				/>
				<rect x={638.56} y={354.93} width={342.49} height={36} rx={18} ry={18} transform="rotate(-180 809.805 372.935)" fill="#1188f7" />
				<rect
					className="-_20_Driver_licence_front_image_svg__cls-14"
					x={814.56}
					y={263.43}
					width={163.51}
					height={36}
					rx={18}
					ry={18}
					transform="rotate(-180 896.31 281.435)"
				/>
				<rect
					className="-_20_Driver_licence_front_image_svg__cls-14"
					x={1015.06}
					y={263.43}
					width={97.51}
					height={36}
					rx={18}
					ry={18}
					transform="rotate(-180 1063.81 281.435)"
				/>
				<path
					d="M703.7 617.57L696 689a2.16 2.16 0 002.14 2.14c21.4-1.39 38.1-21.91 30.27-43.07-6.75-18.24-31.7-25.62-45.94-10.9-7 7.22-7.25 17.88 1.9 23.24 8.42 4.94 19.55 3.73 28.38.61a49.35 49.35 0 0024.38-18.65c1.84-2.71 4.34-6.91 2.17-10.16s-5.95-1.89-8 .51c-5.49 6.44-8.08 22.78 3 25.39 5.62 1.33 11.34-3 15.14-6.65a36 36 0 009.32-15.25c.78-2.45-2.59-3.52-3.92-1.65-3.53 5-7.24 11.86-2.81 17.54s11.61 2.29 16.26-1c13.55-9.65 21.51-24.74 28.49-39.42l-3.97-1.68a195.35 195.35 0 00-14.28 63c-.12 2.09 3.05 3 4 1.08a25.06 25.06 0 0125.29-13.84v-4.28a156.82 156.82 0 00-107 36.66c-2.11 1.78.93 4.8 3 3a152.46 152.46 0 01104-35.41c2.8.12 2.69-4 0-4.28a29.13 29.13 0 00-29 16l4 1.08a191.68 191.68 0 0114.13-61.8c.92-2.23-2.87-3.84-3.91-1.65-4.77 10-9.84 20.21-17.12 28.71a49.76 49.76 0 01-12.41 10.88c-3.51 2-8.79 2.54-9.2-2.79-.28-3.57 2.24-6.82 4.19-9.58l-3.91-1.65c-2.18 6.84-6.66 13.47-13 17.09-3 1.74-6.4 2.65-9.06-.16a7.82 7.82 0 01-1.69-5.42 20.41 20.41 0 011.55-6.56c.37-1 3.06-6.17 3-3.19-.06 2.65-4.62 7-6.23 8.69a44.9 44.9 0 01-8.1 6.7 44.44 44.44 0 01-20.38 7.06c-6 .49-14-.38-17.76-5.72-5.59-7.9 4.45-16.43 11.29-18.67 14-4.6 28.21 4.15 31.3 18.35 3.79 17.39-10.34 32.51-27.34 33.61l2.14 2.15 7.69-71.49c.3-2.73-4-2.71-4.28 0z"
					fill="#4288db"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-22"
					d="M1875.78 386.37c-54.71-45.78-166.57-115.84-199.32-137.44s-63 30.35-1.78 95.46c0 0-94.82 9-123.39 20.61s-63 46 6.71 67.29 130.07 15.32 130.07 15.32-121.4 48.76-150.29 70.9-25 63.66 10.61 65.56S1725 530 1725 530s-225.27 135.76-190.07 183.38 109.27 18.47 155.78-17.38 76.26-6.92 82.49 22.95"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-14"
					d="M1999.75 1270.93s253.1-153 256.22-284.4c0 0-80.45-97.57-126.08-180.69s-117.56-265.9-188.34-343S1805 298 1750.3 252.21 1581.05 140 1551 114.77c-32.85-27.53-76.61-9-43.73 67.26 13.43 31.14 127.76 159.79 150.51 185 0 0 91.17 136.28 110 186.93 7.06 19 25.66 28.8 25.16 62.23-.83 55.7-28.93 137-62.8 142.8"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-14"
					d="M1773.13 754.66c-30.4 2.56-63.6 4.37-63.6 4.37s-249.75 4.11-303 48-41.69 72.23 8.38 82.07 172.74 26.59 227.69 61.1 120.31 94.41 193.75 127.14 137.85 155.4 137.85 155.4c4.23-3.15 30.56-33 31.07-69.47S1801 752.31 1773.13 754.66z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-23"
					d="M1792.9 761.83c-27.28-7.42-69.13-.74-108.37-2.07M1614.19 317.74s48.76 28.9 140.68 217.65"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-22"
					d="M127.11 386.37c54.71-45.78 166.57-115.84 199.32-137.44s63 30.35 1.78 95.46c0 0 94.82 9 123.39 20.61s63 46-6.71 67.29-130.07 15.32-130.07 15.32 121.4 48.76 150.29 70.9 25 63.66-10.61 65.56S277.93 530 277.93 530 503.2 665.74 468 713.36 358.68 731.85 312.18 696s-76.26-6.92-82.49 22.95"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-14"
					d="M3.14 1270.93S-250 1118-253.08 986.53c0 0 80.45-97.57 126.08-180.69s117.56-265.9 188.34-343S197.87 298 252.59 252.21 421.84 140 451.91 114.77c32.85-27.53 76.6-9 43.73 67.26-13.43 31.14-127.76 159.79-150.51 185 0 0-91.17 136.28-110 186.93-7.06 19-25.66 28.8-25.16 62.23.83 55.7 28.93 137 62.8 142.8"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-14"
					d="M229.76 754.66c30.4 2.56 63.6 4.37 63.6 4.37s249.75 4.11 303 48S638 879.26 588 889.1s-172.74 26.59-227.69 61.1-120.31 94.41-193.78 127.14-137.85 155.4-137.85 155.4c-4.23-3.15-30.56-33-31.07-69.47s204.25-410.96 232.15-408.61z"
				/>
				<path
					className="-_20_Driver_licence_front_image_svg__cls-23"
					d="M210 761.83c27.28-7.42 69.13-.74 108.37-2.07M388.7 317.74s-48.76 28.9-140.7 217.65"
				/>
				<path
					d="M1539.46 171.81H461.1a52.51 52.51 0 00-52.51 52.51v525.55a52.51 52.51 0 0052.51 52.51h1078.36a52.51 52.51 0 0052.54-52.51V224.32a52.51 52.51 0 00-52.54-52.51zm-29.32 609.52H490.42V192.87h1019.72z"
					fill="#32384f"
				/>
				<path className="-_20_Driver_licence_front_image_svg__cls-8" d="M1510.14 781.33H712.26l797.88-588.46v588.46z" />
				<path className="-_20_Driver_licence_front_image_svg__cls-1" d="M120.11-63.42h1774.92v1500.51H120.11z" />
			</g>
		</svg>
	);
}

export default Svg20Driverlicencefrontimage;
