import * as React from 'react';

function Svg11NationalIDcard2(props) {
	return (
		<svg
			id="-_11_National_ID_card_2_svg__\u0421\u043B\u043E\u0439_1"
			data-name="\u0421\u043B\u043E\u0439 1"
			viewBox="0 0 946.43 584.06"
			{...props}
		>
			<defs>
				<clipPath id="-_11_National_ID_card_2_svg__clip-path">
					<rect className="-_11_National_ID_card_2_svg__cls-1" x={92.06} y={249.86} width={166.42} height={218.88} rx={9.75} ry={9.75} />
				</clipPath>
				<clipPath id="-_11_National_ID_card_2_svg__clip-path-2">
					<rect className="-_11_National_ID_card_2_svg__cls-1" x={668.83} y={208.41} width={216.95} height={285.34} rx={9.75} ry={9.75} />
				</clipPath>
				<style>
					{
						'.-_11_National_ID_card_2_svg__cls-1{fill:none}.-_11_National_ID_card_2_svg__cls-2{fill:#fff}.-_11_National_ID_card_2_svg__cls-6{fill:#1188f7}.-_11_National_ID_card_2_svg__cls-7{fill:#0074d3}.-_11_National_ID_card_2_svg__cls-8{fill:#ffca27}.-_11_National_ID_card_2_svg__cls-9{fill:#09030b}.-_11_National_ID_card_2_svg__cls-10,.-_11_National_ID_card_2_svg__cls-15{fill:#32384f;stroke:#32384f;stroke-miterlimit:10}.-_11_National_ID_card_2_svg__cls-10{stroke-width:.94px}.-_11_National_ID_card_2_svg__cls-11{fill:#3f2037}.-_11_National_ID_card_2_svg__cls-15{stroke-width:1.22px}.-_11_National_ID_card_2_svg__cls-16{fill:#2594cc}.-_11_National_ID_card_2_svg__cls-17{fill:#49bbe8}'
					}
				</style>
			</defs>
			<rect className="-_11_National_ID_card_2_svg__cls-2" width={946.43} height={584.06} rx={35.71} ry={35.71} />
			<rect width={946.43} height={584.06} rx={35.71} ry={35.71} fill="#6bc5f2" />
			<g opacity={0.31}>
				<rect className="-_11_National_ID_card_2_svg__cls-2" x={92.06} y={249.86} width={166.42} height={218.88} rx={9.75} ry={9.75} />
				<g clipPath="url(#-_11_National_ID_card_2_svg__clip-path)">
					<path
						className="-_11_National_ID_card_2_svg__cls-6"
						d="M277.65 468.48H72.28s.18-2.32.78-5.69c.2-1.14.45-2.4.76-3.74a60.78 60.78 0 012.36-7.95 38.79 38.79 0 011.63-3.74 21.49 21.49 0 016.19-7.95 12 12 0 012.71-1.51c1.29-.52 3-1.28 5.06-2.24 4.28-2 10-4.81 16.19-7.94 2.34-1.18 4.74-2.4 7.15-3.64l.21-.11c5.22-2.67 10.51-5.41 15.37-7.94l4.62-2.42 2.54-1.33.38-.2c6.65-3.49 11.54-6.08 12.69-6.69l.09-.05.15-.08 1.57.36q3 .64 5.92 1.13c1.95.31 3.91.57 5.88.76a98 98 0 0032.47-2.25s4.88 2.52 12.05 6.17l1.69.86 7.37 3.75 7.3 3.68c1.92 1 3.86 2 5.81 2.92L234 424c2.56 1.28 5.09 2.54 7.55 3.75 6.35 3.13 12.2 5.95 16.55 7.94 2.08 1 3.82 1.72 5.11 2.24a11.81 11.81 0 012.71 1.51 21.49 21.49 0 016.22 7.95 38.79 38.79 0 011.63 3.74 60.78 60.78 0 012.36 7.95c.31 1.34.56 2.6.76 3.74.58 3.34.76 5.66.76 5.66z"
					/>
					<path
						className="-_11_National_ID_card_2_svg__cls-7"
						d="M218.18 416h-87.46l7.16-3.75h72.93zM241.53 427.72H108c2.34-1.18 4.74-2.4 7.15-3.64l.21-.11H234c2.54 1.28 5.07 2.54 7.53 3.75zM265.9 439.41H84a12 12 0 012.71-1.51c1.29-.52 3-1.28 5.06-2.24h166.31c2.08 1 3.82 1.72 5.11 2.24a11.81 11.81 0 012.71 1.51zM273.75 451.1H76.18a38.79 38.79 0 011.63-3.74h194.31a38.79 38.79 0 011.63 3.74zM276.87 462.79H73.06c.2-1.14.45-2.4.76-3.74h202.29c.31 1.34.56 2.6.76 3.74z"
					/>
					<path
						className="-_11_National_ID_card_2_svg__cls-7"
						d="M209.12 411.42c-2.36 8.29-12.53 21.32-34.15 21.32-22.37 0-34.76-12.08-39.63-19.13l2.54-1.33.38-.2c6.65-3.49 11.54-6.08 12.69-6.69l.09-.05.15-.08 1.57.36q3 .64 5.92 1.13c1.95.31 3.91.57 5.88.76a98 98 0 0032.44-2.25s5 2.51 12.12 6.16z"
					/>
					<path
						className="-_11_National_ID_card_2_svg__cls-8"
						d="M198.44 407.8s-4 15.56-22.55 15.56c-21.61 0-26.7-14.15-26.7-14.15a135.23 135.23 0 004-16.83c1.6-9.27 3.67-19.56 3.58-24l36.12 1.16c.35 4 .92 8.6 1.56 13.27 1.76 12.4 3.99 24.99 3.99 24.99z"
					/>
					<path
						className="-_11_National_ID_card_2_svg__cls-9"
						d="M193.85 378.12c-5.88 5-16.81 10-39.33 7.1 1.6-9.27 3-15.36 2.92-19.82l35.49 4.12c.35 3.95.27 3.92.92 8.6z"
					/>
					<path className="-_11_National_ID_card_2_svg__cls-8" d="M141.75 332s-8-10.82-14.14-4.42 5.74 28.72 16.57 23.86z" />
					<path
						className="-_11_National_ID_card_2_svg__cls-10"
						d="M140.32 346.53a.71.71 0 01-.7-.61c-1.35-9.69-9.05-15.64-9.13-15.7a.7.7 0 01-.13-1 .71.71 0 011-.14c.33.26 8.25 6.37 9.67 16.64a.69.69 0 01-.6.79z"
					/>
					<path
						className="-_11_National_ID_card_2_svg__cls-10"
						d="M138.17 339.57a.7.7 0 01-.58-.31c-1-1.52-4.11-1.68-5.2-1.61a.71.71 0 01-.09-1.41c.19 0 4.77-.27 6.46 2.23a.71.71 0 01-.19 1 .72.72 0 01-.4.1z"
					/>
					<path className="-_11_National_ID_card_2_svg__cls-8" d="M213.4 332s8-10.82 14.14-4.42-5.74 28.74-16.54 23.88z" />
					<path
						className="-_11_National_ID_card_2_svg__cls-10"
						d="M212.53 346.53h-.1a.7.7 0 01-.6-.79c1.43-10.27 9.34-16.38 9.67-16.64a.71.71 0 011 .14.7.7 0 01-.13 1c-.08.06-7.78 6-9.13 15.7a.71.71 0 01-.71.59z"
					/>
					<path
						className="-_11_National_ID_card_2_svg__cls-10"
						d="M214.68 339.57a.72.72 0 01-.4-.12.71.71 0 01-.19-1c1.69-2.5 6.27-2.24 6.46-2.23a.71.71 0 01-.09 1.41c-1.1-.07-4.17.09-5.2 1.61a.7.7 0 01-.58.33z"
					/>
					<path
						className="-_11_National_ID_card_2_svg__cls-8"
						d="M183 286.79c-29.21 0-47.37 9.86-44.71 40.75 2.15 24.94 6.11 50 39.52 50 25.65 0 39.81-27.51 37.91-47.89-1.62-17.46-6.51-36.28-25.2-41.72a45.16 45.16 0 00-7.52-1.14z"
					/>
					<path
						className="-_11_National_ID_card_2_svg__cls-11"
						d="M138.54 330.31s-7.85-19.11-4.44-40.52c2.79-17.53 16-25.62 42.26-25.57 21.2 0 31.69 3.78 33.4 11.29 0 0 10 4.26 12.81 15.09 4.92 19.11-7.55 43.92-7.55 43.92s5.87-8.18-4.32-12.79c-8-3.63-10.25-6.07-17.07-6.21s-14.36-1.61-22.75-2.74c-10.62-1.43-16.94-2.72-23.43-7.5 0 0-1.73 16.5-8.91 25.03z"
					/>
					<path
						className="-_11_National_ID_card_2_svg__cls-10"
						d="M178.15 348.4a10.19 10.19 0 01-4-1 1 1 0 01-.6-1.05l2-15.75a1 1 0 011.13-.87 1 1 0 01.87 1.13l-1.94 15c2 .74 3.56.67 4.84-.19a1 1 0 111.13 1.67 6 6 0 01-3.43 1.06zM175.67 360.31a23.38 23.38 0 01-9.67-4.21 1 1 0 011.28-1.56c.43.35 10.37 8.26 21.49 1.17a1 1 0 011.39.3 1 1 0 01-.31 1.4 19.33 19.33 0 01-14.18 2.9z"
					/>
				</g>
			</g>
			<path
				d="M904.78 584.06a41.65 41.65 0 0041.65-41.65V218.65c-123.08-66.51-322.41-106.5-420 26.26C413.33 398.86 226.38 443 129.75 455.33c-32.4 4.14-84.5 31.54-129.75 72.56v14.52a41.65 41.65 0 0041.65 41.65z"
				opacity={0.09}
				fill="#fff"
			/>
			<path
				d="M759.77 100.19s7.93 16.82 1.4 42.92-28.37 60-33.34 86.77 10.79 25.68-11.06 48.29-38.51 44.11-55.55 70.92-47.48 78.68-55.56 104.13-12.41 50.64-16.1 63 5.68 5.87-25.52-9.1-47-13.89-48.53-25.86 6.68-23.85-12.51-28.58-32.11-6.12-55-9.85-20.71-16-32.16-6.87S402.11 467 393.69 454s-17.32-32.77-32.95-40.77-17.38-.33-22.16-15.09-5.4-35.1-22.72-34.68-39.12-9.42-47.39-8.86-19.26 16.4-13.82-3.6.77-233.2 2.82-236.36 276.58 6.13 303 6.59 42.51-7.41 60.63-7.13 21.75 9.09 47.64 12.61 39.47 3.36 53-8.12 38.03-18.4 38.03-18.4z"
				opacity={0.15}
				fill="#fff"
			/>
			<rect className="-_11_National_ID_card_2_svg__cls-2" x={668.83} y={208.41} width={216.95} height={285.34} rx={9.75} ry={9.75} />
			<g clipPath="url(#-_11_National_ID_card_2_svg__clip-path-2)">
				<path
					className="-_11_National_ID_card_2_svg__cls-6"
					d="M910.79 493.4H643.05s.24-3 1-7.41c.26-1.49.59-3.14 1-4.88a78.71 78.71 0 013.07-10.36 52.93 52.93 0 012.13-4.88 28.05 28.05 0 018.11-10.36 15.69 15.69 0 013.53-2c1.68-.68 3.92-1.67 6.59-2.91 5.59-2.6 13-6.28 21.12-10.36 3-1.54 6.17-3.13 9.32-4.75l.27-.13c6.8-3.49 13.7-7.06 20-10.37l6-3.14 3.3-1.74.5-.26c8.67-4.55 15.05-7.93 16.54-8.73l.12-.07.2-.1 2 .46c2.56.57 5.12 1.07 7.7 1.47s5.11.75 7.67 1a127.48 127.48 0 0042.45-2.88h.07s6.36 3.27 15.7 8l2.2 1.12 9.62 4.88 9.51 4.81 7.57 3.8 3.52 1.76c3.32 1.66 6.63 3.3 9.83 4.88 8.29 4.08 15.91 7.76 21.58 10.36 2.71 1.24 5 2.23 6.66 2.91a15.69 15.69 0 013.53 2 28.18 28.18 0 018.12 10.36c.78 1.58 1.49 3.22 2.12 4.88a78.71 78.71 0 013.07 10.36c.4 1.74.73 3.39 1 4.88.77 4.39 1.02 7.4 1.02 7.4z"
				/>
				<path
					className="-_11_National_ID_card_2_svg__cls-7"
					d="M833.26 425h-114l9.33-4.88h95.08zM863.69 440.27H689.61c3-1.54 6.17-3.13 9.32-4.75l.27-.13h154.66c3.32 1.66 6.63 3.3 9.83 4.88zM895.46 455.51H658.37a15.69 15.69 0 013.53-2c1.68-.68 3.92-1.67 6.59-2.91h216.78c2.71 1.24 5 2.23 6.66 2.91a15.69 15.69 0 013.53 2zM905.7 470.75H648.13a52.93 52.93 0 012.13-4.88h253.32c.78 1.58 1.49 3.22 2.12 4.88zM909.76 486H644.07c.26-1.49.59-3.14 1-4.88h263.7c.4 1.73.73 3.38.99 4.88z"
				/>
				<path
					className="-_11_National_ID_card_2_svg__cls-7"
					d="M821.44 419c-3.07 10.81-16.34 27.8-44.52 27.8-29.16 0-45.32-15.75-51.66-24.94l3.3-1.74.5-.26c8.67-4.55 15.05-7.93 16.54-8.73l.12-.07.2-.1 2 .46c2.56.57 5.12 1.07 7.7 1.47s5.11.75 7.67 1a127.48 127.48 0 0042.38-2.89h.07s6.36 3.25 15.7 8z"
				/>
				<path
					className="-_11_National_ID_card_2_svg__cls-8"
					d="M807.52 414.3s-5.25 20.29-29.41 20.29c-28.17 0-34.8-18.45-34.8-18.45a178 178 0 005.27-21.94c2.09-12.09 4.79-25.5 4.66-31.32l47.09 1.52c.46 5.14 1.2 11.2 2 17.3 2.28 16.19 5.19 32.6 5.19 32.6z"
				/>
				<path
					className="-_11_National_ID_card_2_svg__cls-9"
					d="M801.53 375.6c-7.67 6.55-21.91 13.06-51.27 9.26 2.09-12.08 3.93-20 3.81-25.84l46.26 5.38c.46 5.14.36 5.11 1.2 11.2z"
				/>
				<path className="-_11_National_ID_card_2_svg__cls-8" d="M733.61 315.51s-10.37-14.11-18.43-5.76 7.49 37.44 21.6 31.1z" />
				<path
					className="-_11_National_ID_card_2_svg__cls-15"
					d="M731.75 334.43a.92.92 0 01-.91-.8c-1.76-12.63-11.8-20.39-11.9-20.47a.91.91 0 01-.18-1.29.92.92 0 011.29-.18c.44.34 10.76 8.3 12.62 21.69a.93.93 0 01-.79 1z"
				/>
				<path
					className="-_11_National_ID_card_2_svg__cls-15"
					d="M729 325.35a.93.93 0 01-.76-.4c-1.34-2-5.35-2.19-6.78-2.1a.91.91 0 01-1-.86.93.93 0 01.86-1c.26 0 6.23-.35 8.43 2.9a.93.93 0 01-.25 1.28.91.91 0 01-.5.18z"
				/>
				<path className="-_11_National_ID_card_2_svg__cls-8" d="M827 315.51s10.36-14.11 18.43-5.76-7.49 37.44-21.6 31.1z" />
				<path
					className="-_11_National_ID_card_2_svg__cls-15"
					d="M825.89 334.43h-.13a.92.92 0 01-.79-1c1.86-13.39 12.18-21.35 12.61-21.69a.94.94 0 011.3.18.91.91 0 01-.18 1.29c-.1.08-10.15 7.86-11.9 20.47a.92.92 0 01-.91.75z"
				/>
				<path
					className="-_11_National_ID_card_2_svg__cls-15"
					d="M828.69 325.35a.91.91 0 01-.52-.16.93.93 0 01-.25-1.28c2.2-3.25 8.17-2.92 8.43-2.9a.92.92 0 01.86 1 .93.93 0 01-1 .87c-1.43-.09-5.44.12-6.78 2.1a.93.93 0 01-.74.37z"
				/>
				<path
					className="-_11_National_ID_card_2_svg__cls-8"
					d="M787.35 256.55c-38.08 0-61.75 12.85-58.28 53.12 2.8 32.51 8 65.23 51.51 65.23 33.42 0 51.91-35.9 49.42-62.43-2.12-22.76-8.49-47.3-32.86-54.39a59.3 59.3 0 00-9.79-1.53z"
				/>
				<path
					className="-_11_National_ID_card_2_svg__cls-11"
					d="M729.44 313.28s-10.24-24.91-5.8-52.82c3.64-22.86 20.84-33.41 55.1-33.33 27.63.06 41.31 4.92 43.53 14.72 0 0 13.08 5.54 16.71 19.66 6.4 24.92-9.85 57.26-9.85 57.26s7.66-10.67-5.63-16.67c-10.48-4.73-13.35-7.92-22.26-8.1s-18.71-2.1-29.65-3.57c-13.85-1.87-22.09-3.54-30.55-9.78-.04 0-2.26 21.51-11.6 32.63z"
				/>
				<path
					className="-_11_National_ID_card_2_svg__cls-15"
					d="M781.06 336.86a13.32 13.32 0 01-5.25-1.24 1.34 1.34 0 01-.78-1.38l2.68-20.53a1.3 1.3 0 011.47-1.13 1.32 1.32 0 011.14 1.47l-2.54 19.52c2.62 1 4.64.87 6.31-.25a1.31 1.31 0 111.47 2.18 7.82 7.82 0 01-4.5 1.36zM777.83 352.39a30.36 30.36 0 01-12.59-5.49 1.32 1.32 0 111.67-2c.56.46 13.52 10.78 28 1.53a1.32 1.32 0 111.42 2.22 25.23 25.23 0 01-18.5 3.74z"
				/>
			</g>
			<path
				className="-_11_National_ID_card_2_svg__cls-16"
				d="M35.71 0h875a35.71 35.71 0 0135.71 35.71v78.81H0V35.71A35.71 35.71 0 0135.71 0z"
			/>
			<rect className="-_11_National_ID_card_2_svg__cls-16" x={714.8} y={161.99} width={171.78} height={27.55} rx={13.77} ry={13.77} />
			<rect className="-_11_National_ID_card_2_svg__cls-16" x={714.8} y={522.31} width={171.78} height={27.55} rx={13.77} ry={13.77} />
			<rect className="-_11_National_ID_card_2_svg__cls-16" x={600.43} y={522.31} width={85.89} height={27.55} rx={13.77} ry={13.77} />
			<rect
				className="-_11_National_ID_card_2_svg__cls-16"
				x={58.19}
				y={234.53}
				width={169.94}
				height={30.67}
				rx={15.34}
				ry={15.34}
				transform="rotate(-180 143.165 249.865)"
			/>
			<rect
				className="-_11_National_ID_card_2_svg__cls-16"
				x={58.19}
				y={342.99}
				width={191.12}
				height={30.67}
				rx={15.34}
				ry={15.34}
				transform="rotate(-180 153.755 358.325)"
			/>
			<rect
				className="-_11_National_ID_card_2_svg__cls-16"
				x={273.54}
				y={342.99}
				width={113.98}
				height={30.67}
				rx={15.34}
				ry={15.34}
				transform="rotate(-180 330.535 358.325)"
			/>
			<rect
				className="-_11_National_ID_card_2_svg__cls-16"
				x={58.19}
				y={287.36}
				width={286.96}
				height={30.67}
				rx={15.34}
				ry={15.34}
				transform="rotate(180 201.67 302.69)"
			/>
			<rect
				className="-_11_National_ID_card_2_svg__cls-16"
				x={58.19}
				y={151.49}
				width={399.43}
				height={41.98}
				rx={20.99}
				ry={20.99}
				transform="rotate(-180 257.905 172.485)"
			/>
			<rect
				className="-_11_National_ID_card_2_svg__cls-17"
				x={331.16}
				y={31.36}
				width={146.31}
				height={41.98}
				rx={20.99}
				ry={20.99}
				transform="rotate(-180 404.31 52.355)"
			/>
			<rect
				className="-_11_National_ID_card_2_svg__cls-17"
				x={500.2}
				y={31.36}
				width={113.72}
				height={41.98}
				rx={20.99}
				ry={20.99}
				transform="rotate(-180 557.06 52.355)"
			/>
		</svg>
	);
}

export default Svg11NationalIDcard2;
