import * as React from 'react';

function SelectIdTypeSvg(props) {
	return (
		<svg id="-_19_Select_ID_type_svg__\u0421\u043B\u043E\u0439_1" data-name="\u0421\u043B\u043E\u0439 1" viewBox="0 0 1989.16 1503.57" {...props}>
			<defs>
				<clipPath id="-_19_Select_ID_type_svg__clip-path">
					<path fill="none" d="M0 0h1989.16v1452.15H0z" />
				</clipPath>
				<style>
					{
						'.-_19_Select_ID_type_svg__cls-7{fill:none}.-_19_Select_ID_type_svg__cls-6{fill:#ffca27}.-_19_Select_ID_type_svg__cls-17,.-_19_Select_ID_type_svg__cls-7{stroke:#32384f}.-_19_Select_ID_type_svg__cls-7{stroke-linecap:round;stroke-linejoin:round;stroke-width:18.71px}.-_19_Select_ID_type_svg__cls-9{fill:#624de6}.-_19_Select_ID_type_svg__cls-10,.-_19_Select_ID_type_svg__cls-11,.-_19_Select_ID_type_svg__cls-19{fill:#8fafe2}.-_19_Select_ID_type_svg__cls-10{opacity:.1}.-_19_Select_ID_type_svg__cls-11,.-_19_Select_ID_type_svg__cls-18{opacity:.25}.-_19_Select_ID_type_svg__cls-13{fill:#ff703e}.-_19_Select_ID_type_svg__cls-14{fill:#4a3edd}.-_19_Select_ID_type_svg__cls-15{fill:#fff7f1}.-_19_Select_ID_type_svg__cls-17{fill:#32384f;stroke-miterlimit:10;stroke-width:1.18px}'
					}
				</style>
			</defs>
			<g clipPath="url(#-_19_Select_ID_type_svg__clip-path)">
				<path
					d="M1539.69 622.37s-7.38-377.37-316.83-213.49c-239.53 126.84-123.69 383.1-285 446.64-253.08 99.67-118.85 599.84 217 389.92s422.69-43.12 523.84-231.22c68.42-127.22-101.15-153.85-139.01-391.85z"
					fill="#1188f7"
					opacity={0.07}
				/>
				<path
					d="M1204.88 493s254.2-305.48-42.42-436.59C932.87-45.08 860.62 164.47 663.08 121.54 417.06 68.08 161.67 356.5 485 530.9c295.09 159.15 173.26 508.86 542.18 375.1 120.82-43.78 20.24-215.49 177.7-413z"
					opacity={0.11}
					fill="#ffca27"
				/>
				<path
					d="M497.19 945.72s-131.7 216.63 90.81 314.49c224.52 98.72 244.05-159 444.8-121.74 343.86 63.85 470.9-345.34 123.28-442.73s-307.44-275.59-496-201.3c-210.82 83.04-31.31 262.07-162.89 451.28z"
					fill="#4dbc76"
					opacity={0.09}
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-6"
					d="M-309.28 1184.61s257.14-217.8 382.93-302.93S265.31 792.21 390.9 815s380.41 44.3 346.46 142.79-269.14 379.54-309.14 425-61.92-23.64-55.09-65.54c0 0-45.47 69.19-64.32 84.56s-38.69-18.53-31-54.72c0 0-63.85 27-63.16-10.87s24.35-66.06 24.35-66.06-18.89-6.19-38.62 9.31-71.84 64.1-116.5 84.19-112.38 102.91-155.47 133.55-365.34-45.67-237.69-312.6z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-7"
					d="M224.28 1263.8l161.7-178.51M267.52 1329.29l161.32-253.94M370.53 1312.85l136.61-267.06"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-6"
					d="M2095.81 373.18s-278.5-189.73-412.5-261.18-200-68.95-322.49-33.2-373.69 83.82-329.63 178.2 307.35 349.33 351.89 390.36 59.1-30 47.93-70.94c0 0 52.46 64.06 72.81 77.37s36.54-22.48 25.12-57.66c0 0 66.33 20.15 61.69-17.42s-31.15-63.15-31.15-63.15 18.14-8.13 39.38 5.22S1677 577 1723.52 592.36s274 215.75 320 241.72 207.15-208.78 52.29-460.9z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-7"
					d="M1573.46 507.72l-179.48-160.63M1537.3 577.37l-186.99-235.68M1433.13 571.79l-163.78-251.32"
				/>
				<rect x={590.8} y={151.4} width={811.51} height={949.94} rx={35.71} ry={35.71} fill="#fff" />
				<path
					className="-_19_Select_ID_type_svg__cls-9"
					d="M626.51 151.4h740.09a35.71 35.71 0 0135.71 35.71v428.43H590.8V187.11a35.71 35.71 0 0135.71-35.71z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-10"
					d="M1402.31 187.11a35.71 35.71 0 00-35.71-35.71H626.51a35.71 35.71 0 00-35.71 35.71V201c89.92.85 187.67 33.92 217.82 161.47 30.41 128.63 78.31 217.76 167.32 253h218.84c51.69-12.86 110.06-33.25 176.13-61.79q16-6.91 31.4-12.68z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-10"
					d="M1366.6 151.4H626.51a35.71 35.71 0 00-35.71 35.71V517c131 16.13 320.3-105.78 499.11-175.35 131.94-51.33 241.06-17.34 312.4 23.47v-178a35.71 35.71 0 00-35.71-35.72z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-6"
					d="M1491.09 182.73s-282.73 36.7-324.63 45.6-115.89 47.31 6.13 79.75 392.36 64 392.36 64"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-7"
					d="M1427.68 191.05s-219.32 28.38-261.22 37.28-115.89 47.31 6.13 79.75 335.5 54.43 335.5 54.43"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-6"
					d="M245.82 964.38s281-47.94 323.71-51.73 124.67 11.2 17.56 78-356.26 176.45-356.26 176.45"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-7"
					d="M393.62 939.54s133.24-23.1 175.91-26.89 124.67 11.2 17.56 78-304.73 150.55-304.73 150.55"
				/>
				<rect className="-_19_Select_ID_type_svg__cls-9" x={967.92} y={672.06} width={300.25} height={33.23} rx={16.62} ry={16.62} />
				<rect className="-_19_Select_ID_type_svg__cls-11" x={967.92} y={729.29} width={225.6} height={33.23} rx={16.62} ry={16.62} />
				<rect className="-_19_Select_ID_type_svg__cls-11" x={967.92} y={786.52} width={282.04} height={33.23} rx={16.62} ry={16.62} />
				<rect className="-_19_Select_ID_type_svg__cls-11" x={967.92} y={843.76} width={206.79} height={33.23} rx={16.62} ry={16.62} />
				<rect className="-_19_Select_ID_type_svg__cls-11" x={967.92} y={900.99} width={206.79} height={33.23} rx={16.62} ry={16.62} />
				<rect x={675.84} y={672.06} width={252.71} height={262.16} rx={12.6} ry={12.6} fill="#e4eaf4" />
				<path
					className="-_19_Select_ID_type_svg__cls-13"
					d="M767.65 722.56s-22.09 19.13-23 50.67 8.94 45.23-7 58.78-44 28.11-22.54 64.62h87.44V729.1z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-13"
					d="M836.64 722.56s22.09 19.13 23 50.67-5.34 46.46 10.57 60 40.41 26.88 18.95 63.39h-87.44V729.1z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-9"
					d="M913.78 934.22H689.55s2.26-28 15.79-33.39c2.8-1.12 7.46-3.3 13.09-6.06l2.39-1.18C732.55 887.78 747.61 880 759 874c9.7-5.08 16.73-8.82 16.73-8.82l1.71.38a106.88 106.88 0 0048.36-.38s5.79 3 14.15 7.24c4.94 2.52 10.79 5.49 16.87 8.55 16.36 8.24 34.41 17.15 41.17 19.85 13.54 5.42 15.79 33.4 15.79 33.4z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-14"
					d="M740.45 934.22h-6c-4.63-10.21-12.38-27.83-16-39.45l2.39-1.18c2.67 6.88 11.48 28.66 19.61 40.63zM858.82 934.22h6c4.64-10.21 13.15-29.32 16.81-41l-2.18-1c-2.71 6.87-12.45 30.03-20.63 42z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-9"
					d="M789.91 934.22h-49c-8.24-12.27-15.89-30.06-20.1-40.64-2.13-5.35-3.38-8.86-3.38-8.86C730.09 874.73 759 874 759 874c10.29 13.8 23.57 43.13 30.91 60.22zM809.82 934.22h49c8.24-12.27 15.89-30.06 20.09-40.64 2.13-5.35 3.38-8.86 3.38-8.86-12.63-10-42.32-12.29-42.32-12.29-10.31 13.79-22.81 44.7-30.15 61.79z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-6"
					d="M830.49 867.59a10.23 10.23 0 01-5.39-7.12c-1.28-6.42-3.46-17.67-4.63-24.56-.63-3.72-1.19-7.41-1.53-10.54l-37 1.46a72.5 72.5 0 01-1.69 16.69c-.68 3.19-3.37 12.64-5.37 18.84a10.22 10.22 0 01-5 5.92L759 874a167.75 167.75 0 007.16 21.47c6.19 15 16.29 32.61 31.07 38.74h17.68c10.73-5.75 17.11-22.4 20.8-37.59a203.25 203.25 0 004.24-24.2c-3.78-1.92-7.04-3.58-9.46-4.83z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-15"
					d="M835.71 896.63c-3.69 15.19-10.07 31.84-20.8 37.59h-17.68c-14.78-6.13-24.88-23.74-31.07-38.74 7.75 3.2 19.3 6.12 35.65 6.12a108.79 108.79 0 0033.9-4.97zM823.79 863.38c.56-.74 1.05-1.48 1.5-2.2-.07-.24-.14-.47-.19-.71-.09-.44-.18-.91-.28-1.39a29 29 0 01-2.23 3.46c-4.89 6.53-11.63 9.83-20 9.83a27.17 27.17 0 01-21.26-9.87 36.09 36.09 0 01-4.48-6.51c-.2.66-.39 1.31-.59 2a37.48 37.48 0 003.93 5.48 29.06 29.06 0 0022.4 10.42 25.49 25.49 0 0021.2-10.51z"
				/>
				<path d="M819.53 829.78c-5.74 4-16.31 10.16-38.27 7.84a76.25 76.25 0 00.66-10.79l37-1.46a31 31 0 00.61 4.41z" fill="#09030b" />
				<path className="-_19_Select_ID_type_svg__cls-6" d="M767.41 781.08s-8.21-11.17-14.59-4.56 5.93 29.63 17.1 24.62z" />
				<path
					className="-_19_Select_ID_type_svg__cls-17"
					d="M765.13 796.26a.75.75 0 01-.74-.65c-1.42-10.26-9.58-16.56-9.67-16.62a.75.75 0 01.91-1.2c.36.27 8.74 6.75 10.25 17.62a.75.75 0 01-.64.84z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-17"
					d="M762.86 788.89a.75.75 0 01-.62-.33c-1.09-1.61-4.35-1.78-5.51-1.7a.75.75 0 01-.79-.71.74.74 0 01.7-.79c.21 0 5.05-.29 6.84 2.36a.75.75 0 01-.2 1 .72.72 0 01-.42.17z"
				/>
				<path className="-_19_Select_ID_type_svg__cls-6" d="M838.64 781.08s8.21-11.17 14.59-4.56-5.92 29.63-17.09 24.62z" />
				<path
					className="-_19_Select_ID_type_svg__cls-17"
					d="M840.13 796.26H840a.75.75 0 01-.64-.84c1.51-10.87 9.89-17.35 10.25-17.62a.75.75 0 01.91 1.2c-.09.06-8.25 6.38-9.67 16.62a.75.75 0 01-.72.64z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-17"
					d="M842.4 788.89a.72.72 0 01-.42-.13.75.75 0 01-.2-1c1.79-2.65 6.64-2.37 6.84-2.36a.74.74 0 01.7.79.75.75 0 01-.79.71c-1.17-.07-4.42.09-5.51 1.7a.75.75 0 01-.62.29z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-6"
					d="M807.58 734.51c-32.07-.87-44.64 15.09-44.64 48.33 0 15.55 4.29 48.16 38.85 49.15 37.06 1.07 40.67-39.53 40.67-52.47 0-22.4-5.06-37.37-24.86-43.45a87.43 87.43 0 00-10.02-1.56z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-13"
					d="M763.61 793s5.23-14.31 3-24.85l-7.4-2.16s.38 18.08 4.4 27.01zM841.53 792s-5.08-13.28-2.82-23.82l7.4-2.16s-.56 16.98-4.58 25.98z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-13"
					d="M767.65 722.56c-8.21 8.11-14.61 21.39-15 29.51s1.67 16.37 7.83 19.51c12.61 6.41 35.87-.78 50.43-6.51a22.2 22.2 0 0121.21 2.62c3.56 2.58 8 4.63 12.57 4.07 11.08-1.35 15.79-33.56-10.57-51.58s-53.89-10.05-66.47 2.38z"
				/>
				<path
					className="-_19_Select_ID_type_svg__cls-17"
					d="M804.52 804.8a10.89 10.89 0 01-4.25-1 1 1 0 01-.61-1.09l2.17-16.71a1 1 0 011.16-.9 1.05 1.05 0 01.9 1.17l-2.07 15.87c2.15.8 3.81.73 5.18-.19a1 1 0 111.16 1.72 6.37 6.37 0 01-3.64 1.13zM802 815.58a21.37 21.37 0 01-2.52-.47 23.7 23.7 0 01-7.83-3.47 1 1 0 011.23-1.67c.46.34 11.43 8.2 22.83.06a1 1 0 011.21 1.69 20.6 20.6 0 01-14.92 3.86z"
				/>
				<g className="-_19_Select_ID_type_svg__cls-18">
					<rect className="-_19_Select_ID_type_svg__cls-19" x={675.84} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={696.86} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={717.87} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={738.89} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={759.9} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={780.92} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={801.94} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={822.95} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={843.97} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={864.98} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={886} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={907.02} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={928.03} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={949.05} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={970.06} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={991.08} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1012.09} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1033.11} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1054.13} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1075.14} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1096.81} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1117.82} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1138.84} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1159.85} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1180.87} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1201.89} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1222.9} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1243.92} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1264.93} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1285.95} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1306.97} y={987.94} width={10.3} height={19.81} rx={5.11} ry={5.11} />
				</g>
				<g className="-_19_Select_ID_type_svg__cls-18">
					<rect className="-_19_Select_ID_type_svg__cls-19" x={675.84} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={696.86} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={717.87} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={738.89} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={759.9} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={780.92} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={801.94} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={822.95} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={843.97} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={864.98} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={886} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={907.02} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={928.03} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={949.05} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={970.06} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={991.08} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1012.09} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1033.11} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1054.13} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1075.14} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1096.81} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1117.82} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1138.84} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1159.85} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1180.87} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1201.89} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1222.9} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1243.92} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1264.93} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1285.95} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1306.97} y={1010.6} width={10.3} height={19.81} rx={5.11} ry={5.11} />
				</g>
				<g className="-_19_Select_ID_type_svg__cls-18">
					<rect className="-_19_Select_ID_type_svg__cls-19" x={675.84} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={696.86} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={717.87} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={738.89} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={759.9} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={780.92} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={801.94} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={822.95} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={843.97} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={864.98} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={886} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={907.02} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={928.03} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={949.05} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={970.06} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={991.08} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1012.09} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1033.11} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1054.13} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1075.14} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1096.81} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1117.82} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1138.84} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1159.85} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1180.87} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1201.89} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1222.9} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1243.92} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1264.93} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1285.95} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
					<rect className="-_19_Select_ID_type_svg__cls-19" x={1306.97} y={1035.35} width={10.3} height={19.81} rx={5.11} ry={5.11} />
				</g>
				<circle className="-_19_Select_ID_type_svg__cls-9" cx={778.7} cy={861.25} r={2.69} />
				<circle className="-_19_Select_ID_type_svg__cls-9" cx={783.11} cy={865.81} r={2.69} />
				<circle className="-_19_Select_ID_type_svg__cls-9" cx={788.23} cy={869.5} r={2.69} />
				<circle className="-_19_Select_ID_type_svg__cls-9" cx={793.86} cy={872} r={2.69} />
				<circle className="-_19_Select_ID_type_svg__cls-9" cx={799.88} cy={873.09} r={2.69} />
				<circle className="-_19_Select_ID_type_svg__cls-9" cx={806.1} cy={873.09} r={2.69} />
				<circle className="-_19_Select_ID_type_svg__cls-9" cx={812.26} cy={871.19} r={2.69} />
				<circle className="-_19_Select_ID_type_svg__cls-9" cx={817.85} cy={867.88} r={2.69} />
				<circle className="-_19_Select_ID_type_svg__cls-9" cx={822.38} cy={863.44} r={2.69} />
				<path
					className="-_19_Select_ID_type_svg__cls-19"
					d="M1297.59 882.9c-3.65-8.8-14.83-10.82-22.34-5.47-11.5 8.2-9.83 23.64-12.64 35.79-1.49 6.44-5.51 14.93-13.07 15.5a9.54 9.54 0 01-8.17-3.48c-3.43-4.59 1-9.93 5.93-10.34 8.61-.73 11.64 8.58 17.78 12.58a21.33 21.33 0 0016.05 2.58c10-2.19 20.75-12.8 22.09-23.2.49-3.77-.64-9.54-4.84-10.88-3.94-1.26-8.55 2.15-10.32 5.46-5.34 10 5.78 18.36 15 17.1s22.83-10.6 17.88-21.32-19.32.77-13.29 9.81c3.1 4.65 9.17 5.82 14.15 3.86 6.43-2.52 9.87-8.59 12.84-14.45l-4.75-1.29a73.68 73.68 0 01-6.48 29.12c-3.51 7.67-9.77 19.89-19.91 18.78-3.62-.4-7.34-2.52-4.44-6.81 1.23-1.82 3.86-3.27 5.62-4.51a75.51 75.51 0 0111.42-6.55c8.34-3.94 17.26-6.5 26.18-8.77 3.18-.81 1.83-5.73-1.36-4.92-12.49 3.18-25.1 7.07-36.08 14-3.88 2.46-9.68 5.78-11.58 10.18s.86 9 4.93 11.12c10.86 5.57 20.88-4.49 26-13a78.72 78.72 0 0010.81-38.64c0-2.45-3.6-3.57-4.75-1.28-2.12 4.17-4.34 8.88-8.49 11.41-2.25 1.37-5.36 2.19-7.79.75-5.26-3.09-.38-10.77 2.63-6 2.82 4.49-5.31 9.85-8.36 11.46-4.46 2.36-11.1 3.51-14.73-1a5.9 5.9 0 01.35-8.24c2.49-2.31 3.86-.71 4.33 2 .84 4.83-4.39 11.37-7.6 14.6-7.18 7.21-18.53 9.34-26 1.34-5.4-5.82-10.94-12.45-19.92-10.22-6 1.5-10.85 7.54-9.48 13.86 1.22 5.63 6.75 9.31 12.26 9.86 7.07.69 13.14-4.17 16.52-10 5.41-9.32 4.13-20.32 6.91-30.39 1.32-4.78 3.83-10 8.39-12.53 4.34-2.37 11.27-1.68 13.38 3.4 1.24 3 6.17 1.67 4.91-1.36z"
				/>
			</g>
		</svg>
	);
}

export default SelectIdTypeSvg;
