import * as React from 'react';

function Svg04Driverlicence1(props) {
	return (
		<svg id="-_04_Driver_licence_-_1_svg__\u0421\u043B\u043E\u0439_1" data-name="\u0421\u043B\u043E\u0439 1" viewBox="0 0 946 583.8" {...props}>
			<defs>
				<style>
					{
						'.-_04_Driver_licence_-_1_svg__cls-4{fill:#c2c9ce}.-_04_Driver_licence_-_1_svg__cls-5{fill:#333}.-_04_Driver_licence_-_1_svg__cls-6{fill:#e8ebed}.-_04_Driver_licence_-_1_svg__cls-7{fill:#c8d0d6}.-_04_Driver_licence_-_1_svg__cls-8{fill:#09030b}.-_04_Driver_licence_-_1_svg__cls-9{fill:#98a0a5}.-_04_Driver_licence_-_1_svg__cls-12{fill:#32384f;stroke:#32384f;stroke-miterlimit:10;stroke-width:.89px}'
					}
				</style>
			</defs>
			<rect width={946} height={583.8} rx={35.71} ry={35.71} fill="#fff" />
			<rect width={946} height={583.8} rx={35.71} ry={35.71} fill="#e6ebef" />
			<path d="M35.71 0h874.58A35.71 35.71 0 01946 35.71V99H0V35.71A35.71 35.71 0 0135.71 0z" fill="#d7dce0" />
			<rect className="-_04_Driver_licence_-_1_svg__cls-4" x={714.48} y={476.64} width={171.7} height={27.54} rx={13.77} ry={13.77} />
			<rect className="-_04_Driver_licence_-_1_svg__cls-4" x={714.48} y={148.5} width={171.7} height={27.54} rx={13.77} ry={13.77} />
			<rect className="-_04_Driver_licence_-_1_svg__cls-4" x={596.71} y={524.06} width={289.47} height={27.54} rx={13.77} ry={13.77} />
			<path
				className="-_04_Driver_licence_-_1_svg__cls-5"
				d="M668 448.41s-49.24 9.9-51.32-31.56c-.9-18-3.32-15.14-19-30.27s-15.69-21.3-12.33-35.31 17.5-18.82 10.51-31.77c-9.2-17.05-6.38-46.2 13.61-57.56 19.33-11 21.5-10.84 21.5-22.1 0-14.71 15.28-38.42 46.79-36 27.1 2.12 22.89-11.07 42.11-16.94 35.73-10.9 53.07 20.1 65.4 21.21s33.66 1.33 44.49 17.71c13.42 20.32 1.56 36.91 18.37 38.59s33.63 16.25 33.07 31.38-9.53 28-3.37 34.75 8.56 46.55-16.32 54.17c-34 10.42 0 60.74-62.52 59.81-56.19-.84-130.99 3.89-130.99 3.89z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-6"
				d="M767.8 377.45a12 12 0 01-6.28-8.31c-1.49-7.48-4-20.59-5.4-28.62-.73-4.33-1.38-8.64-1.78-12.29l-43.16 1.71a83.43 83.43 0 01-2 19.45c-.78 3.71-3.92 14.74-6.25 22a11.92 11.92 0 01-5.79 6.9c-3.34 1.76-7.71 4.07-12.7 6.68a194.84 194.84 0 008.35 25c7.22 17.49 19 38 36.21 45.16h20.62c12.5-6.7 19.94-26.11 24.25-43.82a234.62 234.62 0 004.93-28.21c-4.37-2.26-8.17-4.19-11-5.65z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-7"
				d="M864.91 455.12h-261.4s2.63-32.61 18.41-38.92c3.26-1.3 8.69-3.85 15.25-7.07l2.79-1.37c13.65-6.76 31.22-15.87 44.49-22.82 11.3-5.92 16.82-10.28 16.82-10.28l2.55 3c13.12 16.32 44.59 21.85 60.37-3a125.62 125.62 0 0014.62 8.45c5.77 2.94 12.58 6.4 19.68 10 19.06 9.6 40.1 20 48 23.14 15.79 6.26 18.42 38.87 18.42 38.87z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-8"
				d="M755 333.37c-6.69 4.65-19 11.85-44.61 9.15a87.42 87.42 0 00.76-12.58l43.16-1.71a36 36 0 00.69 5.14z"
			/>
			<path className="-_04_Driver_licence_-_1_svg__cls-6" d="M694.28 276.6s-9.57-13-17-5.31 6.91 34.54 19.93 28.7z" />
			<path
				className="-_04_Driver_licence_-_1_svg__cls-9"
				d="M691.62 294.3a.87.87 0 01-.86-.76c-1.67-12-11.18-19.31-11.27-19.38a.87.87 0 011-1.39c.42.31 10.19 7.86 12 20.53a.89.89 0 01-.75 1z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-9"
				d="M689 285.7a.85.85 0 01-.72-.38c-1.27-1.88-5.07-2.07-6.42-2a.86.86 0 01-.93-.81.87.87 0 01.82-.93c.24 0 5.89-.33 8 2.75a.86.86 0 01-.23 1.21.81.81 0 01-.52.16z"
			/>
			<path className="-_04_Driver_licence_-_1_svg__cls-6" d="M777.31 276.6s9.57-13 17-5.31-6.91 34.54-19.93 28.7z" />
			<path
				className="-_04_Driver_licence_-_1_svg__cls-9"
				d="M779 294.3h-.12a.87.87 0 01-.74-1c1.76-12.67 11.52-20.22 11.94-20.53a.87.87 0 011.06 1.39c-.1.07-9.61 7.44-11.27 19.38a.88.88 0 01-.87.76z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-9"
				d="M781.69 285.7a.81.81 0 01-.49-.15.87.87 0 01-.23-1.21c2.08-3.08 7.73-2.77 8-2.75a.87.87 0 01-.1 1.74c-1.36-.08-5.16.12-6.42 2a.88.88 0 01-.76.37z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-6"
				d="M741.1 222.32c-37.38-1-52 17.59-52 56.33 0 18.13 5 56.15 45.29 57.3 43.2 1.24 47.41-46.08 47.41-61.17 0-26.11-5.9-43.56-29-50.64a102.85 102.85 0 00-11.7-1.82z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-5"
				d="M689.84 290.47s6.1-16.68 3.47-29l-8.63-2.47s.48 21.08 5.16 31.47zM780.68 289.28s-5.92-15.49-3.29-27.77L786 259s-.64 19.89-5.32 30.28z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-5"
				d="M694.55 208.38c-9.57 9.46-17 24.94-17.53 34.4s2 19.09 9.13 22.74c14.7 7.48 41.82-.91 58.78-7.59a25.92 25.92 0 0124.73 3.07c4.15 3 9.28 5.4 14.66 4.74 12.91-1.57 18.4-39.12-12.33-60.12s-62.77-11.72-77.44 2.76z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-9"
				d="M737.54 304.25a12.5 12.5 0 01-5-1.18 1.22 1.22 0 01-.72-1.26l2.54-19.44a1.2 1.2 0 011.35-1 1.21 1.21 0 011 1.35l-2.41 18.51c2.5.93 4.44.85 6-.23a1.21 1.21 0 111.35 2 7.45 7.45 0 01-4.11 1.25z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-9"
				d="M737.54 304.94a13.1 13.1 0 01-5.23-1.23 1.92 1.92 0 01-1.13-2l2.53-19.44a1.93 1.93 0 01.73-1.26 1.85 1.85 0 011.4-.37 1.9 1.9 0 011.64 2.12l-2.34 18c2 .66 3.6.55 4.9-.33a1.9 1.9 0 112.13 3.15 8.14 8.14 0 01-4.63 1.4zM735.59 282a.5.5 0 00-.31.1.55.55 0 00-.2.35l-2.53 19.44a.5.5 0 00.31.54 11.84 11.84 0 004.68 1.12 6.74 6.74 0 003.86-1.16.53.53 0 00-.59-.87c-1.78 1.2-4 1.3-6.66.31l-.52-.19 2.48-19a.52.52 0 00-.45-.59zM734.6 316.82a25.29 25.29 0 01-2.94-.55 27.86 27.86 0 01-9.13-4 1.21 1.21 0 011.44-2c.54.4 13.32 9.56 26.61.08a1.21 1.21 0 111.41 2 24.05 24.05 0 01-17.39 4.47z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-9"
				d="M737.8 317.72a24.57 24.57 0 01-3.29-.22 24.63 24.63 0 01-3-.56 28.52 28.52 0 01-9.37-4.16 1.9 1.9 0 112.26-3c.52.38 12.92 9.26 25.8.07a1.84 1.84 0 011.42-.33 1.85 1.85 0 011.23.77 1.9 1.9 0 01-.44 2.65 24.73 24.73 0 01-14.61 4.78zm-14.55-7a.53.53 0 00-.31.95 27.1 27.1 0 008.88 3.92 23.76 23.76 0 002.87.54 23.29 23.29 0 0016.9-4.37.54.54 0 00.12-.73.48.48 0 00-.34-.21.45.45 0 00-.39.09c-13.68 9.76-26.87.32-27.42-.08a.51.51 0 00-.31-.1z"
			/>
			<path
				className="-_04_Driver_licence_-_1_svg__cls-7"
				d="M645.14 405.18l-5.14 2.57-2.79 1.37c-6.56 3.22-12 5.77-15.25 7.07-15.78 6.31-18.41 38.92-18.41 38.92h52.35c3.76-25.8-4.36-41.75-10.76-49.93zM846.5 416.2c-4.72-1.89-14.15-6.36-25-11.71-6.47 8-15.17 24.08-11.3 50.63h54.75s-2.67-32.61-18.45-38.92z"
			/>
			<g opacity={0.3}>
				<path
					className="-_04_Driver_licence_-_1_svg__cls-5"
					d="M137.5 450.78s-31.87 6.41-33.22-20.44c-.58-11.61-2.15-9.8-12.31-19.59S81.81 397 84 387.89s11.33-12.19 6.8-20.57c-6-11-4.12-29.91 8.82-37.27 12.51-7.11 13.91-7 13.91-14.3 0-9.53 9.89-24.87 30.3-23.28 17.54 1.37 14.82-7.17 27.26-11 23.16-7.09 34.39 13 42.37 13.7s21.79.87 28.8 11.47c8.69 13.16 1 23.9 11.9 25s21.77 10.53 21.4 20.32-6.16 18.14-2.17 22.5 5.53 30.13-10.57 35.07c-22 6.74 0 39.32-40.48 38.72-36.34-.53-84.84 2.53-84.84 2.53z"
				/>
				<path
					className="-_04_Driver_licence_-_1_svg__cls-6"
					d="M202.14 404.84a7.72 7.72 0 01-4.06-5.38c-1-4.85-2.62-13.33-3.5-18.53-.47-2.81-.89-5.59-1.15-8l-27.94 1.11a54.91 54.91 0 01-1.27 12.59c-.51 2.4-2.54 9.54-4 14.22a7.77 7.77 0 01-3.76 4.47l-8.22 4.32a127.41 127.41 0 005.41 16.21c4.67 11.32 12.29 24.61 23.44 29.23h13.35c8.1-4.34 12.91-16.9 15.7-28.37a151.72 151.72 0 003.19-18.26z"
				/>
				<path
					className="-_04_Driver_licence_-_1_svg__cls-7"
					d="M265 455.12H95.78s1.7-21.11 11.92-25.19c2.11-.85 5.63-2.5 9.87-4.58l1.81-.89c8.84-4.37 20.21-10.27 28.8-14.77a77.38 77.38 0 0010.9-6.69l1.65 2c8.49 10.57 28.87 14.15 39.09-2a81.4 81.4 0 009.46 5.47c3.73 1.9 8.15 4.14 12.74 6.45 12.34 6.22 26 12.94 31.07 15 10.22 4.08 11.91 25.2 11.91 25.2z"
				/>
				<path
					className="-_04_Driver_licence_-_1_svg__cls-8"
					d="M193.87 376.3c-4.33 3-12.3 7.67-28.88 5.92a57.18 57.18 0 00.5-8.14l27.94-1.08a24.54 24.54 0 00.44 3.3z"
				/>
				<path d="M154.54 339.55s-6.19-8.43-11-3.44 4.47 22.36 12.9 18.58z" fill="#ffca27" />
				<path
					className="-_04_Driver_licence_-_1_svg__cls-12"
					d="M152.82 351a.56.56 0 01-.56-.48C151.19 342.77 145 338 145 338a.56.56 0 01.68-.9 21.78 21.78 0 017.73 13.29.56.56 0 01-.48.64z"
				/>
				<path
					className="-_04_Driver_licence_-_1_svg__cls-12"
					d="M151.11 345.44a.57.57 0 01-.47-.25c-.82-1.21-3.28-1.34-4.16-1.28a.57.57 0 01-.6-.53.56.56 0 01.53-.6c.16 0 3.82-.22 5.16 1.78a.55.55 0 01-.15.78.5.5 0 01-.31.1z"
				/>
				<path className="-_04_Driver_licence_-_1_svg__cls-6" d="M208.3 339.55s6.19-8.43 11-3.44-4.47 22.36-12.9 18.58z" />
				<path
					className="-_04_Driver_licence_-_1_svg__cls-12"
					d="M209.42 351h-.08a.56.56 0 01-.48-.64 21.78 21.78 0 017.73-13.29.56.56 0 11.68.9c-.06 0-6.22 4.82-7.29 12.55a.56.56 0 01-.56.48z"
				/>
				<path
					className="-_04_Driver_licence_-_1_svg__cls-12"
					d="M211.13 345.44a.5.5 0 01-.31-.1.55.55 0 01-.15-.78c1.35-2 5-1.79 5.16-1.78a.56.56 0 01.53.6.57.57 0 01-.6.53c-.88-.06-3.34.07-4.16 1.28a.56.56 0 01-.47.25z"
				/>
				<path
					className="-_04_Driver_licence_-_1_svg__cls-6"
					d="M184.86 304.4c-24.2-.66-33.69 11.39-33.69 36.47 0 11.74 3.24 36.35 29.31 37.1 28 .8 30.7-29.83 30.7-39.6 0-16.91-3.82-28.2-18.76-32.79a70.36 70.36 0 00-7.56-1.18z"
				/>
				<path d="M151.67 348.53s4-10.8 2.24-18.76l-5.58-1.62s.31 13.65 3.34 20.38z" fill="#382c36" />
				<path className="-_04_Driver_licence_-_1_svg__cls-5" d="M210.48 347.75s-3.84-10-2.13-18l5.59-1.62s-.43 12.87-3.46 19.62z" />
				<path
					className="-_04_Driver_licence_-_1_svg__cls-5"
					d="M154.72 295.38c-6.2 6.12-11 16.15-11.35 22.27s1.26 12.36 5.91 14.73c9.52 4.84 27.07-.59 38.06-4.92a16.79 16.79 0 0116 2c2.68 1.95 6 3.5 9.49 3.07 8.35-1 11.91-25.33-8-38.92s-40.61-7.61-50.11 1.77z"
				/>
				<path
					className="-_04_Driver_licence_-_1_svg__cls-12"
					d="M182.55 357.45a8.28 8.28 0 01-3.21-.76.78.78 0 01-.46-.82l1.63-12.59a.79.79 0 011.56.21l-1.56 12a4.08 4.08 0 003.91-.15.79.79 0 011.09.21.8.8 0 01-.21 1.09 4.86 4.86 0 01-2.75.81zM180.65 365.59a18.6 18.6 0 01-1.91-.36 18.05 18.05 0 01-5.91-2.62.78.78 0 01.93-1.26c.35.26 8.63 6.19 17.23 0a.79.79 0 01.91 1.28 15.58 15.58 0 01-11.25 2.96z"
				/>
				<path
					className="-_04_Driver_licence_-_1_svg__cls-7"
					d="M122.73 422.79l-3.34 1.66-1.81.89c-4.24 2.08-7.76 3.73-9.87 4.58-10.23 4.08-11.93 25.2-11.93 25.2h33.91c2.44-16.71-2.82-27.03-6.96-32.33zM253.09 429.93c-3.06-1.23-9.16-4.12-16.21-7.59-4.19 5.16-9.82 15.59-7.32 32.78H265s-1.69-21.12-11.91-25.19z"
				/>
			</g>
			<rect
				className="-_04_Driver_licence_-_1_svg__cls-4"
				x={58.17}
				y={221.01}
				width={215.08}
				height={30.66}
				rx={15.33}
				ry={15.33}
				transform="rotate(-180 165.71 236.34)"
			/>
			<rect
				className="-_04_Driver_licence_-_1_svg__cls-4"
				x={58.17}
				y={329.42}
				width={150.13}
				height={30.66}
				rx={15.33}
				ry={15.33}
				transform="rotate(-180 133.235 344.755)"
			/>
			<rect
				className="-_04_Driver_licence_-_1_svg__cls-4"
				x={58.17}
				y={273.81}
				width={322.31}
				height={30.66}
				rx={15.33}
				ry={15.33}
				transform="rotate(-180 219.325 289.145)"
			/>
			<rect
				className="-_04_Driver_licence_-_1_svg__cls-4"
				x={58.17}
				y={138.01}
				width={399.25}
				height={41.96}
				rx={20.98}
				ry={20.98}
				transform="rotate(-180 257.79 158.995)"
			/>
			<rect
				className="-_04_Driver_licence_-_1_svg__cls-4"
				x={317.95}
				y={28.5}
				width={310.1}
				height={41.96}
				rx={20.98}
				ry={20.98}
				transform="rotate(-180 473 49.48)"
			/>
		</svg>
	);
}

export default Svg04Driverlicence1;
