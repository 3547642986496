import * as React from 'react';

function Svg22NationalIDcardfrontimage(props) {
	return (
		<svg
			id="-_22_National_ID_card_front_image_svg__\u0421\u043B\u043E\u0439_1"
			data-name="\u0421\u043B\u043E\u0439 1"
			viewBox="0 0 1989.16 1287.05"
			{...props}
		>
			<defs>
				<clipPath id="-_22_National_ID_card_front_image_svg__clip-path">
					<path className="-_22_National_ID_card_front_image_svg__cls-1" d="M0 0h1989.16v1287.05H0z" />
				</clipPath>
				<clipPath id="-_22_National_ID_card_front_image_svg__clip-path-2">
					<rect
						className="-_22_National_ID_card_front_image_svg__cls-1"
						x={685.92}
						y={465.91}
						width={142.69}
						height={187.68}
						rx={9.75}
						ry={9.75}
					/>
				</clipPath>
				<clipPath id="-_22_National_ID_card_front_image_svg__clip-path-3">
					<rect
						className="-_22_National_ID_card_front_image_svg__cls-1"
						x={1180.47}
						y={430.37}
						width={186.02}
						height={244.67}
						rx={9.75}
						ry={9.75}
					/>
				</clipPath>
				<style>
					{
						'.-_22_National_ID_card_front_image_svg__cls-1,.-_22_National_ID_card_front_image_svg__cls-25{fill:none}.-_22_National_ID_card_front_image_svg__cls-10{fill:#1188f7}.-_22_National_ID_card_front_image_svg__cls-12{fill:#ffca27}.-_22_National_ID_card_front_image_svg__cls-6{fill:#fff}.-_22_National_ID_card_front_image_svg__cls-11{fill:#0074d3}.-_22_National_ID_card_front_image_svg__cls-13{fill:#09030b}.-_22_National_ID_card_front_image_svg__cls-14,.-_22_National_ID_card_front_image_svg__cls-19,.-_22_National_ID_card_front_image_svg__cls-23{fill:#32384f}.-_22_National_ID_card_front_image_svg__cls-14,.-_22_National_ID_card_front_image_svg__cls-19,.-_22_National_ID_card_front_image_svg__cls-25{stroke:#32384f}.-_22_National_ID_card_front_image_svg__cls-14,.-_22_National_ID_card_front_image_svg__cls-19{stroke-miterlimit:10}.-_22_National_ID_card_front_image_svg__cls-14{stroke-width:.8px}.-_22_National_ID_card_front_image_svg__cls-15{fill:#3f2037}.-_22_National_ID_card_front_image_svg__cls-19{stroke-width:1.05px}.-_22_National_ID_card_front_image_svg__cls-21{fill:#3e4866;opacity:.75}.-_22_National_ID_card_front_image_svg__cls-22{fill:#49bbe8}.-_22_National_ID_card_front_image_svg__cls-24{fill:#f7b614}.-_22_National_ID_card_front_image_svg__cls-25{stroke-linecap:round;stroke-linejoin:round;stroke-width:14.03px}'
					}
				</style>
			</defs>
			<g clipPath="url(#-_22_National_ID_card_front_image_svg__clip-path)">
				<path
					d="M1264 397.54c70.68-90.8 132-240.71 35.83-330.54-71.47-66.75-176.54-81.81-266.1-40.9C997.61 42.58 963.9 64.43 927 78.89c-45.84 18-86 16.12-133 10.26-81.06-10.09-170 22-224.13 87.74-56 68.05-51.13 154.21 8.09 214.7 38 38.82 89 56.92 131.25 89.65 32.48 25.13 59.11 56.62 79.74 92.49 29.85 51.9 43.21 121.1 87.58 162.74 27.6 25.9 65 33.13 102.07 32.53 42.07-.69 105.91-3.24 137.14-37.49 31.4-34.43 38.59-90.53 51.16-133.51 21.1-72.1 51.1-140.45 97.1-200.46z"
					opacity={0.07}
					fill="#1188f7"
				/>
				<path
					d="M726.32 620.45s-202.47 191.84 5.45 360.1c209.76 169.74 305-98.19 507.35-.55 346.52 167.25 599.75-230.34 258.94-434.27s-246.61-381.47-468.3-357.17C782 215.72 920.72 457.68 726.32 620.45z"
					opacity={0.11}
					fill="#ffca27"
				/>
				<path
					d="M484.07 891.91s-22.34 227.22 196.48 219.77c220.75-7.51 136-224.31 313.48-272.41 304-82.4 247.38-464.08-72.76-407.43s-357.08-103.67-481.15 30.24C301.44 611.76 517 687 484.07 891.91z"
					fill="#4dbc76"
					opacity={0.12}
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-6"
					x={606.98}
					y={251.67}
					width={811.51}
					height={500.8}
					rx={35.71}
					ry={35.71}
				/>
				<rect x={606.98} y={251.67} width={811.51} height={500.8} rx={35.71} ry={35.71} fill="#6bc5f2" />
				<g opacity={0.5}>
					<rect
						className="-_22_National_ID_card_front_image_svg__cls-6"
						x={685.92}
						y={465.91}
						width={142.69}
						height={187.68}
						rx={9.75}
						ry={9.75}
					/>
					<g clipPath="url(#-_22_National_ID_card_front_image_svg__clip-path-2)">
						<path
							className="-_22_National_ID_card_front_image_svg__cls-10"
							d="M845.06 653.36H669s.16-2 .67-4.87c.17-1 .39-2.07.65-3.21a52.9 52.9 0 012-6.82c.42-1.09.88-2.17 1.4-3.21a18.51 18.51 0 015.34-6.81 10.37 10.37 0 012.32-1.3c1.1-.44 2.58-1.1 4.33-1.91 3.68-1.71 8.58-4.13 13.89-6.82l6.13-3.12.18-.09c4.47-2.29 9-4.64 13.18-6.81l4-2.07 2.17-1.14.33-.17c5.71-3 9.9-5.22 10.88-5.75h.08l.13-.07 1.35.3q2.52.57 5.07 1t5 .66a84.14 84.14 0 0027.83-1.92l10.33 5.29 1.45.74 6.32 3.21 6.26 3.16 5 2.5 2.31 1.15c2.19 1.1 4.36 2.18 6.47 3.21 5.45 2.69 10.46 5.11 14.19 6.82 1.79.81 3.28 1.47 4.39 1.91a10.6 10.6 0 012.32 1.3 18.39 18.39 0 015.33 6.81 33.86 33.86 0 011.4 3.21 51.36 51.36 0 012 6.82c.27 1.14.48 2.23.66 3.21.54 2.81.7 4.79.7 4.79z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-11"
							d="M794.06 608.39h-75l6.13-3.21h62.54zM814.08 618.41h-114.5l6.13-3.12.18-.09h101.72c2.19 1.1 4.39 2.18 6.47 3.21zM835 628.44H679a10.37 10.37 0 012.32-1.3c1.1-.44 2.58-1.1 4.33-1.91h142.62c1.79.81 3.28 1.47 4.39 1.91a10.6 10.6 0 012.34 1.3zM841.71 638.46H672.3c.42-1.09.88-2.17 1.4-3.21h166.61a33.86 33.86 0 011.4 3.21zM844.39 648.49H669.63c.17-1 .39-2.07.65-3.21h173.45c.27 1.14.48 2.23.66 3.21z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-11"
							d="M786.29 604.44c-2 7.11-10.74 18.28-29.28 18.28-19.18 0-29.81-10.36-34-16.4l2.17-1.14.33-.17c5.71-3 9.9-5.22 10.88-5.75h.08l.13-.07 1.35.3q2.52.57 5.07 1t5 .66a84.14 84.14 0 0027.83-1.92z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-12"
							d="M777.14 601.33s-3.46 13.35-19.34 13.35c-18.53 0-22.89-12.13-22.89-12.13a117.49 117.49 0 003.46-14.44c1.38-7.94 3.15-16.77 3.07-20.59l31 1c.3 3.39.79 7.37 1.34 11.38 1.44 10.64 3.36 21.43 3.36 21.43z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-13"
							d="M773.2 575.88c-5.05 4.31-14.42 8.59-33.73 6.09 1.38-7.94 2.59-13.17 2.51-17l30.43 3.54c.3 3.39.23 3.36.79 7.37z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-12"
							d="M728.52 536.36s-6.81-9.28-12.12-3.79 4.93 24.62 14.21 20.46z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-14"
							d="M727.3 548.8a.6.6 0 01-.6-.52c-1.15-8.31-7.76-13.41-7.83-13.47a.6.6 0 01-.11-.85.61.61 0 01.85-.11c.29.22 7.07 5.46 8.29 14.26a.59.59 0 01-.51.68z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-14"
							d="M725.46 542.83a.58.58 0 01-.5-.27c-.88-1.3-3.52-1.43-4.46-1.37a.61.61 0 01-.08-1.22c.17 0 4.1-.23 5.54 1.91a.61.61 0 01-.16.85.59.59 0 01-.34.1z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-12"
							d="M790 536.36s6.81-9.28 12.12-3.79-4.93 24.62-14.21 20.46z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-14"
							d="M789.22 548.8h-.09a.59.59 0 01-.51-.68c1.22-8.8 8-14 8.29-14.26a.6.6 0 01.85.11.61.61 0 01-.11.85c-.07.06-6.68 5.17-7.83 13.47a.6.6 0 01-.6.51z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-14"
							d="M791.06 542.83a.59.59 0 01-.34-.1.61.61 0 01-.16-.85c1.44-2.14 5.37-1.92 5.54-1.91a.61.61 0 01-.08 1.22c-.94-.06-3.58.07-4.46 1.37a.6.6 0 01-.5.27z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-12"
							d="M763.87 497.57c-25 0-40.61 8.46-38.33 34.95 1.84 21.38 5.23 42.9 33.88 42.9 22 0 34.14-23.59 32.51-41.06-1.4-15-5.58-31.11-21.61-35.78a40.5 40.5 0 00-6.45-1.01z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-15"
							d="M725.78 534.89S719 518.51 722 500.15c2.39-15 13.7-22 36.24-21.93 18.17 0 27.17 3.25 28.63 9.69 0 0 8.6 3.64 11 12.93 4.21 16.39-6.48 37.66-6.48 37.66s5-7-3.7-11c-6.9-3.12-8.79-5.21-14.64-5.33s-12.31-1.38-19.51-2.35c-9.1-1.23-14.52-2.33-20.09-6.43-.04.04-1.52 14.18-7.67 21.5z"
						/>
						<path
							className="-_22_National_ID_card_front_image_svg__cls-14"
							d="M759.73 550.4a8.7 8.7 0 01-3.45-.82.86.86 0 01-.51-.9l1.76-13.5a.87.87 0 011-.75.85.85 0 01.74 1l-1.67 12.83a4.34 4.34 0 004.16-.16.87.87 0 011.2.23.88.88 0 01-.23 1.21 5.24 5.24 0 01-3 .86zM757.61 560.61a20 20 0 01-8.29-3.61.87.87 0 011.1-1.34c.38.31 8.9 7.09 18.43 1a.87.87 0 011.2.26.88.88 0 01-.27 1.2 16.56 16.56 0 01-12.17 2.49z"
						/>
					</g>
				</g>
				<path
					d="M1382.78 752.47a35.71 35.71 0 0035.71-35.71V439.15c-105.53-57-276.45-91.32-360.1 22.52-97 132-257.3 169.84-340.15 180.42-27.78 3.55-72.46 27-111.26 62.22v12.45a35.71 35.71 0 0035.71 35.71z"
					opacity={0.09}
					fill="#fff"
				/>
				<path
					d="M1258.45 337.58s6.8 14.42 1.2 36.79-24.33 51.47-28.59 74.41 9.25 22-9.49 41.4a365.13 365.13 0 00-47.63 60.82c-14.61 23-40.71 67.46-47.64 89.28s-10.64 43.42-13.8 54 4.87 5-21.88-7.81-40.28-11.91-41.62-22.17 5.73-20.45-10.72-24.51-27.53-5.25-47.16-8.44-17.75-13.68-27.57-5.89-11.78 26.67-19 15.52-14.85-28.11-28.25-35-14.9-.28-19-13-4.63-30.09-19.49-29.73-33.54-8.07-40.62-7.59-16.52 14.06-11.86-3.08.66-200 2.42-202.67 237.15 5.25 259.82 5.66 36.45-6.36 52-6.12 18.65 7.79 40.85 10.81 33.84 2.88 45.46-7 32.57-15.68 32.57-15.68z"
					opacity={0.25}
					fill="#fff"
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-6"
					x={1180.47}
					y={430.37}
					width={186.02}
					height={244.67}
					rx={9.75}
					ry={9.75}
				/>
				<g clipPath="url(#-_22_National_ID_card_front_image_svg__clip-path-3)">
					<path
						className="-_22_National_ID_card_front_image_svg__cls-10"
						d="M1387.93 674.73h-229.57s.21-2.58.88-6.35c.22-1.28.5-2.69.85-4.19a67.05 67.05 0 012.63-8.88c.54-1.42 1.15-2.83 1.82-4.18a24.1 24.1 0 017-8.89 13.64 13.64 0 013-1.69c1.44-.58 3.36-1.43 5.65-2.49 4.79-2.23 11.18-5.38 18.11-8.89 2.6-1.31 5.29-2.68 8-4.06l.23-.12c5.83-3 11.74-6 17.18-8.89l5.16-2.7c1-.5 1.92-1 2.84-1.48l.42-.23c7.44-3.9 12.91-6.8 14.19-7.48l.11-.06.17-.09 1.75.4q3.3.74 6.61 1.26c2.19.35 4.38.64 6.57.85a109.46 109.46 0 0036.28-2.5h.06s5.46 2.81 13.46 6.89l1.89 1 8.25 4.18c2.63 1.34 5.37 2.72 8.16 4.12 2.14 1.08 4.31 2.18 6.49 3.26l3 1.51c2.85 1.43 5.68 2.83 8.43 4.18 7.11 3.5 13.64 6.66 18.5 8.89 2.33 1.06 4.27 1.91 5.72 2.49a13.8 13.8 0 013 1.69 24 24 0 017 8.89 44.35 44.35 0 011.82 4.18 68.7 68.7 0 012.64 8.88c.34 1.5.62 2.91.85 4.19.65 3.73.85 6.31.85 6.31z"
					/>
					<path
						className="-_22_National_ID_card_front_image_svg__cls-11"
						d="M1321.46 616.1h-97.77l8-4.18h81.52zM1347.55 629.17h-149.26c2.6-1.31 5.29-2.68 8-4.06l.23-.12h132.61c2.87 1.43 5.67 2.83 8.42 4.18zM1374.79 642.24H1171.5a13.64 13.64 0 013-1.69c1.44-.58 3.36-1.43 5.65-2.49h185.87c2.33 1.06 4.27 1.91 5.72 2.49a13.8 13.8 0 013.05 1.69zM1383.57 655.31h-220.85c.54-1.42 1.15-2.83 1.82-4.18h217.21a44.35 44.35 0 011.82 4.18zM1387.06 668.38h-227.82c.22-1.28.5-2.69.85-4.19h226.12c.34 1.5.62 2.91.85 4.19z"
					/>
					<path
						className="-_22_National_ID_card_front_image_svg__cls-11"
						d="M1311.32 611c-2.63 9.27-14 23.84-38.17 23.84-25 0-38.86-13.5-44.3-21.39 1-.5 1.92-1 2.84-1.48l.42-.23c7.44-3.9 12.91-6.8 14.19-7.48l.11-.06.17-.09 1.75.4q3.3.74 6.61 1.26c2.19.35 4.38.64 6.57.85a109.46 109.46 0 0036.28-2.5h.06s5.47 2.75 13.47 6.88z"
					/>
					<path
						className="-_22_National_ID_card_front_image_svg__cls-12"
						d="M1299.39 606.91s-4.51 17.4-25.22 17.4c-24.15 0-29.84-15.82-29.84-15.82a151.4 151.4 0 004.52-18.82c1.79-10.36 4.11-21.86 4-26.85l40.37 1.3c.4 4.41 1 9.61 1.75 14.84 1.92 13.88 4.42 27.95 4.42 27.95z"
					/>
					<path
						className="-_22_National_ID_card_front_image_svg__cls-13"
						d="M1294.25 573.73c-6.57 5.61-18.79 11.2-44 7.94 1.79-10.36 3.37-17.17 3.27-22.16l39.66 4.61c.44 4.41.35 4.38 1.07 9.61z"
					/>
					<path className="-_22_National_ID_card_front_image_svg__cls-12" d="M1236 522.2s-8.88-12.1-15.8-4.94 6.42 32.1 18.52 26.67z" />
					<path
						className="-_22_National_ID_card_front_image_svg__cls-19"
						d="M1234.42 538.42a.78.78 0 01-.78-.68c-1.51-10.83-10.12-17.48-10.21-17.55a.79.79 0 011-1.26c.38.28 9.22 7.12 10.81 18.59a.78.78 0 01-.67.89z"
					/>
					<path
						className="-_22_National_ID_card_front_image_svg__cls-19"
						d="M1232 530.64a.8.8 0 01-.66-.35c-1.15-1.7-4.58-1.87-5.81-1.8a.78.78 0 01-.84-.74.81.81 0 01.74-.84c.22 0 5.34-.29 7.23 2.5a.78.78 0 01-.22 1.09.72.72 0 01-.44.14z"
					/>
					<path className="-_22_National_ID_card_front_image_svg__cls-12" d="M1316.11 522.2s8.89-12.1 15.81-4.94-6.42 32.1-18.52 26.67z" />
					<path
						className="-_22_National_ID_card_front_image_svg__cls-19"
						d="M1315.14 538.42h-.14a.79.79 0 01-.68-.89c1.6-11.47 10.44-18.31 10.82-18.59a.79.79 0 011.11.15.8.8 0 01-.16 1.11c-.08.07-8.7 6.74-10.2 17.55a.78.78 0 01-.75.67z"
					/>
					<path
						className="-_22_National_ID_card_front_image_svg__cls-19"
						d="M1317.54 530.64a.75.75 0 01-.45-.14.79.79 0 01-.21-1.09c1.89-2.79 7-2.51 7.22-2.5a.79.79 0 01.74.84.78.78 0 01-.83.74c-1.23-.07-4.67.11-5.82 1.8a.77.77 0 01-.65.35z"
					/>
					<path
						className="-_22_National_ID_card_front_image_svg__cls-12"
						d="M1282.09 471.64c-32.65 0-52.94 11-50 45.56 2.4 27.87 6.82 55.93 44.17 55.92 28.67 0 44.51-30.75 42.38-53.53-1.82-19.51-7.27-40.55-28.17-46.63a50.86 50.86 0 00-8.38-1.32z"
					/>
					<path
						className="-_22_National_ID_card_front_image_svg__cls-15"
						d="M1232.44 520.29s-8.79-21.36-5-45.29c3.12-19.6 17.86-28.65 47.24-28.58 23.69.05 35.42 4.22 37.33 12.62 0 0 11.21 4.75 14.32 16.86 5.49 21.36-8.44 49.1-8.44 49.1s6.56-9.15-4.83-14.3c-9-4.06-11.45-6.79-19.09-6.94s-16-1.8-25.42-3.07c-11.87-1.59-18.94-3-26.19-8.38.03 0-1.91 18.44-9.92 27.98z"
					/>
					<path
						className="-_22_National_ID_card_front_image_svg__cls-19"
						d="M1276.7 540.51a11.49 11.49 0 01-4.5-1.07 1.13 1.13 0 01-.67-1.18l2.29-17.6a1.13 1.13 0 012.24.29l-2.18 16.73c2.25.83 4 .75 5.42-.21a1.13 1.13 0 111.26 1.87 6.71 6.71 0 01-3.86 1.17zM1273.93 553.82a26.23 26.23 0 01-10.8-4.7 1.14 1.14 0 01-.16-1.59 1.12 1.12 0 011.59-.16c.49.39 11.6 9.24 24 1.31a1.13 1.13 0 011.22 1.9 21.59 21.59 0 01-15.85 3.24z"
					/>
				</g>
				<path d="M642.69 251.67h740.09a35.71 35.71 0 0135.71 35.71v62.48H607v-62.48a35.71 35.71 0 0135.71-35.71z" fill="#2594cc" />
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-21"
					x={1219.89}
					y={390.57}
					width={147.29}
					height={23.62}
					rx={11.81}
					ry={11.81}
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-21"
					x={1219.89}
					y={699.52}
					width={147.29}
					height={23.62}
					rx={11.81}
					ry={11.81}
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-21"
					x={1121.82}
					y={699.52}
					width={73.64}
					height={23.62}
					rx={11.81}
					ry={11.81}
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-21"
					x={656.88}
					y={452.76}
					width={145.72}
					height={26.3}
					rx={13.15}
					ry={13.15}
					transform="rotate(-180 729.74 465.915)"
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-21"
					x={656.88}
					y={545.76}
					width={163.88}
					height={26.3}
					rx={13.15}
					ry={13.15}
					transform="rotate(-180 738.82 558.915)"
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-21"
					x={841.53}
					y={545.76}
					width={97.73}
					height={26.3}
					rx={13.15}
					ry={13.15}
					transform="rotate(-180 890.4 558.915)"
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-21"
					x={656.88}
					y={498.06}
					width={246.05}
					height={26.3}
					rx={13.15}
					ry={13.15}
					transform="rotate(180 779.905 511.21)"
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-10"
					x={656.88}
					y={381.57}
					width={342.49}
					height={36}
					rx={18}
					ry={18}
					transform="rotate(-180 828.125 399.565)"
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-22"
					x={890.93}
					y={278.56}
					width={125.46}
					height={36}
					rx={18}
					ry={18}
					transform="rotate(180 953.66 296.56)"
				/>
				<rect
					className="-_22_National_ID_card_front_image_svg__cls-22"
					x={1035.88}
					y={278.56}
					width={97.51}
					height={36}
					rx={18}
					ry={18}
					transform="rotate(-180 1084.635 296.56)"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-23"
					d="M739.94 674c9.45 10.53 22.66 18.78 37.27 18.11 11.81-.54 26.95-7.7 28.74-20.81.72-5.3-1.76-10.47-7.06-12.16-5.84-1.86-12.23 1.14-17.13 4.1a81 81 0 00-29.88 32l4.57 1.93 15.85-64.71-4.91-.66a655.6 655.6 0 01-3.65 95.74c-.31 2.85 4.2 3.33 4.92.67l6.92-25.78c.84-3.11-4-4.44-4.82-1.33l-6.93 25.78 4.91.66a655.6 655.6 0 003.65-95.74c-.1-2.74-4.22-3.49-4.91-.66l-15.85 64.7c-.65 2.67 3.22 4.53 4.57 1.93A77 77 0 01776.44 673c4.09-3.27 8.8-6.67 13.79-8.4 2.64-.92 5.95-1.67 8.41.06 3.18 2.25 2.76 6.14 1.3 9.37-4.43 9.83-17.13 13.83-27.13 13-11.66-1-21.74-8.1-29.34-16.56-2.15-2.4-5.68 1.14-3.53 3.53z"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-23"
					d="M752.16 687A67.62 67.62 0 00810 668.5c2.3-2.27-1.19-5.72-3.54-3.54l-3.54 3.3c-2.1 2 .59 5.33 3 3.92a52.81 52.81 0 0026.29-43.32c.14-3-4-3.19-4.91-.67a97.46 97.46 0 001.1 68.81l3.07-3.07c-19.2-6-39.43-12.36-59.81-9.72-15.63 2-30.44 9.12-39.61 22.26-1.58 2.27 1.4 4.34 3.42 3.42a699.17 699.17 0 01127.29-43.29c3.13-.74 1.8-5.56-1.33-4.82A704.92 704.92 0 00733 705.61l3.42 3.42c21.52-30.86 64.23-19.53 93.77-10.25 1.69.53 3.79-1.29 3.08-3.08a93.12 93.12 0 01-1.09-66.18l-4.92-.66a47.61 47.61 0 01-23.82 39l3 3.93 3.56-3.29-3.57-3.5a62.33 62.33 0 01-52.94 17.25c-3.15-.48-4.5 4.33-1.33 4.82z"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-24"
					d="M1876.1 402.5c-54.71-45.78-166.57-115.85-199.32-137.44s-63 30.34-1.78 95.46c0 0-94.82 9-123.39 20.6s-63 46 6.71 67.3 130.07 15.32 130.07 15.32-121.39 48.76-150.29 70.9-25 63.66 10.61 65.56 176.57-54.09 176.57-54.09S1500 681.87 1535.21 729.48 1644.52 748 1691 712.1s76.26-6.92 82.49 22.95"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-12"
					d="M2000.06 1287.05s253.11-153 256.23-284.4c0 0-80.45-97.56-126.08-180.69s-117.56-265.89-188.34-343-136.54-164.87-191.25-210.65-169.25-112.22-199.32-137.41c-32.85-27.53-76.61-9-43.73 67.26 13.43 31.13 127.76 159.79 150.51 185 0 0 91.17 136.28 110 186.93 7.06 19 25.66 28.81 25.16 62.23-.83 55.71-28.94 137.05-62.8 142.81"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-12"
					d="M1773.45 770.79c-30.4 2.55-63.6 4.37-63.6 4.37s-249.75 4.11-303 48-41.69 72.22 8.38 82.07 172.74 26.59 227.69 61.09 120.31 94.42 193.75 127.15 137.85 155.4 137.85 155.4c4.23-3.15 30.56-33 31.07-69.47s-204.24-410.96-232.14-408.61z"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-25"
					d="M1793.22 778c-27.28-7.41-69.13-.74-108.37-2.07M1614.51 333.87s48.76 28.9 140.68 217.65"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-24"
					d="M127.43 402.5C182.14 356.72 294 286.65 326.75 265.06s63 30.34 1.78 95.46c0 0 94.82 9 123.39 20.6s63 46-6.71 67.3-130.07 15.32-130.07 15.32 121.4 48.76 150.29 70.9 25 63.66-10.61 65.56-176.57-54.09-176.57-54.09 225.27 135.76 190.07 183.37S359 748 312.5 712.1s-76.26-6.92-82.49 22.95"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-12"
					d="M3.46 1287.05s-253.1-153-256.22-284.4c0 0 80.45-97.56 126.08-180.69S-9.12 556.07 61.66 479s136.53-164.88 191.25-210.66S422.16 156.09 452.23 130.9c32.85-27.53 76.6-9 43.73 67.26-13.43 31.13-127.77 159.79-150.51 185 0 0-91.17 136.28-110 186.93-7.05 19-25.65 28.81-25.15 62.23.83 55.71 28.93 137.05 62.8 142.81"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-12"
					d="M230.08 770.79c30.4 2.55 63.6 4.37 63.6 4.37s249.75 4.11 303 48 41.69 72.22-8.38 82.07-172.75 26.59-227.7 61.09-120.31 94.42-193.75 127.15S29 1248.87 29 1248.87c-4.23-3.15-30.56-33-31.07-69.47s204.25-410.96 232.15-408.61z"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-25"
					d="M210.31 778c27.28-7.41 69.13-.74 108.37-2.07M389 333.87s-48.76 28.9-140.68 217.65"
				/>
				<path
					className="-_22_National_ID_card_front_image_svg__cls-23"
					d="M1551.78 187.94H473.42a52.51 52.51 0 00-52.51 52.51V766a52.51 52.51 0 0052.51 52.51h1078.36a52.51 52.51 0 0052.51-52.51V240.45a52.51 52.51 0 00-52.51-52.51zm-29.32 609.51H502.74V209h1019.72z"
				/>
				<path opacity={0.2} fill="#fff" d="M1522.46 797.45H724.58L1522.46 209v588.45z" />
			</g>
		</svg>
	);
}

export default Svg22NationalIDcardfrontimage;
