import * as React from 'react';
import { useRouter } from 'next/router';
import PageHeader from '../PageHeader';
import NavBar from '../NavBar';
import HeadWrapper from '../HeadWrapper';
import { DocumentContext } from '../../context/DocumentContext';
import { withFirebase } from '../Firebase';

type PageContentProps = {
	children: React.ReactNode;
	overflow?: boolean;
};
export const PageContent = ({ children, overflow }: PageContentProps) => (
	<>
		<main className="main">
			<div className="h-full max-w-7xl md:mx-auto md:px-6 lg:px-8">
				<div className="card h-full md:h-auto">
					<div className="flex h-full bg-white drop-shadow-md md:rounded-lg">{children}</div>
				</div>
			</div>
		</main>
		<style jsx>
			{`
				.main {
					//background-color: pink;
					//border: 2px solid green;

					overflow: ${overflow ? 'hidden' : 'auto'};
				}
				.card {
					// dev
					//border: 2px solid orangered;
				}
			`}
		</style>
	</>
);

type Props = {
	firebase?: Record<string, unknown>;
	title?: string;
	currentStep?: number;
	children?: React.ReactNode;
	overflow?: boolean;
};

const Layout = ({ firebase, children, title = 'bronID eKYC', currentStep, overflow }: Props) => {
	const router = useRouter();
	const { state, dispatch } = React.useContext(DocumentContext);
	const [userId, setUserId] = React.useState(null);

	const onAuthStateChange = setUser => {
		// console.log('TODO: look into this feature!');
		// console.log('TODO: look into this feature!');
		// console.log('TODO: look into this feature!');
		// @ts-ignore
		return firebase?.auth?.onAuthStateChanged(user => {
			if (user) {
				setUser(user.uid);
			} else {
				setUser(null);
			}
		});
	};
	const registerFirebaseUser = setUser => {
		const unsubscribe = onAuthStateChange(setUser);
		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	};

	React.useEffect(() => registerFirebaseUser(setUserId), []);
	// 👇👇👇 this worked and i'm testing the one above
	// firebase.auth.onAuthStateChanged(async user => {
	// 	if (user) {
	// 		setUserId(user.uid);
	// 	} else {
	// 		setUserId(null);
	// 	}
	// });

	return (
		<>
			<div className="layout">
				<HeadWrapper title={title} />
				{/* <button */}
				{/* 	className="absolute left-0 bottom-0 bg-transparent border border-red-400 text-red-400 py-1 px-2 text-xs rounded" */}
				{/* 	onClick={() => { */}
				{/* 		dispatch({ */}
				{/* 			type: Types.EraseContext, */}
				{/* 		}); */}
				{/* 		router.push('/'); */}
				{/* 		// @ts-ignore */}
				{/* 		firebase.doSignOut(); */}
				{/* 	}} */}
				{/* > */}
				{/* 	RESET! {userId} */}
				{/* </button> */}
				{/* <pre>stateDocs: {JSON.stringify(state, null, 2)}</pre> */}
				<NavBar />

				<PageHeader currentStep={currentStep} />
				<PageContent overflow={overflow}>{children}</PageContent>
			</div>
			<style jsx>
				{`
					.layout {
						// dev
						//background-color: #f8ecc2;
						//background-color: orangered;
						// highlight child elements
						//padding: 5px;

						// used
						display: grid;
						grid-template-areas:
							'nav'
							'progress'
							'main';
						grid-template-rows: fit-content(100px) fit-content(100px) auto;
					}
				`}
			</style>
		</>
	);
};

export default withFirebase(Layout);
