import React from 'react';
import Debugger from './Debugger';

type Props = {
	// title?: string;
	// children?: React.ReactNode;
};

const showDebugger = false;

const NavBar: React.FunctionComponent<Props> = () => (
	<nav className="row header border-b border-gray-200 bg-white">
		<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
			<div className="flex h-16 justify-between">
				<div className="flex">
					<div className="flex shrink-0 items-center">
						<img className="block h-8 lg:hidden" src="/img/logos/bronID-logo-text-only.svg" alt="Workflow logo" />
						<img className="hidden h-8 lg:block" src="/img/logos/bronID-logo-text-only.svg" alt="Workflow logo" />
					</div>
				</div>
				<div className="hidden text-lg leading-tight text-gray-900 sm:ml-6 sm:flex sm:items-center">Verification of Identity</div>
				<div className="-mr-2 flex items-center text-lg leading-tight text-gray-900 sm:hidden">Verification of Identity</div>
			</div>
		</div>

		{showDebugger && <Debugger />}
	</nav>
);

export default NavBar;
