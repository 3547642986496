import * as React from 'react';

function Svg23BirthCertificatefrontimage(props) {
	return (
		<svg
			id="-_23_Birth_Certificate_front_image_svg__\u0421\u043B\u043E\u0439_1"
			data-name="\u0421\u043B\u043E\u0439 1"
			viewBox="0 0 1989.16 1315.53"
			{...props}
		>
			<defs>
				<clipPath id="-_23_Birth_Certificate_front_image_svg__clip-path">
					<path fill="none" d="M0 28.48h1989.16v1287.05H0z" />
				</clipPath>
				<style>
					{
						'.-_23_Birth_Certificate_front_image_svg__cls-23,.-_23_Birth_Certificate_front_image_svg__cls-35{fill:none}.-_23_Birth_Certificate_front_image_svg__cls-34{fill:#ffca27}.-_23_Birth_Certificate_front_image_svg__cls-35{stroke:#32384f;stroke-linejoin:round}.-_23_Birth_Certificate_front_image_svg__cls-23,.-_23_Birth_Certificate_front_image_svg__cls-35{stroke-linecap:round}.-_23_Birth_Certificate_front_image_svg__cls-9{fill:#008589}.-_23_Birth_Certificate_front_image_svg__cls-10{fill:#00a488}.-_23_Birth_Certificate_front_image_svg__cls-14{fill:#00bfa5}.-_23_Birth_Certificate_front_image_svg__cls-23{stroke-miterlimit:10}.-_23_Birth_Certificate_front_image_svg__cls-16{fill:#00a457}.-_23_Birth_Certificate_front_image_svg__cls-20{fill:#8fafe2;opacity:.25}.-_23_Birth_Certificate_front_image_svg__cls-21{fill:#d33f11}.-_23_Birth_Certificate_front_image_svg__cls-23{stroke:#ff977b;stroke-width:24px}.-_23_Birth_Certificate_front_image_svg__cls-24{fill:#e9a64f}.-_23_Birth_Certificate_front_image_svg__cls-28{fill:#9f8867}.-_23_Birth_Certificate_front_image_svg__cls-29{fill:#847053}.-_23_Birth_Certificate_front_image_svg__cls-33{fill:#f7b614}.-_23_Birth_Certificate_front_image_svg__cls-35{stroke-width:14.03px}'
					}
				</style>
			</defs>
			<g clipPath="url(#-_23_Birth_Certificate_front_image_svg__clip-path)">
				<path
					d="M732.59 1026.2s356.61 117.49 304-202.39c-40.73-247.61-193.91-236-202.13-396.38C821.58 176 383.09 94.48 413.83 459.9c30.82 366.25-284.49 335.13-95 540.59 90.67 98.31 193.71-11.21 413.76 25.71z"
					opacity={0.07}
					fill="#1188f7"
				/>
				<path
					d="M1226.37 464.66s254.2-305.48-42.37-436.59C954.36-73.41 882.12 136.14 684.57 93.21c-246-53.46-501.41 235-178.05 409.37 295.07 159.14 173.23 508.85 542.16 375.12 120.84-43.81 20.24-215.52 177.69-413.04z"
					opacity={0.11}
					fill="#ffca27"
				/>
				<path
					d="M995.24 1013.7s26.23 216.61 228.82 164.34c204.39-52.74 117.3-227 272.9-308.68 266.54-139.84 97.79-494.59-189.14-375.44S953.21 471.18 865.2 621.81C766.83 790.17 983.52 815.7 995.24 1013.7z"
					opacity={0.09}
					fill="#4dbc76"
				/>
				<path
					strokeWidth={17.73}
					strokeLinecap="round"
					stroke="#32384f"
					strokeLinejoin="round"
					fill="none"
					d="M1274.4 475.08l-36.65-162.58"
				/>
				<rect x={726.91} y={204.01} width={558.02} height={655.2} rx={35.71} ry={35.71} fill="#fff" />
				<path
					d="M1225.69 719.92s-20.58-22.8-78-21.95-102-18.26-147.74-5.19-86.79-7.12-116.11.92-145.49-2.46-141.39 46.21-.51 100-.51 100l351.83-2.64 112-44.59 38.7-47.57z"
					fill="#4dbc76"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-9"
					d="M858.49 776s20.38-45 59.88-28.1c17.77-24.9 45.5-10 51 3.61 38.18-45.72 56.85 3.22 56.85 3.22 22.54-31.3 75.79 5.71 75.79 5.71l-47.89 79.48-213.68-2.72z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-10"
					d="M1144.83 759.29s-34.39-50.36-10.28-102.7c12 14.27 16.22 65.83 16.22 65.83s-7.5-57.43 12.18-79.91c13.21 23.14 2.8 79.1 2.8 79.1s6.4-36.2 20.43-52.9c2.69 10.75 0 34.35-11.38 55.09 9.63-14.14 37.44-34.65 37.44-34.65s-13.88 36.85-48.53 66.78-18.88 3.36-18.88 3.36z"
				/>
				<path
					d="M1053.13 813.2s1-49.39 44-49.39c6.53-29.89 37.89-27.11 48.27-16.73 17.1-57.05 53.53-19.42 53.53-19.42 8.4-37.63 71.91-24.57 71.91-24.57v150.19h-209.61z"
					fill="#ffe590"
				/>
				<path
					d="M1053.13 813.2s1-49.39 44-49.39c6.53-29.89 37.89-27.11 48.27-16.73 17.1-57.05 53.53-19.42 53.53-19.42 8.4-37.63 71.91-24.57 71.91-24.57v150.19h-209.61z"
					opacity={0.5}
					fill="#00a488"
				/>
				<path
					d="M739.58 750s3-35.06 33.52-4.78c25.7-16.6 46 7.46 46 22.14 52.34-28.42 51.66 24 51.66 24 32.49-20.78 78.71 48.81 78.71 48.81l-198.46 9.31-12.64-73.81z"
					fill="#00a400"
					opacity={0.5}
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-10"
					d="M902.11 838.2s-73.87-9.27-102.25-73.65c22.75.59 73.34 38.41 73.34 38.41s-58.6-39.59-63.2-75.77c31.84 6.63 74.6 61.4 74.6 61.4s-27.92-35.12-31.86-61.47c12 6.25 31.38 27.84 41.2 55-5.15-20.24-1.42-62.27-1.42-62.27s22.48 42.5 21.83 98.4-12.24 19.95-12.24 19.95z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-14"
					d="M902.11 838.2c-2.05-1.52-37.4-13.52-41.74-42.69 20.09 3.52 32.15 22 32.15 22s-16.59-26.67-12.6-43.73c13.84-2.06 28.07 32 28.07 32s-8.33-34.14-.43-44.81c13 12.47 22.1 46.36 22.1 46.36s8.59-31.64 20.81-38.88c8 18.88-3.21 67.71-3.21 67.71zM1158.67 748.08c5.83-1 9.09 38.58-27.15 44-3.16-11-2.47-38.84 27.15-44zM1170.4 797.67c2.42 2.63-16.67 17.27-31.31.6 4.57-5.22 18.97-13.99 31.31-.6zM786.5 750.85c3.58 0 1.42 24-20.76 23.45-.74-6.9 2.55-23.44 20.76-23.45zM802.31 817.3c1.2 3.37-22.1 9.38-29-11.69 6.2-3.01 22.88-5.45 29 11.69zM804.2 789.83c.78 2.2-14.39 6.11-18.92-7.61 4.07-1.96 14.93-3.55 18.92 7.61zM1207.76 766.32c2.42 2.64-16.67 17.28-31.31.6 4.55-5.21 18.97-13.99 31.31-.6z"
				/>
				<rect
					x={726.91}
					y={204.01}
					width={558.02}
					height={655.2}
					rx={35.71}
					ry={35.71}
					strokeMiterlimit={10}
					stroke="#624de6"
					strokeWidth={37}
					fill="none"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-9"
					d="M939.35 722.18s10-22.06 29.35-13.77c8.7-12.21 22.29-4.91 25 1.77 18.71-22.41 27.86 1.57 27.86 1.57 11-15.33 37.13 2.8 37.13 2.8l-24.77 44.78-98.62-17.54z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-10"
					d="M1079.67 714s-16.85-24.68-5-50.33c5.87 7 7.95 32.26 7.95 32.26s-3.68-28.14 6-39.16c6.47 11.34 1.37 38.76 1.37 38.76s3.13-17.73 10-25.92c1.32 5.27 0 16.83-5.58 27 4.72-6.93 18.35-17 18.35-17a90.52 90.52 0 01-23.78 32.72c-17.05 14.67-9.31 1.67-9.31 1.67zM960.73 752.65s-36.2-4.55-50.11-36.1c11.15.29 35.94 18.82 35.94 18.82s-28.72-19.39-31-37.12c15.6 3.25 36.56 30.09 36.56 30.09s-13.69-17.22-15.61-30.13c5.88 3.06 15.37 13.64 20.18 26.94-2.52-9.92-.69-30.51-.69-30.51a110.58 110.58 0 0110.7 48.22c-.31 27.39-5.97 9.79-5.97 9.79z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-14"
					d="M958.25 760.7c-1.25-.18-16.25 5.84-24.85-5.7 9.48-3.15 19 2 19 2s-13.4-7.65-15.6-15.93c5.49-4.1 19.55 7.31 19.55 7.31s-11.52-12.8-10.59-19.24c8.52 2.36 20.3 14.88 20.3 14.88s-3.63-15.65 0-21.61c7.82 6.3 14.33 30 14.33 30z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-16"
					d="M992.85 759.56c-1.66-.66-21.84-2-30-20.91 14.18-1.24 25.65 9.1 25.65 9.1s-16-15-16.36-27.23c9-3.88 24.69 16.56 24.69 16.56s-11.76-21.53-8.35-30.14c11 6.06 23.25 27.29 23.25 27.29s.1-22.88 7-30c8.77 11.29 10 46.24 10 46.24z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-10"
					d="M1031.71 751.12c-.68-.81-8.81-10.53-7.76-22.64 7.72 3.33 10.78 11.92 10.78 11.92s-4.1-12.28-.87-18.74c5.75.5 8.19 15.53 8.19 15.53s-.08-14.48 4.11-18c4 6.24 4.43 20.7 4.43 20.7s6.47-11.87 12.06-13.6c1.39 8.33-7.76 26.84-7.76 26.84z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-14"
					d="M1086.45 708.48c2.85-.49 4.45 18.91-13.31 21.57-1.54-5.4-1.21-19.05 13.31-21.57zM1092.2 732.78c1.18 1.29-8.17 8.47-15.35.3 2.25-2.56 9.3-6.86 15.35-.3zM904.07 709.84c1.76 0 .7 11.74-10.17 11.49-.36-3.33 1.25-11.48 10.17-11.49zM911.82 742.4c.59 1.66-10.82 4.6-14.23-5.73 3.06-1.47 11.24-2.67 14.23 5.73zM912.75 728.94c.38 1.08-7 3-9.27-3.73 1.99-.96 7.32-1.74 9.27 3.73zM1110.5 717.42c1.19 1.29-8.16 8.47-15.34.3 2.24-2.56 9.3-6.86 15.34-.3z"
				/>
				<rect
					x={838.25}
					y={393.94}
					width={339.18}
					height={314.54}
					rx={22.67}
					ry={22.67}
					stroke="#e2e1ff"
					strokeWidth={23}
					strokeMiterlimit={10}
					fill="#fff"
				/>
				<circle cx={891.78} cy={663.96} r={20.43} stroke="#ffca27" strokeWidth={14} opacity={0.55} strokeMiterlimit={10} fill="none" />
				<rect x={897.29} y={440.01} width={216.96} height={28.73} rx={13.26} ry={13.26} fill="#1188f7" />
				<rect
					className="-_23_Birth_Certificate_front_image_svg__cls-20"
					x={878.94}
					y={492.82}
					width={133.19}
					height={28.73}
					rx={13.26}
					ry={13.26}
				/>
				<rect
					className="-_23_Birth_Certificate_front_image_svg__cls-20"
					x={891.08}
					y={544.7}
					width={226.72}
					height={28.73}
					rx={13.26}
					ry={13.26}
				/>
				<rect
					className="-_23_Birth_Certificate_front_image_svg__cls-20"
					x={944.37}
					y={600.11}
					width={131.73}
					height={28.73}
					rx={13.26}
					ry={13.26}
				/>
				<rect
					className="-_23_Birth_Certificate_front_image_svg__cls-20"
					x={1036.68}
					y={492.82}
					width={99.88}
					height={28.73}
					rx={13.26}
					ry={13.26}
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-16"
					d="M952.94 833.24c-2.09-1.81-31-14.13-33.34-46 21.45 5.44 33 26.47 33 26.47s-15.78-30.2-10.08-48.33c15.14-1.1 27.76 36.95 27.76 36.95s-6.24-37.61 3.18-48.5c13.08 14.55 20.13 52 20.13 52s11.88-33.53 25.67-40.36c7.08 21.06-4 69.14-4 69.14z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-10"
					d="M1023.68 834c-1.38-1.64-18-21.48-15.84-46.19 15.75 6.8 22 24.32 22 24.32s-8.36-25.06-1.77-38.24c11.72 1 16.71 31.69 16.71 31.69s-.16-29.55 8.39-36.74c8.23 12.75 9 42.25 9 42.25s13.21-24.21 24.61-27.75c2.84 17-13.14 51.23-13.14 51.23z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-21"
					d="M845.76 295.21s-16.54 14.07-39.08 19.93-20.72 8.08-19.41 9.25S804.2 365 804.2 365l58.63-22.8s-13.83-36.31-17.07-46.99zM1169.92 301.42s16.54 14.07 39.08 19.93 20.71 8.08 19.41 9.25-16.94 40.64-16.94 40.64l-58.62-22.79s13.81-36.35 17.07-47.03z"
				/>
				<path
					d="M797.69 367.9l-10.42-43.51s21.62-.52 95.88-24 95.36-24.72 145.65-23.39 153.2 41.69 173.26 46.38 26.58 3.64 26.58 7.29-5.73 34.13-5.73 37.26-6 4.26-18.24 3.74-106.83-40.22-140.7-47-89.63-11.46-140.7 8.6-122.71 39.58-125.58 34.63z"
					fill="#ff703e"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-23"
					d="M866.89 329.8s21.75-3.37 67.38-21.43M987.43 298.32s38.7-8.21 96.16 9.78c69.38 21.71 100.86 33.62 100.86 33.62"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-24"
					d="M1034.29 738c-16.87-2.83-33.73 9-36.24 31.33s21.72 43.27 19.09 49.55-43.48-.53-37.34 15c0 0 44.25-4.63 46-7.58s-6.47-34.38-5.58-37 14.71-2.73 30.57-5.64 11.95-40.88-16.5-45.66zM973.12 714.49c-5.48 3.26-9.94 38-9.27 46.42s13 18.16 16.67 11.93c0 0-10.84-7.76-12-10.26S984.9 732.7 984.88 726s-5.88-15-11.76-11.51z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-24"
					d="M983.76 682.38c-.71 10 1.45 20.49 8 25.5s27.17-5.67 54.54 13c38.07 26 30.54 70.13 41.39 88.71s38 19.41 38 19.41-15.11 11.21-37 .07-33.11-34.39-36.73-37-31.28-5-60.66-32.06-22.8-75.55-22.8-75.55z"
				/>
				<path
					d="M1054.75 785.36c-7.93-6.15-39.66-19-60.26-43.12s-23.24-58.11-23.24-58.11l-2.88 1.87c-.81 8.3-3.39 49.82 23 74.08 29.38 27 57.05 29.47 60.66 32.06s14.89 25.83 36.73 37 37-.07 37-.07c-40.08 14.93-63.09-37.57-71.01-43.71z"
					fill="#ffdf6b"
					opacity={0.38}
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-24"
					d="M1035.21 739.7c-17.1-.22-32 14-31 36.51s25 39.2 22.61 45.56-39 .25-32.37 15.53c0 0 44.08-6 45.79-9s-11.5-33-11-35.75 14.12-5 29.35-10.26 5.46-42.22-23.38-42.59zM984.19 725.43c-5.47 3.26-9.93 38-9.26 46.42S988 790 991.6 783.78c0 0-10.84-7.75-12-10.26S996 743.64 996 736.9s-5.95-14.96-11.81-11.47z"
				/>
				<path d="M968.93 668.85s.89-7.76 5-12.85 12.81-7.6 13.32-5.93.59 7.51-4.16 13.15a29.26 29.26 0 01-10.89 7.91z" fill="#dd864f" />
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-24"
					d="M975.12 667.63c-4.78-2.93-11.51-3.35-14.45 1.55s-4.8 8.56-4.8 8.56-16.23-2.36-18.09 1.31.3 11.75 5.36 15.17 29.52 4.43 37.08-.71 2.98-20.91-5.1-25.88z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-24"
					d="M972.4 670.09s3-7.22 8.31-11 14.4-3.8 14.44-2.05-1.5 7.38-7.61 11.5a29.28 29.28 0 01-12.63 4.64z"
				/>
				<path d="M937.25 684c-1.72-.6-1.35-5.09.44-6.69a6.09 6.09 0 017-.3c1.69 1.32-2.83 8.6-7.44 6.99z" fill="#c68232" />
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-24"
					d="M995.15 657c-1.52-.95-10.7 2.51-13.72 6.25a36.71 36.71 0 00-4.43 6.77s9.26-1.57 13.34-4.64 4.81-8.38 4.81-8.38z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-28"
					d="M1014.4 782.45a6.9 6.9 0 01-4.12-5.7 8.48 8.48 0 013.85-7.87 9.13 9.13 0 018.82-.39 6.16 6.16 0 011.7 1.2c1.66 1.73 1.68 4.54.68 6.71-1.78 3.84-6.44 7.78-10.93 6.05z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-29"
					d="M1015.72 776a2.72 2.72 0 00.64 2.35 3 3 0 003 .38c2.4-.78 4.26-4 1.79-5.9-2.22-1.69-5.15.97-5.43 3.17z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-28"
					d="M1053.62 780.39a6.9 6.9 0 002.51-6.57 8.49 8.49 0 00-5.75-6.6 9.17 9.17 0 00-8.62 1.89 6.25 6.25 0 00-1.34 1.61c-1.15 2.09-.45 4.82 1.08 6.65 2.7 3.25 8.23 5.86 12.12 3.02z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-29"
					d="M1050.68 774.51a2.75 2.75 0 010 2.44 3 3 0 01-2.77 1.14c-2.52-.14-5.15-2.76-3.26-5.24 1.67-2.21 5.15-.39 6.03 1.66zM1058.58 821a8.16 8.16 0 01-2.62 6.24c-2.66 2.39-5.2.14-5.09-2.83.08-2.19 1.06-5.3 2.87-6.7 2.17-1.68 4.29.26 4.74 2.5a5.33 5.33 0 01.1.79zM1017.26 812a5.16 5.16 0 00-2.51 4.66 13.13 13.13 0 001.08 4.48c.15.41 1.7 4.35 1.66 4.37a22.39 22.39 0 00-6.29 4.45 1 1 0 00.42 1.75c1.3.47 3.34-.24 4.58-.7a40 40 0 009.18-4.39c3.82-2.73 4.38-7.46 2.89-11.72-1-2.74-3.59-3.77-6.29-4a8.11 8.11 0 00-4.72 1.1z"
				/>
				<path
					d="M1018.81 816.37a18.57 18.57 0 002.55 7 15.8 15.8 0 008.89 6.11 34.47 34.47 0 0010.91 1 19.42 19.42 0 007.23-1.3c4.53-2 7.2-7 7.71-11.92s-.82-9.86-2.41-14.56q-1-3-2.16-5.88a13.1 13.1 0 00-2.14-3.94c-1.79-2-4.59-2.8-7.25-3.29a56.67 56.67 0 00-10.4-.94c-2.16 0-5.71-.4-7.61.9s-3 5-3.75 7.14a38.89 38.89 0 00-2 9.25 37.75 37.75 0 00.43 10.43z"
					fill="#967e5d"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-29"
					d="M1043.62 803.25l-.43.33c-1.42 1-3.09 1.7-4.49 2.77-.8.61-1.13 1.8 0 2.33a2.68 2.68 0 001.06.22 9.84 9.84 0 009.85-6.54 4.88 4.88 0 00.26-2.36c-.3-1.62-2.23-2.82-3.42-1.35-.46.57-.45 1.41-.74 2.07a7.08 7.08 0 01-2.09 2.53zM1040.47 815.1a5.13 5.13 0 00-2.51 4.66 13 13 0 001.07 4.47c.15.41 1.7 4.36 1.66 4.38a22.19 22.19 0 00-6.29 4.45 1 1 0 00.42 1.75c1.31.47 3.34-.24 4.58-.71a40.2 40.2 0 009.19-4.38c3.81-2.73 4.37-7.47 2.88-11.72-1-2.74-3.59-3.77-6.29-4a8.06 8.06 0 00-4.71 1.1z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-28"
					d="M1026.05 774.61a18.09 18.09 0 00-7.28 4.13 9.08 9.08 0 00-2.74 5.62c-.56 8.21 8.81 11.82 15.45 12.06a35.34 35.34 0 0013-2c4.31-1.54 9.38-5 8.24-10.26s-6.68-8.81-11.57-10.15a26.72 26.72 0 00-15.1.6z"
				/>
				<path d="M1035.47 788c0 1.92-2.1 3.47-4.68 3.47s-4.69-1.55-4.69-3.47 2.1-3.47 4.69-3.47 4.68 1.54 4.68 3.47z" fill="#4b4034" />
				<path
					d="M1021.89 807a12.39 12.39 0 001.1 12.56c3.61 5.2 10.41 5.74 12.89-.69 1.36-3.53 2.2-8.33.7-11.94-2.52-6.05-11.88-5.29-14.69.07z"
					fill="#b59c7f"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-29"
					d="M1017.1 797.76a4.77 4.77 0 00-.49 2.84 10.56 10.56 0 003.61 6.24 2.7 2.7 0 002 1 1.21 1.21 0 001-.66 1.45 1.45 0 00-.12-1.2c-.71-1.44-3.7-5.37-3.66-6.16l1.43-4.53a5.55 5.55 0 00-2.42 1 4 4 0 00-1.35 1.47z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-33"
					d="M1879.89 426.88c-54.72-45.78-166.58-115.88-199.32-137.44s-63 30.34-1.78 95.46c0 0-94.83 9-123.4 20.6s-63 46 6.71 67.3 130.08 15.32 130.08 15.32-121.4 48.75-150.3 70.9-25 63.66 10.62 65.55 176.57-54.08 176.57-54.08S1503.79 706.25 1539 753.86s109.32 18.49 155.82-17.39 76.27-6.91 82.49 23"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-34"
					d="M2003.85 1311.43s253.11-153 256.23-284.4c0 0-80.46-97.56-126.08-180.69s-117.56-265.89-188.35-343-136.53-164.84-191.24-210.62-169.26-112.25-199.33-137.44c-32.84-27.53-76.6-8.95-43.73 67.26 13.43 31.13 127.77 159.79 150.51 185 0 0 91.17 136.28 110 186.93 7.05 19 25.65 28.81 25.15 62.23-.83 55.71-28.93 137-62.8 142.81"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-34"
					d="M1777.23 795.16c-30.4 2.56-63.6 4.37-63.6 4.37s-249.75 4.12-303 48-41.63 72.23 8.37 82.08 172.75 26.59 227.69 61.09 120.31 94.42 193.75 127.15 148.7 165.36 148.7 165.36c4.23-3.15 19.72-43 20.22-79.43s-204.23-410.96-232.13-408.62z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-35"
					d="M1797 802.33c-27.28-7.41-69.13-.74-108.37-2.07M1618.29 358.24s48.76 28.91 140.71 217.66"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-33"
					d="M131.21 426.88C185.92 381.1 297.78 311 330.53 289.44s63 30.34 1.78 95.46c0 0 94.82 9 123.39 20.6s63 46-6.71 67.3-130.07 15.32-130.07 15.32 121.4 48.75 150.29 70.9 25 63.66-10.61 65.55S282 570.49 282 570.49s225.3 135.76 190.11 183.37-109.32 18.49-155.82-17.39-76.27-6.91-82.49 23"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-34"
					d="M7.25 1311.43S-245.86 1158.45-249 1027c0 0 80.45-97.56 126.08-180.69s117.56-265.89 188.34-343S202 338.5 256.69 292.72 425.94 180.47 456 155.28c32.85-27.53 76.61-8.95 43.73 67.26-13.43 31.13-127.76 159.79-150.51 185 0 0-91.17 136.28-110 186.93-7.06 19-25.66 28.81-25.16 62.23.84 55.71 28.94 137 62.8 142.81"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-34"
					d="M233.86 795.16c30.4 2.56 63.6 4.37 63.6 4.37s249.75 4.12 303 48 41.69 72.22-8.38 82.07-172.75 26.6-227.7 61.1-120.31 94.42-193.74 127.15S28.55 1270.1 28.55 1270.1c-4.23-3.16-26.33-29.85-26.84-66.32S206 792.82 233.86 795.16z"
				/>
				<path
					className="-_23_Birth_Certificate_front_image_svg__cls-35"
					d="M214.09 802.33c27.28-7.41 69.13-.74 108.37-2.07M392.81 358.24S344 387.15 252.12 575.9"
				/>
				<path
					d="M1555.57 212.32H477.2a52.51 52.51 0 00-52.51 52.51v525.55a52.51 52.51 0 0052.51 52.51h1078.37a52.51 52.51 0 0052.5-52.51V264.83a52.51 52.51 0 00-52.5-52.51zm-29.33 609.51H506.52V233.37h1019.72z"
					fill="#32384f"
				/>
				<path opacity={0.2} fill="#fff" d="M1526.24 821.83H728.37l797.87-588.46v588.46z" />
			</g>
		</svg>
	);
}

export default Svg23BirthCertificatefrontimage;
