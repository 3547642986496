import * as React from 'react';

function Svg21Medicarefrontimage(props) {
	return (
		<svg
			id="-_21_Medicare_front_image_svg__\u0421\u043B\u043E\u0439_1"
			data-name="\u0421\u043B\u043E\u0439 1"
			viewBox="0 0 1989.16 1287.05"
			{...props}
		>
			<defs>
				<clipPath id="-_21_Medicare_front_image_svg__clip-path">
					<path className="-_21_Medicare_front_image_svg__cls-1" d="M0 0h1989.16v1287.05H0z" />
				</clipPath>
				<clipPath id="-_21_Medicare_front_image_svg__clip-path-2">
					<rect
						className="-_21_Medicare_front_image_svg__cls-1"
						x={604.51}
						y={249.67}
						width={811.51}
						height={500.8}
						rx={35.71}
						ry={35.71}
					/>
				</clipPath>
				<style>
					{
						'.-_21_Medicare_front_image_svg__cls-1,.-_21_Medicare_front_image_svg__cls-12,.-_21_Medicare_front_image_svg__cls-15{fill:none}.-_21_Medicare_front_image_svg__cls-11{fill:#ffca27}.-_21_Medicare_front_image_svg__cls-10,.-_21_Medicare_front_image_svg__cls-13{fill:#4dbc76}.-_21_Medicare_front_image_svg__cls-12,.-_21_Medicare_front_image_svg__cls-15{stroke:#32384f;stroke-linecap:round;stroke-linejoin:round}.-_21_Medicare_front_image_svg__cls-12{stroke-width:14.89px}.-_21_Medicare_front_image_svg__cls-13{stroke:#4dbc76;stroke-miterlimit:10;stroke-width:13px}.-_21_Medicare_front_image_svg__cls-14{fill:#f7b614}.-_21_Medicare_front_image_svg__cls-15{stroke-width:14.03px}'
					}
				</style>
			</defs>
			<g clipPath="url(#-_21_Medicare_front_image_svg__clip-path)">
				<path
					d="M999.24 697.83c81.7-69 166.25-193.43 96.08-293.57C1043.19 329.85 950.57 296 861.3 316c-35.95 8-70.8 21.43-107.14 27.48-45.11 7.51-81.25-1.87-122.87-16.23-71.75-24.75-158.71-12.69-220.59 36.69-64 51.08-76.12 130.34-33.91 196.71 27.08 42.59 69.94 68.84 102.08 106.71 24.69 29.08 42.85 62.82 54.71 99.39 17.15 52.92 16 118.39 48.34 164.77 20.11 28.85 52.69 42.6 86.52 49.18 38.38 7.46 96.9 17.41 131.87-7.72 35.16-25.27 52.48-74.88 72.16-111.53 33.02-61.54 73.44-117.9 126.77-163.62z"
					fill="#1188f7"
					opacity={0.07}
				/>
				<path
					d="M1476.14 451.92s6.5-259.06-240.58-232.54c-249.27 26.75-135.41 265.16-332.33 334.29-337.31 118.4-241.67 545.78 116 455.19s412.78 87.83 542.16-74C1706 754 1455.8 686.61 1476.14 451.92z"
					opacity={0.11}
					fill="#ffca27"
				/>
				<path
					d="M718.32 431.75S654.35 634 854.68 669.28c202.11 35.6 166.74-177.76 337.3-187.38 292.17-16.48 314-374.31 12.16-384.34S899.48-65.29 761 32.59C606.14 142 787.63 251.81 718.32 431.75z"
					opacity={0.12}
					fill="#4dbc76"
				/>
				<rect x={604.51} y={249.67} width={811.51} height={500.8} rx={35.71} ry={35.71} fill="#fff" />
				<rect x={604.51} y={249.67} width={811.51} height={500.8} rx={35.71} ry={35.71} fill="#82f9a3" opacity={0.58} />
				<g clipPath="url(#-_21_Medicare_front_image_svg__clip-path-2)" opacity={0.29}>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={566.64}
						y={259.88}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 646.985 272.125)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={762.64}
						y={259.88}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 842.98 272.125)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={958.64}
						y={259.88}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1038.98 272.125)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1154.63}
						y={259.88}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1234.975 272.125)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1350.63}
						y={259.88}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1430.97 272.125)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={468.84}
						y={312.04}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 549.185 324.285)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={664.84}
						y={312.04}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 745.18 324.285)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={860.83}
						y={312.04}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 941.175 324.285)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1056.83}
						y={312.04}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1137.17 324.285)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1252.83}
						y={312.04}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1333.165 324.285)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={566.64}
						y={365.56}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 646.985 377.805)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={762.64}
						y={365.56}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 842.98 377.805)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={958.64}
						y={365.56}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1038.98 377.805)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1154.63}
						y={365.56}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1234.975 377.805)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1350.63}
						y={365.56}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1430.97 377.805)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={468.84}
						y={417.72}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 549.185 429.965)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={664.84}
						y={417.72}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 745.18 429.965)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={860.83}
						y={417.72}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 941.175 429.965)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1056.83}
						y={417.72}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1137.17 429.965)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1252.83}
						y={417.72}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1333.165 429.965)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={566.64}
						y={471.24}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 646.985 483.485)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={762.64}
						y={471.24}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 842.98 483.485)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={958.64}
						y={471.24}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1038.98 483.485)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1154.63}
						y={471.24}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1234.975 483.485)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1350.63}
						y={471.24}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1430.97 483.485)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={468.84}
						y={523.4}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 549.185 535.645)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={664.84}
						y={523.4}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 745.18 535.645)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={860.83}
						y={523.4}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 941.175 535.645)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1056.83}
						y={523.4}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1137.17 535.645)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1252.83}
						y={523.4}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1333.165 535.645)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={566.64}
						y={576.92}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 646.985 589.165)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={762.64}
						y={576.92}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 842.98 589.165)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={958.64}
						y={576.92}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1038.98 589.165)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1154.63}
						y={576.92}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1234.975 589.165)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1350.63}
						y={576.92}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1430.97 589.165)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={468.84}
						y={629.08}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 549.185 641.325)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={664.84}
						y={629.08}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 745.18 641.325)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={860.83}
						y={629.08}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 941.175 641.325)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1056.83}
						y={629.08}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1137.17 641.325)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1252.83}
						y={629.08}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1333.165 641.325)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={566.64}
						y={682.6}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 646.985 694.845)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={762.64}
						y={682.6}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 842.98 694.845)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={958.64}
						y={682.6}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1038.98 694.845)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1154.63}
						y={682.6}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1234.975 694.845)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1350.63}
						y={682.6}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1430.97 694.845)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={468.84}
						y={734.76}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(180 549.185 747.005)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={664.84}
						y={734.76}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(180 745.18 747.005)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={860.83}
						y={734.76}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 941.175 747.005)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1056.83}
						y={734.76}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1137.17 747.005)"
					/>
					<rect
						className="-_21_Medicare_front_image_svg__cls-10"
						x={1252.83}
						y={734.76}
						width={160.68}
						height={24.49}
						rx={11.99}
						ry={11.99}
						transform="rotate(-180 1333.165 747.005)"
					/>
				</g>
				<path
					className="-_21_Medicare_front_image_svg__cls-10"
					d="M1114 305.8l-24 45.31h210.78a45.63 45.63 0 0038.33-20.87l31.46-48.71h-216.29A45.63 45.63 0 001114 305.8z"
				/>
				<path
					className="-_21_Medicare_front_image_svg__cls-11"
					d="M1139.08 332.56h156.18c5.37 0 12.75-4.35 16.48-9.72l9.07-13c3.74-5.37 2.41-9.72-3-9.72h-156.13c-5.37 0-12.75 4.35-16.49 9.72l-9.07 13c-3.73 5.37-2.41 9.72 2.96 9.72z"
				/>
				<rect
					className="-_21_Medicare_front_image_svg__cls-12"
					x={695.97}
					y={407.26}
					width={30.69}
					height={56.72}
					rx={15.34}
					ry={15.34}
					transform="rotate(-180 711.32 435.62)"
				/>
				<rect
					className="-_21_Medicare_front_image_svg__cls-12"
					x={749.45}
					y={407.26}
					width={30.69}
					height={56.72}
					rx={15.34}
					ry={15.34}
					transform="rotate(-180 764.795 435.62)"
				/>
				<rect
					className="-_21_Medicare_front_image_svg__cls-12"
					x={802.93}
					y={407.26}
					width={30.69}
					height={56.72}
					rx={15.34}
					ry={15.34}
					transform="rotate(-180 818.27 435.62)"
				/>
				<rect
					className="-_21_Medicare_front_image_svg__cls-12"
					x={856.4}
					y={407.26}
					width={30.69}
					height={56.72}
					rx={15.34}
					ry={15.34}
					transform="rotate(-180 871.745 435.62)"
				/>
				<rect
					className="-_21_Medicare_front_image_svg__cls-12"
					x={954.34}
					y={407.26}
					width={30.69}
					height={56.72}
					rx={15.34}
					ry={15.34}
					transform="rotate(-180 969.685 435.62)"
				/>
				<rect
					className="-_21_Medicare_front_image_svg__cls-12"
					x={1007.82}
					y={407.26}
					width={30.69}
					height={56.72}
					rx={15.34}
					ry={15.34}
					transform="rotate(-180 1023.16 435.62)"
				/>
				<rect
					className="-_21_Medicare_front_image_svg__cls-12"
					x={1061.29}
					y={407.26}
					width={30.69}
					height={56.72}
					rx={15.34}
					ry={15.34}
					transform="rotate(180 1076.635 435.62)"
				/>
				<rect
					className="-_21_Medicare_front_image_svg__cls-12"
					x={1114.77}
					y={407.26}
					width={30.69}
					height={56.72}
					rx={15.34}
					ry={15.34}
					transform="rotate(-180 1130.11 435.62)"
				/>
				<rect
					className="-_21_Medicare_front_image_svg__cls-12"
					x={1168.24}
					y={407.26}
					width={30.69}
					height={56.72}
					rx={15.34}
					ry={15.34}
					transform="rotate(-180 1183.585 435.62)"
				/>
				<rect className="-_21_Medicare_front_image_svg__cls-13" x={678.93} y={527.47} width={87.73} height={20.12} rx={10.06} ry={10.06} />
				<rect className="-_21_Medicare_front_image_svg__cls-13" x={906.24} y={527.47} width={169.47} height={20.12} rx={10.06} ry={10.06} />
				<rect className="-_21_Medicare_front_image_svg__cls-13" x={678.93} y={572.01} width={137.63} height={20.12} rx={10.06} ry={10.06} />
				<rect className="-_21_Medicare_front_image_svg__cls-13" x={906.24} y={572.01} width={169.47} height={20.12} rx={10.06} ry={10.06} />
				<rect className="-_21_Medicare_front_image_svg__cls-13" x={678.93} y={616.54} width={189.14} height={20.12} rx={10.06} ry={10.06} />
				<rect className="-_21_Medicare_front_image_svg__cls-13" x={906.24} y={616.54} width={169.47} height={20.12} rx={10.06} ry={10.06} />
				<rect className="-_21_Medicare_front_image_svg__cls-13" x={1134.65} y={683.57} width={191.17} height={22.7} rx={11.35} ry={11.35} />
				<path
					className="-_21_Medicare_front_image_svg__cls-14"
					d="M1875.1 402.5c-54.71-45.78-166.57-115.85-199.32-137.44s-63 30.34-1.78 95.46c0 0-94.82 9-123.39 20.6s-63 46 6.71 67.3 130.07 15.32 130.07 15.32-121.39 48.76-150.29 70.9-25 63.66 10.61 65.56 176.57-54.09 176.57-54.09S1499 681.87 1534.21 729.48 1643.52 748 1690 712.1s76.26-6.92 82.49 22.95"
				/>
				<path
					className="-_21_Medicare_front_image_svg__cls-11"
					d="M1999.06 1287.05s253.11-153 256.23-284.4c0 0-80.45-97.56-126.08-180.69s-117.56-265.89-188.34-343-136.54-164.87-191.25-210.65-169.25-112.22-199.32-137.41c-32.85-27.53-76.61-9-43.73 67.26 13.43 31.13 127.76 159.79 150.51 185 0 0 91.17 136.28 110 186.93 7.06 19 25.66 28.81 25.16 62.23-.83 55.71-28.94 137.05-62.8 142.81"
				/>
				<path
					className="-_21_Medicare_front_image_svg__cls-11"
					d="M1772.45 770.79c-30.4 2.55-63.6 4.37-63.6 4.37s-249.75 4.11-303 48-41.69 72.22 8.38 82.07 172.74 26.59 227.69 61.09 120.31 94.42 193.75 127.15 137.85 155.4 137.85 155.4c4.23-3.15 30.56-33 31.07-69.47s-204.24-410.96-232.14-408.61z"
				/>
				<path
					className="-_21_Medicare_front_image_svg__cls-15"
					d="M1792.22 778c-27.28-7.41-69.13-.74-108.37-2.07M1613.51 333.87s48.76 28.9 140.68 217.65"
				/>
				<path
					className="-_21_Medicare_front_image_svg__cls-14"
					d="M126.43 402.5C181.14 356.72 293 286.65 325.75 265.06s63 30.34 1.78 95.46c0 0 94.82 9 123.39 20.6s63 46-6.71 67.3-130.07 15.32-130.07 15.32 121.4 48.76 150.29 70.9 25 63.66-10.61 65.56-176.57-54.09-176.57-54.09 225.27 135.76 190.07 183.37S358 748 311.5 712.1s-76.26-6.92-82.49 22.95"
				/>
				<path
					className="-_21_Medicare_front_image_svg__cls-11"
					d="M2.46 1287.05s-253.1-153-256.22-284.4c0 0 80.45-97.56 126.08-180.69S-10.12 556.07 60.66 479s136.53-164.88 191.25-210.66S421.16 156.09 451.23 130.9c32.85-27.53 76.6-9 43.73 67.26-13.43 31.13-127.77 159.79-150.51 185 0 0-91.17 136.28-110 186.93-7.05 19-25.65 28.81-25.15 62.23.83 55.71 28.93 137.05 62.8 142.81"
				/>
				<path
					className="-_21_Medicare_front_image_svg__cls-11"
					d="M229.08 770.79c30.4 2.55 63.6 4.37 63.6 4.37s249.75 4.11 303 48 41.69 72.22-8.38 82.07-172.75 26.59-227.7 61.09-120.31 94.42-193.75 127.15S28 1248.87 28 1248.87c-4.23-3.15-30.56-33-31.07-69.47s204.25-410.96 232.15-408.61z"
				/>
				<path
					className="-_21_Medicare_front_image_svg__cls-15"
					d="M209.31 778c27.28-7.41 69.13-.74 108.37-2.07M388 333.87s-48.76 28.9-140.68 217.65"
				/>
				<path
					d="M1550.78 187.94H472.42a52.51 52.51 0 00-52.51 52.51V766a52.51 52.51 0 0052.51 52.51h1078.36a52.51 52.51 0 0052.51-52.51V240.45a52.51 52.51 0 00-52.51-52.51zm-29.32 609.51H501.74V209h1019.72z"
					fill="#32384f"
				/>
				<path opacity={0.2} fill="#fff" d="M1521.46 797.45H723.58L1521.46 209v588.45z" />
			</g>
		</svg>
	);
}

export default Svg21Medicarefrontimage;
