import * as React from 'react';

function Svg03Passport3(props) {
	return (
		<svg id="-_03_Passport_-_3_svg__\u0421\u043B\u043E\u0439_1" data-name="\u0421\u043B\u043E\u0439 1" viewBox="0 0 946 558" {...props}>
			<defs>
				<style>
					{
						'.-_03_Passport_-_3_svg__cls-12,.-_03_Passport_-_3_svg__cls-2{fill:#8fafe2}.-_03_Passport_-_3_svg__cls-2{opacity:.25}.-_03_Passport_-_3_svg__cls-7{fill:#ffca27}.-_03_Passport_-_3_svg__cls-9{fill:#32384f;stroke:#32384f;stroke-miterlimit:10;stroke-width:1.26px}'
					}
				</style>
			</defs>
			<rect width={946} height={558} rx={42} ry={42} fill="#fff" />
			<rect className="-_03_Passport_-_3_svg__cls-2" x={443.8} y={59.34} width={368.28} height={40.76} rx={20.38} ry={20.38} />
			<rect className="-_03_Passport_-_3_svg__cls-2" x={443.8} y={129.54} width={276.71} height={40.76} rx={20.38} ry={20.38} />
			<rect className="-_03_Passport_-_3_svg__cls-2" x={443.8} y={199.74} width={345.94} height={40.76} rx={20.38} ry={20.38} />
			<rect className="-_03_Passport_-_3_svg__cls-2" x={443.8} y={269.94} width={428.51} height={40.76} rx={20.38} ry={20.38} />
			<rect className="-_03_Passport_-_3_svg__cls-2" x={443.8} y={340.14} width={428.51} height={40.76} rx={20.38} ry={20.38} />
			<rect x={85.54} y={59.34} width={309.97} height={321.56} rx={15.45} ry={15.45} fill="#edf2f7" />
			<path
				d="M378.61 380.9h-276.8s2.78-34.53 19.49-41.22c6.75-2.7 22.23-10.4 38.28-18.62 6-3.05 12-6.17 17.7-9.14l1.79-.93c4.67-2.44 9.09-4.74 13-6.8 8.6-4.51 14.75-7.78 15.92-8.4l.2-.11 2.12.48c2.65.59 5.3 1.1 8 1.52s5.28.77 7.93 1a132 132 0 0043.74-3h.07s6.5 3.35 16.05 8.22c4 2 8.47 4.32 13.26 6.74l1.82.92 7.16 3.61c2.58 1.3 5.2 2.63 7.82 3.93 17.85 8.93 35.72 17.67 43 20.58 16.67 6.69 19.45 41.22 19.45 41.22z"
				fill="#ff4f4e"
			/>
			<path
				d="M301.14 311.56c-.95 2.77-2 5.6-3.3 8.42-5 11.16-11.46 20-19.33 26.44-9.87 8-21.89 12.07-35.75 12.07-28.94 0-46.81-17-56.7-31.19a103.39 103.39 0 01-8.78-15.38l1.79-.93a101.57 101.57 0 008.68 15.21c14 20.09 32.5 30.27 55 30.27 24.33 0 42.24-12.55 53.24-37.3 1.27-2.86 2.37-5.73 3.32-8.53z"
				fill="#c42c31"
			/>
			<path
				d="M286.06 303.9c-3.48 15-16.69 37.82-45.85 37.82-32 0-44.05-25.91-48.15-37.53 8.6-4.51 14.75-7.78 15.92-8.4l.2-.11 2.12.48c2.65.59 5.3 1.1 8 1.52s5.28.77 7.93 1a132 132 0 0043.74-3h.07l16.02 8.22z"
				fill="#fff7f1"
			/>
			<path
				className="-_03_Passport_-_3_svg__cls-7"
				d="M271.41 298.84s-5.43 21-30.37 21c-29.09 0-35.94-19.06-35.94-19.06a184 184 0 005.43-22.66c2.16-12.48 4.94-26.33 4.81-32.34L264 247.3c.48 5.32 1.24 11.58 2.11 17.87 2.29 16.72 5.3 33.67 5.3 33.67z"
			/>
			<path
				d="M265.22 258.88c-7.92 6.76-22.63 13.49-53 9.56 2.16-12.48 4.06-20.68 3.93-26.69L264 247.3c.46 5.32.35 5.28 1.22 11.58z"
				fill="#09030b"
			/>
			<path className="-_03_Passport_-_3_svg__cls-7" d="M195.08 196.82s-10.71-14.58-19-5.95 7.73 38.66 22.31 32.12z" />
			<path
				className="-_03_Passport_-_3_svg__cls-9"
				d="M193.16 216.35a1 1 0 01-.95-.82c-1.81-13.05-12.18-21.06-12.29-21.14a.94.94 0 01-.18-1.33 1 1 0 011.34-.19c.45.35 11.1 8.58 13 22.4a1 1 0 01-.81 1.07z"
			/>
			<path
				className="-_03_Passport_-_3_svg__cls-9"
				d="M190.27 207a1 1 0 01-.79-.42c-1.39-2.05-5.53-2.26-7-2.17a1 1 0 01-1-.89 1 1 0 01.9-1c.26 0 6.42-.36 8.69 3a1 1 0 01-.25 1.33.94.94 0 01-.55.15z"
			/>
			<path className="-_03_Passport_-_3_svg__cls-7" d="M291.55 196.82s10.71-14.58 19-5.95-7.74 38.66-22.31 32.12z" />
			<path
				className="-_03_Passport_-_3_svg__cls-9"
				d="M290.38 216.35h-.13a1 1 0 01-.82-1.07c1.92-13.82 12.58-22.05 13-22.4a1 1 0 011.15 1.52c-.1.08-10.48 8.12-12.29 21.14a1 1 0 01-.91.81z"
			/>
			<path
				className="-_03_Passport_-_3_svg__cls-9"
				d="M293.27 207a1 1 0 01-.54-.16 1 1 0 01-.25-1.33c2.27-3.36 8.44-3 8.7-3a1 1 0 01.89 1 .94.94 0 01-1 .89c-1.48-.08-5.62.13-7 2.17a1 1 0 01-.8.43z"
			/>
			<path
				className="-_03_Passport_-_3_svg__cls-7"
				d="M250.58 135.92c-39.33 0-63.78 13.27-60.2 54.87 2.9 33.57 8.22 67.36 53.21 67.36 34.53 0 53.6-37 51-64.48-2.19-23.5-8.77-48.84-33.94-56.17a62 62 0 00-10.07-1.58z"
			/>
			<path
				d="M190.76 194.52s-10.57-25.73-6-54.56c3.76-23.6 25.06-36.91 60.45-36.84 28.54.06 34.42 9.49 36.72 19.61 0 0 18.2 3.73 21.95 18.32 6.61 25.73-10.17 59.13-10.17 59.13a145.84 145.84 0 01-7.71-24.93c-3.22-14.72-7.41-23.72-16.6-23.9s-20.69.27-33.1 7.63-24.83 8.27-33.57 1.84c.02 0-2.31 22.18-11.97 33.7z"
				fill="#3f2037"
			/>
			<path
				className="-_03_Passport_-_3_svg__cls-9"
				d="M244.08 218.87a13.73 13.73 0 01-5.42-1.29 1.36 1.36 0 01-.8-1.42l2.76-21.2a1.36 1.36 0 012.69.35l-2.62 20.15c2.71 1 4.8.91 6.52-.26a1.36 1.36 0 111.52 2.26 8.09 8.09 0 01-4.65 1.41zM240.74 234.9a31.47 31.47 0 01-13-5.67 1.36 1.36 0 111.72-2.1c.59.48 14 11.13 28.94 1.58a1.35 1.35 0 011.87.41 1.36 1.36 0 01-.41 1.88 26 26 0 01-19.12 3.9z"
			/>
			<g opacity={0.25}>
				<rect className="-_03_Passport_-_3_svg__cls-12" x={85.54} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={111.32} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={137.1} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={162.87} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={188.65} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={214.43} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={240.21} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={265.98} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={291.76} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={317.54} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={343.32} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={369.1} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={394.87} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={420.65} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={446.43} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={472.21} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={497.98} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={523.76} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={549.54} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={575.32} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={601.89} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={627.67} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={653.44} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={679.22} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={705} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={730.78} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={756.55} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={782.33} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={808.11} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={833.89} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
				<rect className="-_03_Passport_-_3_svg__cls-12" x={859.66} y={446.79} width={12.64} height={64.46} rx={6.27} ry={6.27} />
			</g>
		</svg>
	);
}

export default Svg03Passport3;
