import * as React from 'react';

function OtherDocumentFrontImage(props) {
	return (
		<svg id="-_35_A4_Document_svg__\u0421\u043B\u043E\u0439_1" x={0} y={0} viewBox="0 0 1989.2 1315.5" xmlSpace="preserve" {...props}>
			<style>
				{
					'.-_35_A4_Document_svg__st10{opacity:.25;fill:#8fafe2;enable-background:new}.-_35_A4_Document_svg__st11{fill:#f7b614}.-_35_A4_Document_svg__st12{fill:#ffca27}.-_35_A4_Document_svg__st13{fill:none;stroke:#32384f;stroke-width:14.03;stroke-linecap:round;stroke-linejoin:round}'
				}
			</style>
			<defs>
				<path id="-_35_A4_Document_svg__SVGID_1_" d="M0 28.5h1989.2v1287.1H0z" />
			</defs>
			<clipPath id="-_35_A4_Document_svg__SVGID_2_">
				<use xlinkHref="#-_35_A4_Document_svg__SVGID_1_" overflow="visible" />
			</clipPath>
			<g clipPath="url(#-_35_A4_Document_svg__SVGID_2_)">
				<path
					d="M732.6 1026.2s356.6 117.5 304-202.4c-40.7-247.6-193.9-236-202.1-396.4C821.6 176 383.1 94.5 413.8 459.9c30.8 366.3-284.5 335.1-95 540.6 90.7 98.3 193.7-11.2 413.8 25.7z"
					opacity={0.07}
					fill="#1188f7"
				/>
				<path
					d="M1226.4 464.7s254.2-305.5-42.4-436.6c-229.6-101.5-301.9 108-499.4 65.1-246-53.5-501.4 235-178 409.4 295.1 159.1 173.2 508.9 542.2 375.1 120.7-43.8 20.1-215.5 177.6-413z"
					opacity={0.11}
					fill="#ffca27"
				/>
				<path
					d="M995.2 1013.7s26.2 216.6 228.8 164.3c204.4-52.7 117.3-227 272.9-308.7 266.5-139.8 97.8-494.6-189.1-375.4s-354.6-22.7-442.6 127.9c-98.4 168.4 118.3 193.9 130 391.9z"
					opacity={0.09}
					fill="#4dbc76"
				/>
				<path fill="none" stroke="#32384f" strokeWidth={17.73} strokeLinecap="round" strokeLinejoin="round" d="M1274.4 475.1l-36.6-162.6" />
				<path
					d="M762.6 204h486.6c19.7 0 35.7 16 35.7 35.7v583.8c0 19.7-16 35.7-35.7 35.7H762.6c-19.7 0-35.7-16-35.7-35.7V239.7c0-19.7 16-35.7 35.7-35.7z"
					fill="#fff"
				/>
				<path
					d="M762.6 204h486.6c19.7 0 35.7 16 35.7 35.7v583.8c0 19.7-16 35.7-35.7 35.7H762.6c-19.7 0-35.7-16-35.7-35.7V239.7c0-19.7 16-35.7 35.7-35.7z"
					fill="none"
					stroke="#624de6"
					strokeWidth={37}
					strokeMiterlimit={10}
				/>
				<path
					d="M860.9 393.9h293.8c12.5 0 22.7 10.1 22.7 22.7v269.2c0 12.5-10.1 22.7-22.7 22.7H860.9c-12.5 0-22.7-10.1-22.7-22.7V416.6c0-12.5 10.2-22.7 22.7-22.7z"
					fill="#fff"
					stroke="#e2e1ff"
					strokeWidth={23}
					strokeMiterlimit={10}
				/>
				<circle cx={891.8} cy={664} r={20.4} opacity={0.55} fill="none" stroke="#ffca27" strokeWidth={14} strokeMiterlimit={10} />
				<path
					d="M910.5 440H1101c7.3 0 13.3 5.9 13.3 13.3v2.2c0 7.3-5.9 13.3-13.3 13.3H910.5c-7.3 0-13.3-5.9-13.3-13.3v-2.2c.1-7.4 6-13.3 13.3-13.3z"
					fill="#1188f7"
				/>
				<path
					className="-_35_A4_Document_svg__st10"
					d="M892.2 492.8h106.7c7.3 0 13.3 5.9 13.3 13.3v2.2c0 7.3-5.9 13.3-13.3 13.3H892.2c-7.3 0-13.3-5.9-13.3-13.3v-2.2c0-7.3 6-13.3 13.3-13.3zM904.3 544.7h200.2c7.3 0 13.3 5.9 13.3 13.3v2.2c0 7.3-5.9 13.3-13.3 13.3H904.3c-7.3 0-13.3-5.9-13.3-13.3V558c.1-7.4 6-13.3 13.3-13.3zM957.6 600.1h105.2c7.3 0 13.3 5.9 13.3 13.3v2.2c0 7.3-5.9 13.3-13.3 13.3H957.6c-7.3 0-13.3-5.9-13.3-13.3v-2.2c.1-7.4 6-13.3 13.3-13.3zM1049.9 492.8h73.4c7.3 0 13.3 5.9 13.3 13.3v2.2c0 7.3-5.9 13.3-13.3 13.3h-73.4c-7.3 0-13.3-5.9-13.3-13.3v-2.2c.1-7.3 6-13.3 13.3-13.3z"
				/>
				<path
					className="-_35_A4_Document_svg__st11"
					d="M1879.9 426.9c-54.7-45.8-166.6-115.9-199.3-137.4s-63 30.3-1.8 95.5c0 0-94.8 9-123.4 20.6s-63 46 6.7 67.3 130.1 15.3 130.1 15.3-121.4 48.8-150.3 70.9-25 63.7 10.6 65.5 176.6-54.1 176.6-54.1-225.3 135.7-190.1 183.4 109.3 18.5 155.8-17.4 76.3-6.9 82.5 23"
				/>
				<path
					className="-_35_A4_Document_svg__st12"
					d="M2003.8 1311.4s253.1-153 256.2-284.4c0 0-80.5-97.6-126.1-180.7s-117.6-265.9-188.3-343-136.5-164.8-191.2-210.6-169.3-112.2-199.3-137.4c-32.8-27.5-76.6-8.9-43.7 67.3 13.4 31.1 127.8 159.8 150.5 185 0 0 91.2 136.3 110 186.9 7.1 19 25.7 28.8 25.2 62.2-.8 55.7-28.9 137-62.8 142.8"
				/>
				<path
					className="-_35_A4_Document_svg__st12"
					d="M1777.2 795.2c-30.4 2.6-63.6 4.4-63.6 4.4s-249.8 4.1-303 48-41.6 72.2 8.4 82.1 172.8 26.6 227.7 61.1c54.9 34.5 120.3 94.4 193.8 127.1s148.7 165.4 148.7 165.4c4.2-3.2 19.7-43 20.2-79.4s-204.3-411.1-232.2-408.7z"
				/>
				<path className="-_35_A4_Document_svg__st13" d="M1797 802.3c-27.3-7.4-69.1-.7-108.4-2.1M1618.3 358.2s48.8 28.9 140.7 217.7" />
				<path
					className="-_35_A4_Document_svg__st11"
					d="M131.2 426.9c54.7-45.8 166.6-115.9 199.3-137.4s63 30.3 1.8 95.5c0 0 94.8 9 123.4 20.6s63 46-6.7 67.3-130.1 15.3-130.1 15.3 121.4 48.8 150.3 70.9 25 63.7-10.6 65.5S282 570.5 282 570.5s225.3 135.8 190.1 183.4-109.3 18.5-155.8-17.4-76.3-6.9-82.5 23"
				/>
				<path
					className="-_35_A4_Document_svg__st12"
					d="M7.2 1311.4s-253.1-153-256.2-284.4c0 0 80.4-97.6 126.1-180.7s117.6-265.9 188.3-343S202 338.5 256.7 292.7 425.9 180.5 456 155.3c32.9-27.5 76.6-8.9 43.7 67.3-13.4 31.1-127.8 159.8-150.5 185 0 0-91.2 136.3-110 186.9-7.1 19-25.7 28.8-25.2 62.2.8 55.7 28.9 137 62.8 142.8"
				/>
				<path
					className="-_35_A4_Document_svg__st12"
					d="M233.9 795.2c30.4 2.6 63.6 4.4 63.6 4.4s249.7 4.1 303 48 41.7 72.2-8.4 82.1-172.7 26.6-227.7 61.1c-54.9 34.5-120.3 94.4-193.7 127.1S28.5 1270.1 28.5 1270.1c-4.2-3.2-26.3-29.8-26.8-66.3s204.3-411 232.2-408.6z"
				/>
				<path className="-_35_A4_Document_svg__st13" d="M214.1 802.3c27.3-7.4 69.1-.7 108.4-2.1M392.8 358.2S344 387.1 252.1 575.9" />
				<path
					d="M1555.6 212.3H477.2c-29 0-52.5 23.5-52.5 52.5v525.5c0 29 23.5 52.5 52.5 52.5h1078.4c29 0 52.5-23.5 52.5-52.5V264.8c0-29-23.5-52.5-52.5-52.5zm-29.4 609.5H506.5V233.4h1019.7v588.4z"
					fill="#32384f"
				/>
				<path opacity={0.2} fill="#fff" d="M1526.2 821.8H728.4l797.8-588.4z" />
			</g>
		</svg>
	);
}

export default OtherDocumentFrontImage;
