import * as React from 'react';

function Svg15BirthCertificate3(props) {
	return (
		<svg
			id="-_15_Birth_Certificate_-_3_svg__\u0421\u043B\u043E\u0439_1"
			data-name="\u0421\u043B\u043E\u0439 1"
			viewBox="0 0 732.88 860.51"
			{...props}
		>
			<defs>
				<style>
					{
						'.-_15_Birth_Certificate_-_3_svg__cls-9{stroke-miterlimit:10;fill:none}.-_15_Birth_Certificate_-_3_svg__cls-6{fill:#8fafe2;opacity:.25}.-_15_Birth_Certificate_-_3_svg__cls-7{fill:#d33f11}.-_15_Birth_Certificate_-_3_svg__cls-9{stroke:#ff8a66;stroke-linecap:round;stroke-width:25px}'
					}
				</style>
			</defs>
			<rect x={12.47} y={13.16} width={710.46} height={834.19} rx={34.62} ry={34.62} fill="#fff" />
			<path
				d="M690.88 42v776.51H42V42h648.88m6.29-42H35.71A35.71 35.71 0 000 35.71V824.8a35.71 35.71 0 0035.71 35.71h661.46a35.71 35.71 0 0035.71-35.71V35.71A35.71 35.71 0 00697.17 0z"
				fill="#958dff"
			/>
			<rect
				x={144.97}
				y={249.45}
				width={445.47}
				height={445.47}
				rx={23.54}
				ry={23.54}
				strokeMiterlimit={10}
				stroke="#e2e1ff"
				strokeWidth={30}
				fill="#fff"
			/>
			<circle cx={234.03} cy={611.1} r={34.36} stroke="#ffca27" strokeWidth={17.93} opacity={0.93} fill="none" strokeMiterlimit={10} />
			<rect x={223.77} y={309.95} width={284.95} height={37.74} rx={18.87} ry={18.87} fill="#1188f7" />
			<rect className="-_15_Birth_Certificate_-_3_svg__cls-6" x={199.68} y={379.31} width={174.93} height={37.74} rx={18.87} ry={18.87} />
			<rect className="-_15_Birth_Certificate_-_3_svg__cls-6" x={215.61} y={447.45} width={297.77} height={37.74} rx={18.87} ry={18.87} />
			<rect className="-_15_Birth_Certificate_-_3_svg__cls-6" x={285.6} y={520.22} width={173} height={37.74} rx={18.87} ry={18.87} />
			<rect className="-_15_Birth_Certificate_-_3_svg__cls-6" x={406.84} y={379.31} width={131.18} height={37.74} rx={18.87} ry={18.87} />
			<path
				className="-_15_Birth_Certificate_-_3_svg__cls-7"
				d="M156.09 119.78s-21.73 18.48-51.33 26.22-27.2 10.6-25.49 12.14 22.24 53.39 22.24 53.39l77-29.94s-18.14-47.78-22.42-61.81zM581.82 127.93s21.73 18.48 51.33 26.18 27.21 10.61 25.5 12.15-22.25 53.38-22.25 53.38l-77-29.94s18.15-47.7 22.42-61.77z"
			/>
			<path
				d="M93 215.25L79.27 158.1s28.4-.68 125.93-31.48 125.24-32.51 191.29-30.79 201.21 54.75 227.56 60.91 34.9 4.79 34.9 9.58-7.53 44.82-7.53 48.93-7.87 5.59-23.95 4.91-140.3-52.82-184.79-61.71S325 143.39 257.9 169.74 96.72 221.75 93 215.25z"
				fill="#ff703e"
			/>
			<path
				className="-_15_Birth_Certificate_-_3_svg__cls-9"
				d="M183.85 165.21s28.56-4.42 88.48-28.15M342.15 123.87s50.83-10.78 126.3 12.84c91.11 28.51 132.46 44.15 132.46 44.15"
			/>
		</svg>
	);
}

export default Svg15BirthCertificate3;
