import * as React from 'react';

function Svg17ChangeofName2(props) {
	return (
		<svg
			id="-_17_Change_of_Name_-_2_svg__\u0421\u043B\u043E\u0439_1"
			data-name="\u0421\u043B\u043E\u0439 1"
			viewBox="0 0 1057.62 811.62"
			{...props}
		>
			<defs>
				<style>
					{
						'.-_17_Change_of_Name_-_2_svg__cls-5{fill:#ff703e}.-_17_Change_of_Name_-_2_svg__cls-3{fill:#8fafe2;opacity:.25}.-_17_Change_of_Name_-_2_svg__cls-4{fill:#1188f7}'
					}
				</style>
			</defs>
			<rect
				x={15.62}
				y={15.62}
				width={1026.38}
				height={780.39}
				rx={14.81}
				ry={14.81}
				fill="#edf2f7"
				stroke="#ff703e"
				strokeMiterlimit={10}
				strokeWidth={31.24}
			/>
			<circle cx={530.08} cy={138.96} r={62.46} opacity={0.66} fill="#ff703e" />
			<rect className="-_17_Change_of_Name_-_2_svg__cls-3" x={142.02} y={357.23} width={771.95} height={45} rx={22.5} ry={22.5} />
			<rect className="-_17_Change_of_Name_-_2_svg__cls-3" x={238.93} y={447.4} width={578.13} height={45} rx={22.5} ry={22.5} />
			<rect className="-_17_Change_of_Name_-_2_svg__cls-3" x={238.93} y={588.44} width={228.69} height={45} rx={22.5} ry={22.5} />
			<rect className="-_17_Change_of_Name_-_2_svg__cls-3" x={286.62} y={671.24} width={133.31} height={45} rx={22.5} ry={22.5} />
			<rect className="-_17_Change_of_Name_-_2_svg__cls-3" x={572.94} y={588.44} width={228.69} height={45} rx={22.5} ry={22.5} />
			<rect className="-_17_Change_of_Name_-_2_svg__cls-3" x={607.52} y={671.24} width={159.52} height={45} rx={22.5} ry={22.5} />
			<rect className="-_17_Change_of_Name_-_2_svg__cls-4" x={198.62} y={256.96} width={292.39} height={50.9} rx={25.45} ry={25.45} />
			<rect className="-_17_Change_of_Name_-_2_svg__cls-4" x={520.8} y={256.96} width={325.18} height={50.9} rx={25.45} ry={25.45} />
			<path
				className="-_17_Change_of_Name_-_2_svg__cls-5"
				d="M7.55 661.63l149.97 144.79-127.88-10.41-19.97-20.24v-91.24l-2.12-22.9zM1052.58 656.37L902.61 806.42l127.89-10.41 19.96-20.24v-91.24l2.12-28.16zM1049.74 156.03L899.77 11.24l117.72 5.34 30.13 25.31v91.24l2.12 22.9zM6.6 151.46L156.58 6.67 38.85 12l-28.44 2.74-1.69 113.81-2.12 22.91z"
			/>
		</svg>
	);
}

export default Svg17ChangeofName2;
