import * as React from 'react';

function Svg24ChangeofNamefrontimage(props) {
	return (
		<svg
			id="-_24_Change_of_Name_front_image_svg__\u0421\u043B\u043E\u0439_1"
			data-name="\u0421\u043B\u043E\u0439 1"
			viewBox="0 0 1989.16 1287.05"
			{...props}
		>
			<defs>
				<clipPath id="-_24_Change_of_Name_front_image_svg__clip-path">
					<path fill="none" d="M0 0h1989.16v1287.05H0z" />
				</clipPath>
				<style>
					{
						'.-_24_Change_of_Name_front_image_svg__cls-11,.-_24_Change_of_Name_front_image_svg__cls-14{fill:none}.-_24_Change_of_Name_front_image_svg__cls-9{fill:#1188f7}.-_24_Change_of_Name_front_image_svg__cls-13{fill:#ffca27}.-_24_Change_of_Name_front_image_svg__cls-11{stroke-miterlimit:10}.-_24_Change_of_Name_front_image_svg__cls-10{fill:#ff703e}.-_24_Change_of_Name_front_image_svg__cls-8{fill:#8fafe2;opacity:.25}.-_24_Change_of_Name_front_image_svg__cls-11{stroke:#eda400;stroke-width:8px}.-_24_Change_of_Name_front_image_svg__cls-11,.-_24_Change_of_Name_front_image_svg__cls-14{stroke-linecap:round}.-_24_Change_of_Name_front_image_svg__cls-12{fill:#f7b614}.-_24_Change_of_Name_front_image_svg__cls-14{stroke:#32384f;stroke-linejoin:round;stroke-width:14.03px}'
					}
				</style>
			</defs>
			<g clipPath="url(#-_24_Change_of_Name_front_image_svg__clip-path)">
				<path
					d="M403.88 605.73s6.27 327.52 274.91 185.4c207.94-110 107.49-332.46 247.56-387.56C1146 317.15 1029.7-117 738.09 65.08s-389.45 19.47-477.3 182.69c-59.43 110.4 110.31 151.37 143.09 357.96z"
					opacity={0.07}
					fill="#1188f7"
				/>
				<path
					d="M781.21 598.18s-242 245.74 5 380.68c191.2 104.45 268.9-71.54 436.64-20.07 208.9 64.11 450.73-166.8 183.73-341.12C1162.92 458.6 1293.69 165 964.9 253.86 857.2 283 931.74 438.78 781.21 598.18z"
					opacity={0.11}
					fill="#ffca27"
				/>
				<path
					d="M1386.81 325.43s114.39-188-78.74-273C1113.23-33.31 1096.19 190.38 922 158c-298.45-55.57-408.85 299.53-107.18 384.18s266.75 239.3 430.47 174.88c183-72 27.26-227.45 141.52-391.63z"
					fill="#4dbc76"
					opacity={0.09}
				/>
				<rect
					x={643.62}
					y={227.82}
					width={723.72}
					height={550.26}
					rx={14.81}
					ry={14.81}
					strokeMiterlimit={10}
					stroke="#ff703e"
					strokeWidth={23}
					fill="#fff"
				/>
				<circle cx={1006.37} cy={314.79} r={44.04} opacity={0.66} fill="#ff703e" />
				<rect
					className="-_24_Change_of_Name_front_image_svg__cls-8"
					x={732.74}
					y={468.7}
					width={544.31}
					height={31.73}
					rx={15.01}
					ry={15.01}
				/>
				<rect
					className="-_24_Change_of_Name_front_image_svg__cls-8"
					x={801.08}
					y={532.27}
					width={407.65}
					height={31.73}
					rx={15.01}
					ry={15.01}
				/>
				<rect
					className="-_24_Change_of_Name_front_image_svg__cls-8"
					x={801.08}
					y={631.72}
					width={161.25}
					height={31.73}
					rx={15.01}
					ry={15.01}
				/>
				<rect className="-_24_Change_of_Name_front_image_svg__cls-8" x={834.7} y={690.11} width={94} height={31.73} rx={15.01} ry={15.01} />
				<rect
					className="-_24_Change_of_Name_front_image_svg__cls-8"
					x={1036.59}
					y={631.72}
					width={161.25}
					height={31.73}
					rx={15.01}
					ry={15.01}
				/>
				<rect
					className="-_24_Change_of_Name_front_image_svg__cls-8"
					x={1060.98}
					y={690.11}
					width={112.48}
					height={31.73}
					rx={15.01}
					ry={15.01}
				/>
				<rect className="-_24_Change_of_Name_front_image_svg__cls-9" x={772.65} y={398} width={206.17} height={35.89} rx={16.98} ry={16.98} />
				<rect className="-_24_Change_of_Name_front_image_svg__cls-9" x={999.83} y={398} width={229.29} height={35.89} rx={16.98} ry={16.98} />
				<path
					className="-_24_Change_of_Name_front_image_svg__cls-10"
					d="M637.93 683.33l105.75 102.1-90.18-7.34-14.08-14.27v-64.34l-1.49-16.15zM1371.74 684.39l-105.75 102.09 90.17-7.34 14.08-14.27v-64.33l1.5-16.15zM1372.79 326.83l-105.75-102.09 83.01 3.76 21.25 17.84v64.34l1.49 16.15zM637.26 323.6l105.75-102.09-83.01 3.76-20.05 1.93-1.19 80.25-1.5 16.15z"
				/>
				<path
					className="-_24_Change_of_Name_front_image_svg__cls-11"
					d="M629.37 295v-52.79A28.14 28.14 0 01657.45 214h52.23M629.37 709.62v52.77a28.14 28.14 0 0028.08 28.2h52.23M1380.25 295v-52.79a28.14 28.14 0 00-28.08-28.2h-52.23M1380.25 709.62v52.77a28.14 28.14 0 01-28.08 28.2h-52.23"
				/>
				<path
					className="-_24_Change_of_Name_front_image_svg__cls-12"
					d="M1881.44 401.65c-54.71-45.78-166.58-115.85-199.32-137.44s-63 30.34-1.78 95.46c0 0-94.83 9-123.4 20.6s-63 46 6.71 67.3 130.08 15.32 130.08 15.32-121.4 48.75-150.3 70.9-25 63.66 10.62 65.55 176.57-54.08 176.57-54.08S1505.34 681 1540.54 728.63s109.32 18.49 155.82-17.39 76.27-6.91 82.49 23"
				/>
				<path
					className="-_24_Change_of_Name_front_image_svg__cls-13"
					d="M2005.4 1286.2s253.11-153 256.23-284.4c0 0-80.46-97.56-126.08-180.69s-117.56-265.89-188.34-343S1810.67 313.26 1756 267.48s-169.3-112.24-199.36-137.43c-32.85-27.53-76.61-9-43.74 67.25 13.44 31.14 127.77 159.8 150.51 185 0 0 91.18 136.28 110 186.93 7 19 25.66 28.81 25.16 62.23-.84 55.71-28.94 137-62.81 142.81"
				/>
				<path
					className="-_24_Change_of_Name_front_image_svg__cls-13"
					d="M1778.79 769.93c-30.4 2.56-63.6 4.37-63.6 4.37s-249.76 4.11-303 48-41.69 72.22 8.39 82.07 172.74 26.59 227.68 61.09 120.32 94.42 193.75 127.14 142.69 154.63 142.69 154.63c4.23-3.16 25.73-32.22 26.23-68.69s-204.25-410.95-232.14-408.61z"
				/>
				<path
					className="-_24_Change_of_Name_front_image_svg__cls-14"
					d="M1798.56 777.1c-27.29-7.41-69.13-.74-108.38-2.07M1619.84 333s48.76 28.91 140.68 217.66"
				/>
				<path
					className="-_24_Change_of_Name_front_image_svg__cls-12"
					d="M132.76 401.65c54.71-45.78 166.57-115.85 199.32-137.44s63 30.34 1.78 95.46c0 0 94.83 9 123.4 20.6s63 46-6.71 67.3-130.08 15.32-130.08 15.32 121.4 48.75 150.29 70.9 25 63.66-10.61 65.55-176.57-54.08-176.57-54.08S508.85 681 473.66 728.63s-109.32 18.49-155.82-17.39-76.27-6.91-82.49 23"
				/>
				<path
					className="-_24_Change_of_Name_front_image_svg__cls-13"
					d="M8.8 1286.2s-253.11-153-256.23-284.4c0 0 80.46-97.56 126.08-180.69S-3.79 555.22 67 478.14s136.53-164.88 191.24-210.66S427.5 155.24 457.56 130.05c32.85-27.53 76.61-9 43.73 67.25-13.43 31.14-127.76 159.8-150.5 185 0 0-91.18 136.28-110 186.93-7.05 19-25.66 28.81-25.16 62.23.84 55.71 28.94 137 62.81 142.81"
				/>
				<path
					className="-_24_Change_of_Name_front_image_svg__cls-13"
					d="M235.41 769.93c30.4 2.56 63.6 4.37 63.6 4.37s249.76 4.11 303 48 41.69 72.22-8.39 82.07-172.74 26.63-227.68 61.1-120.32 94.42-193.75 127.14S27.7 1247.74 27.7 1247.74c-4.23-3.16-23.93-32.72-24.43-69.19s204.24-410.96 232.14-408.62z"
				/>
				<path
					className="-_24_Change_of_Name_front_image_svg__cls-14"
					d="M215.64 777.1c27.29-7.41 69.13-.74 108.38-2.07M394.36 333s-48.76 28.92-140.69 217.67"
				/>
				<path
					d="M1557.12 187.09H478.75a52.5 52.5 0 00-52.5 52.51v525.55a52.49 52.49 0 0052.5 52.5h1078.37a52.49 52.49 0 0052.5-52.5V239.6a52.5 52.5 0 00-52.5-52.51zm-29.33 609.51H508.07V208.14h1019.72z"
					fill="#32384f"
					stroke="#32384f"
					strokeMiterlimit={10}
				/>
				<path opacity={0.2} fill="#fff" d="M1527.79 796.6H729.92l797.87-588.46V796.6z" />
			</g>
		</svg>
	);
}

export default Svg24ChangeofNamefrontimage;
