import PassportSvg01 from './PassportSvg01';
import PassportSvg02 from './PassportSvg02';
import PassportSvg03 from './PassportSvg03';
import DriverLicenceSvg01 from './DriverLicenceSvg01';
import DriverLicenceSvg02 from './DriverLicenceSvg02';
import DriverLicenceSvg03 from './DriverLicenceSvg03';
import MedicareSvg01 from './MedicareSvg01';
import MedicareSvg02 from './MedicareSvg02';
import MedicareSvg03 from './MedicareSvg03';
import OtherDocumentSvg01 from './OtherDocumentSvg01';
import OtherDocumentSvg02 from './OtherDocumentSvg02';
import NationalIdCardSvg01 from './NationalIdCardSvg01';
import NationalIdCardSvg02 from './NationalIdCardSvg02';
import NationalIdCardSvg03 from './NationalIdCardSvg03';
import BirthCertificateSvg01 from './BirthCertificateSvg01';
import BirthCertificateSvg02 from './BirthCertificateSvg02';
import BirthCertificateSvg03 from './BirthCertificateSvg03';
import ChangeOfNameSvg01 from './ChangeOfNameSvg01';
import ChangeOfNameSvg02 from './ChangeOfNameSvg02';
import ChangeOfNameSvg03 from './ChangeOfNameSvg03';
import SelectIdTypeSvg from './SelectIdTypeSvg';
import ArrowDown from './ArrowDown';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ArrowUp from './ArrowUp';
import OtherDocumentFrontImage from './OtherDocumentFrontImage';
import BirthCertificateFrontImage from './BirthCertificateFrontImage';
import ChangeOfNameFrontImage from './ChangeOfNameFrontImage';
import DriverLicenceFrontImage from './DriverLicenceFrontImage';
import MedicareFrontImage from './MedicareFrontImage';
import NationalIdCardFrontImage from './NationalIdCardFrontImage';
import PassportFrontImage from './PassportFrontImage';
import DocumentReview from './DocumentReview';
import LivenessTest from './LivenessTest';
import VerificationComplete from './VerificationComplete';

export default {
	LivenessTest,
	VerificationComplete,
	DocumentReview,
	// image frame
	OtherDocumentFrontImage,
	BirthCertificateFrontImage,
	ChangeOfNameFrontImage,
	DriverLicenceFrontImage,
	MedicareFrontImage,
	NationalIdCardFrontImage,
	PassportFrontImage,
	// document selection
	OtherDocument01: OtherDocumentSvg01,
	OtherDocument02: OtherDocumentSvg02,
	Passport01: PassportSvg01,
	Passport02: PassportSvg02,
	Passport03: PassportSvg03,
	DriverLicence01: DriverLicenceSvg01,
	DriverLicence02: DriverLicenceSvg02,
	DriverLicence03: DriverLicenceSvg03,
	Medicare01: MedicareSvg01,
	Medicare02: MedicareSvg02,
	Medicare03: MedicareSvg03,
	NationalIdCard01: NationalIdCardSvg01,
	NationalIdCard02: NationalIdCardSvg02,
	NationalIdCard03: NationalIdCardSvg03,
	BirthCertificate01: BirthCertificateSvg01,
	BirthCertificate02: BirthCertificateSvg02,
	BirthCertificate03: BirthCertificateSvg03,
	ChangeOfName01: ChangeOfNameSvg01,
	ChangeOfName02: ChangeOfNameSvg02,
	ChangeOfName03: ChangeOfNameSvg03,
	SelectIdType: SelectIdTypeSvg,
	// arrows
	ArrowDown,
	ArrowLeft,
	ArrowRight,
	ArrowUp,
};
