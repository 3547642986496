import * as React from 'react';

function Svg31Left(props) {
	return (
		<svg data-name="\u0421\u043B\u043E\u0439 1" viewBox="0 0 547.34 557.72" {...props}>
			<path
				d="M0 278.49c-.2 153 124 278.23 277 279.23 127.4.82 235.16-83.78 269.38-199.91a23.06 23.06 0 00-22.16-29.53H287.86l69 69a43.34 43.34 0 010 61.31 43.34 43.34 0 01-61.31 0L148.86 311.91a43.24 43.24 0 01-12.63-33.05 43.21 43.21 0 0112.63-33l146.7-146.7a43.35 43.35 0 0161.31 0 43.35 43.35 0 010 61.32l-66 66H523.3a23.08 23.08 0 0022.06-29.86C510.25 82.73 404.23 0 278.86 0 125 0 .2 124.65 0 278.49z"
				fill="#fff"
			/>
		</svg>
	);
}

export default Svg31Left;
